import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { t } from "@/src/features/Localization";
import styled from "styled-components";
const Root = styled.div `
	margin-top: 32px;
	display: flex;
`;
const Title = styled.div `
	${TypographyV3.Header3};
	vertical-align: top;
	text-align: left;
`;
export const ProfileWalletCardDrawerHeader = () => {
    return (_jsx(Root, { children: _jsx(Title, { children: t("profile_tab_wallet_addPaymentMethod_bottomSheet_header") }, void 0) }, void 0));
};
