import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldError } from "@/src/common/components/field/FieldError";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import { FieldHint } from "@/src/common/components/field/FieldHint";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const Field = (p) => {
    return (_jsxs(Root, { children: [_jsx(FieldHeader, { title: p.title, description: p.description }, void 0), _jsx(RootContent, { children: p.children }, void 0), _jsx(FieldError, { error: p.error }, void 0), _jsx(FieldHint, { children: p.hint }, void 0)] }, void 0));
};
