var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { usePhoneValidator } from "@/src/common/utility/phone/usePhoneValidator";
import { FormId } from "@/src/enums/forms";
import { Form } from "@/src/features/common";
import { FormikTextFieldMaskedPhoneNumber } from "@/src/features/common/FormikTextFieldMaskedPhoneNumber";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { redirectAfterLogin } from "@/src/features/DeepLink/DeepLinkHelpers";
import { t } from "@/src/features/Localization";
import { mapSSOMethodTypeToRequestType } from "@/src/features/Login/types";
import { getCurrentUserCredentials, getSSOMethod, getSSOTokenRegister } from "@/src/features/RegisterAccount/RegisterAccountSSO/registerAccountSSOSlice";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import FormikErrorFocus from "@/src/features/utils/Formik/FormikErrorFocus";
import { FormikSelect } from "@/src/features/utils/Formik/FormikSelect";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { useGroupViewListRegisterQuery } from "@/src/modules/group/redux/ApiCoreGroup";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { useUserRegisterSsoCustomerMutation } from "@/src/modules/user/redux/ApiCoreUser";
import { setCredentials } from "@/src/redux/slices/authSlice";
import { formatNumberToPhoneNumber } from "@/src/utils/HelperUtils";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { FormikProvider, useFormik } from "formik";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	display: flex;
	justify-content: center;
	width: 500px;
	max-width: 100%;
`;
const RootTerms = styled.div `
	& {
		margin-top: 16px;
		margin-bottom: 32px;
	}
`;
const FormikTextFieldMaskStyled = styled(FormikTextFieldMaskedPhoneNumber) `
	width: 100%;
`;
export const RegisterAccountSSOForm = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const history = useHistory();
    const { snackbar } = useCustomSnackbar();
    const dispatch = useDispatch();
    const { segmentTrackEvent } = useSegmentAnalytics();
    let { query } = useQuery();
    const institutionCode = (query === null || query === void 0 ? void 0 : query.get("groupCode")) || "";
    const pharmacyGuid = (query === null || query === void 0 ? void 0 : query.get("ph")) || undefined;
    const ssoTokenRegister = useSelector(getSSOTokenRegister) || null;
    const ssoMethod = useSelector(getSSOMethod) || null;
    const ssoCustomerRequestType = mapSSOMethodTypeToRequestType(ssoMethod);
    const userCredentials = useSelector(getCurrentUserCredentials) || null;
    const phoneValidator = usePhoneValidator({});
    const [apiRegister, apiRegisterState] = useUserRegisterSsoCustomerMutation();
    const apiList = useGroupViewListRegisterQuery({
        pharmacy: pharmacyGuid,
    });
    const provinceList = ((_b = (_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.provinceList) !== null && _b !== void 0 ? _b : []);
    const provinceDefault = (_c = apiList.data) === null || _c === void 0 ? void 0 : _c.data.provinceDefault;
    const pharmacy = (_d = apiList.data) === null || _d === void 0 ? void 0 : _d.data.pharmacy;
    const isPharmacy = Boolean(pharmacy);
    const formik = useFormik({
        initialValues: {
            nameFirst: ((_e = userCredentials === null || userCredentials === void 0 ? void 0 : userCredentials.details) === null || _e === void 0 ? void 0 : _e.nameFirst) || "",
            nameLast: ((_f = userCredentials === null || userCredentials === void 0 ? void 0 : userCredentials.details) === null || _f === void 0 ? void 0 : _f.nameLast) || "",
            phone: "",
            groupProvince: provinceDefault !== null && provinceDefault !== void 0 ? provinceDefault : "",
            groupPharmacy: (_g = pharmacy === null || pharmacy === void 0 ? void 0 : pharmacy.guid) !== null && _g !== void 0 ? _g : "",
            groupPharmacyName: (_h = pharmacy === null || pharmacy === void 0 ? void 0 : pharmacy.name) !== null && _h !== void 0 ? _h : "",
            isCheckbox: false,
        },
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
            nameFirst: Yup.string()
                .min(1, t("common_nameInput_error_invalidMinLength"))
                .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
                .required(t("common_nameInput_error_invalidMinLength")),
            nameLast: Yup.string()
                .min(1, t("common_nameInput_error_invalidMinLength"))
                .max(50, t("common_nameInput_error_invalidMaxLength", { maxLength: 50 }))
                .required(t("common_nameInput_error_invalidMinLength")),
            groupProvince: Yup.string().min(1, t("common_provinceInput_error_empty")).required(t("common_provinceInput_error_empty")),
            phone: phoneValidator.schema,
            isCheckbox: Yup.boolean().oneOf([true], "Required"),
        }),
        onSubmit: (s) => __awaiter(void 0, void 0, void 0, function* () {
            var _j, _k, _l, _m;
            const response = (yield apiRegister({
                type: ssoCustomerRequestType,
                tokenRegister: ssoTokenRegister,
                group: s.groupPharmacy || s.groupProvince,
                institutionCode: institutionCode,
                details: {
                    nameFirst: s.nameFirst,
                    nameLast: s.nameLast,
                    phone: formatNumberToPhoneNumber(s.phone),
                },
            }).unwrap());
            if (!response) {
                return;
            }
            const token = (_j = response.session) === null || _j === void 0 ? void 0 : _j.token;
            const tokenJunction = (_k = response.session) === null || _k === void 0 ? void 0 : _k.tokenJunction;
            const tokenStream = (_l = response.session) === null || _l === void 0 ? void 0 : _l.tokenStream;
            const user = response.data.user;
            const settingActivate = getSettingDetailsByType(SettingType.GroupActivate, user.settings);
            const deeplink = settingActivate === null || settingActivate === void 0 ? void 0 : settingActivate.deeplink;
            segmentTrackEvent("sign_up", {
                guid: user.guid,
                email: ssoTokenRegister ? (_m = jwtDecode(ssoTokenRegister !== null && ssoTokenRegister !== void 0 ? ssoTokenRegister : "")) === null || _m === void 0 ? void 0 : _m.email : undefined,
                method: ssoMethod,
            });
            dispatch(setCredentials({ token, tokenJunction, tokenStream }));
            if (deeplink) {
                query = new URLSearchParams(settingActivate.deeplink);
            }
            redirectAfterLogin(history, query);
        }),
    });
    // This is if there is an error with log request (`/api/user/login-basic`)
    useEffect(() => {
        var _a, _b, _c;
        if (apiRegisterState.error) {
            snackbar.handleApiMessages((_b = (_a = apiRegisterState.error) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.messages);
        }
        if (apiList.error) {
            snackbar.handleApiMessages((_c = apiList.error.data) === null || _c === void 0 ? void 0 : _c.messages);
        }
    }, [apiRegisterState.error, apiList.error]);
    return (_jsx(Root, { children: _jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.RegisterAccountSSO }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "nameFirst", label: t("ssoRegistration_inputLabel_firstName") }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "nameLast", label: t("ssoRegistration_inputLabel_lastName") }, void 0), _jsx(FormikSelect, { isLoading: apiList.isLoading, disabled: isPharmacy, formikId: "groupProvince", label: t("ssoRegistration_inputLabel_province"), onChange: () => {
                            return null;
                        }, options: provinceList && (provinceList === null || provinceList === void 0 ? void 0 : provinceList.length) > 0
                            ? provinceList === null || provinceList === void 0 ? void 0 : provinceList.map((item) => ({
                                value: item.guid,
                                label: item.name,
                            }))
                            : [] }, void 0), isPharmacy && (_jsx(FormikTextField
                    //
                    , { 
                        //
                        disabled: true, fullWidth: true, formikId: "groupPharmacyName", label: "Pharmacy" }, void 0)), _jsx(FormikTextFieldMaskStyled, { formikId: "phone", label: t("ssoRegistration_inputLabel_phone") }, void 0), _jsx(RootTerms, Object.assign({ "data-cy": "privacy-policy-sso-checkbox" }, { children: _jsx(FormikCheckbox, { formikId: "isCheckbox", label: t("ssoRegistration_agreementOne", {
                                privacyPolicy: (_jsx(ButtonNavigate, { style: ButtonStyle.TextAnchor, pathTo: "https://mednow.ca/pages/privacy-policy", isNewTab: true, content: t("ssoRegistration_agreementOne_privacyPolicy") }, void 0)),
                                termsOfService: (_jsx(ButtonNavigate, { style: ButtonStyle.TextAnchor, pathTo: "https://mednow.ca/pages/terms-of-use", isNewTab: true, content: t("ssoRegistration_agreementOne_termsOfService") }, void 0)),
                            }), isSecondary: true }, void 0) }), void 0), _jsx(ButtonStandard
                    //
                    , { 
                        //
                        style: ButtonStyle.SolidPrimary, content: "Sign Up", iconRight: "person", isSubmit: true, isWide: true }, void 0), _jsx(FormikErrorFocus, {}, void 0)] }), void 0) }), void 0) }, void 0));
};
