import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { routes } from "@/src/routes";
import { Nav } from "@/src/features/Nav/Nav";
import styled from "styled-components";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { NotificationList } from "@/src/modules/user/layout/notification-settings/NotificationList";
import { AppStoreLinks } from "@/src/common/components/app-store/AppStoreLinks";
const Root = styled.div `
	& {
	}
`;
const RootLinks = styled.div `
	& {
		align-items: center;
		display: flex;
		height: 48px;
		justify-content: center;
		margin: 48px 0;
		//padding: 0 24px;
	}
`;
export const LayoutNotificationSettings = () => {
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { 
                //
                dataLeft: { leftItemType: "Back", linkTo: routes.Home.toPath() }, dataRight: { rightItemType: "StartChat" } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader
                            //
                            , { 
                                //
                                title: "Notifications", description: "These settings affect notifications you will receive. Not all notifications can be disabled." }, void 0), _jsx(NotificationList, {}, void 0), _jsx(RootLinks, { children: _jsx(AppStoreLinks, {}, void 0) }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
