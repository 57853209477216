import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { WorkflowTrackingInfo } from "@/src/features/Workflows/WorkflowTrackingInfo";
import { SummaryList } from "@/src/modules/workflow/layout/workflow/summary/SummaryList";
import { WorkflowFulfillment } from "@/src/modules/workflow/type/WorkflowFulfillment";
import styled from "styled-components";
const RootHint = styled.div `
	& {
		${TypographyV3.Hint};
	}
`;
export const WorkflowTracking = (p) => {
    const workflow = p.workflow;
    const details = p.workflow.details;
    const isTracking = Boolean(details.trackingNotes) || Boolean(details.trackingURL);
    const isDelivery = details.fulfillment == WorkflowFulfillment.Delivery;
    if (!isDelivery) {
        return null;
    }
    return (_jsxs(SummaryList, Object.assign({ title: "Track your order" }, { children: [!isTracking && _jsx(RootHint, { children: "Tracking information will be provided once order ships." }, void 0), _jsx(WorkflowTrackingInfo, { workflow: workflow }, void 0)] }), void 0));
};
