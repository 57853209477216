import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { MAX_DESKTOP_INNER_CONTENT_WIDTH } from "@/src/constants";
import { Select } from "@/src/features/common/FormInputs";
import { t } from "@/src/features/Localization";
import { PatientAvatar } from "@/src/features/Patient/PatientAvatar";
import { useProfile } from "@/src/features/Profile/useProfile";
import { useAuth } from "@/src/hooks/useAuth";
import { useCustomHistory } from "@/src/hooks/useCustomHistory";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { memo, useContext } from "react";
import styled from "styled-components";
var SpecialSelectValue;
(function (SpecialSelectValue) {
    SpecialSelectValue["AddAPatient"] = "ProfilePatientSelectAddAPatient";
})(SpecialSelectValue || (SpecialSelectValue = {}));
const ProfilePatientSelectContainer = styled.div `
	width: ${MAX_DESKTOP_INNER_CONTENT_WIDTH}px;
	max-width: 100%;
	margin: 0 auto;
`;
const SelectStyled = styled(Select) `
	&& .MuiInputBase-root {
		background: #fff;
	}
`;
const ProfileIconStyled = styled(PatientAvatar) ``;
const CustomMenuItemContainer = styled.div `
	display: flex;
	flex-direction: row;
	gap: 12px;
	align-items: center;
	height: 24px;
	max-width: 100%;

	& ${ProfileIconStyled} {
		display: inline-flex;
	}
`;
const CustomMenuItemIconContainer = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
`;
const CustomMenuItemLabel = styled.div `
	${TypographyV3.FieldInput};
	text-overflow: ellipsis;
	overflow: hidden;
`;
/* ---------------------------- Helper Components --------------------------- */
const CustomMenuItem = ({ value, option }) => {
    var _a, _b;
    const bc = useContext(BrandContext);
    const isSelected = value === option.value;
    const isAddPatient = option.value === SpecialSelectValue.AddAPatient;
    const labelToCaption = (_a = option === null || option === void 0 ? void 0 : option.extraData) === null || _a === void 0 ? void 0 : _a.labelToCaption;
    const profileIconColor = (_b = option === null || option === void 0 ? void 0 : option.extraData) === null || _b === void 0 ? void 0 : _b.color;
    const icon = isAddPatient ? _jsx(Symbol, { icon: "add" }, void 0) : _jsx(ProfileIconStyled, { color: profileIconColor !== null && profileIconColor !== void 0 ? profileIconColor : "grey", size: 24 }, void 0);
    return (_jsxs(CustomMenuItemContainer, { children: [_jsx(CustomMenuItemIconContainer, { children: icon }, void 0), _jsxs(CustomMenuItemLabel, Object.assign({ color: bc.Colors.inputPrimary }, { children: [option.label, Boolean(labelToCaption) && _jsxs("span", { children: [" (", labelToCaption, ")"] }, void 0)] }), void 0)] }, void 0));
};
/* ---------------------------- Helper Functions ---------------------------- */
function getCurrentUserSelectOption({ user }) {
    if (!user) {
        return undefined;
    }
    const { guid } = user || {};
    const { color, nameFirst, nameLast } = (user === null || user === void 0 ? void 0 : user.details) || {};
    return {
        label: `${nameFirst} ${nameLast}`,
        value: guid,
        extraData: {
            color,
        },
    };
}
export const ProfilePatientSelect = memo(({ onChangeSelectedPatientGuid, selectedPatientGuid, className }) => {
    const { historyPush } = useCustomHistory();
    const { user } = useAuth();
    const { userRelationships, isLoadingUserRelationships } = useProfile();
    const segment = useSegmentAnalytics();
    const userGuid = user === null || user === void 0 ? void 0 : user.guid;
    const currentUserSelectOption = getCurrentUserSelectOption({ user });
    let options = 
    // TODO: reidenzon - Need to confirm these are ONLY dependents!
    (userRelationships === null || userRelationships === void 0 ? void 0 : userRelationships.map((item) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return ({
            label: `${(_b = (_a = item === null || item === void 0 ? void 0 : item.userTo) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.nameFirst} ${(_d = (_c = item === null || item === void 0 ? void 0 : item.userTo) === null || _c === void 0 ? void 0 : _c.details) === null || _d === void 0 ? void 0 : _d.nameLast}`,
            value: (_e = item === null || item === void 0 ? void 0 : item.userTo) === null || _e === void 0 ? void 0 : _e.guid,
            extraData: {
                color: (_g = (_f = item === null || item === void 0 ? void 0 : item.userTo) === null || _f === void 0 ? void 0 : _f.details) === null || _g === void 0 ? void 0 : _g.color,
                labelToCaption: (_h = item === null || item === void 0 ? void 0 : item.labelTo) === null || _h === void 0 ? void 0 : _h.caption,
            },
        });
    })) || [];
    // Add "Add a patient selection"
    options = [
        ...(currentUserSelectOption ? [currentUserSelectOption] : []),
        ...options,
        {
            label: t("profile_patient_select_add_a_patient_label"),
            value: SpecialSelectValue.AddAPatient,
        },
    ];
    const handleChange = (e) => {
        var _a;
        const newValue = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value;
        if (newValue === SpecialSelectValue.AddAPatient) {
            segment.segmentTrackEvent("select_add_patient", {
                entrypoint: SegmentEntryPoint.PatientProfileDropdown,
            });
            historyPush(routes.Profiles.toPath({
                addPatient: true,
                addPatientSuccessRedirect: routes.Profile.toPath({
                // TODO: reidenzon - Need GUID of new user! Will inject in post-creation...
                }),
            }));
        }
        else {
            const patientGuid = newValue;
            // This small timeout was necessary because the select's modal overlay background
            // sometimes may still be open before it gets a chance to trigger the close and remove for it,
            // and then the component would rerender, which will then cause that overlay to persist
            // and not be removed.
            setTimeout(() => {
                onChangeSelectedPatientGuid(patientGuid);
            }, 50);
            segment.segmentTrackEvent("select_patient_profile", {
                entrypoint: SegmentEntryPoint.PatientProfileDropdown,
                patient_id: patientGuid,
            });
        }
    };
    const handleOpen = () => {
        segment.segmentTrackEvent("select_patient_dropdown");
    };
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(ProfilePatientSelectContainer, Object.assign({ className: className }, { children: _jsx(SelectStyled, { 
            // NOTE: we use `isLoadingUserRelationships` instead of `isFetchingUserRelationships` so
            // the `isLoading` will not be triggered upon a refetch.
            // Instead, it will silently update, without a loader spinner being shown.
            isLoading: isLoadingUserRelationships, label: t("profile_patient_select_label"), name: "ProfilePatientSelect", options: options, onChange: handleChange, onOpen: handleOpen, 
            // If no value is selected, then set selcted value to userGuid
            value: selectedPatientGuid || userGuid, "data-cy": `profile-patient-select}`, CustomOptionComponent: CustomMenuItem }, "ProfilePatientSelect"
        // NOTE: we use `isLoadingUserRelationships` instead of `isFetchingUserRelationships` so
        // the `isLoading` will not be triggered upon a refetch.
        // Instead, it will silently update, without a loader spinner being shown.
        ) }), "ProfilePatientSelectContainer"));
});
