import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import { PatientSelectCard, PatientSelectListCardAdd } from "@/src/features/PatientSelect/PatientSelectListCard";
import { useCustomHistory } from "@/src/hooks/useCustomHistory";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
import styled from "styled-components";
const ProfileCardContainer = styled.div `
	display: flex;
	flex-direction: column;
	margin-top: 32px;
	gap: 16px;
`;
export const PatientSelectList = (p) => {
    /* ---------------------------------- hooks --------------------------------- */
    const segment = useSegmentAnalytics();
    const { historyPush } = useCustomHistory();
    const history = useHistory();
    /* ---------------------------------- Helper Functions --------------------------------- */
    function handleCreateNewProfile() {
        segment.segmentTrackEvent("select_add_patient", {
            entrypoint: p.segmentNewProfileEntryPoint || SegmentEntryPoint.PatientProfilesPage,
        });
        historyPush(p.addFormUrl ||
            routes.Profiles.toPath({
                addPatient: true,
                addPatientSuccessRedirect: p.addSuccessUrl,
            }));
    }
    function handleClick({ userGuid, userDetails, userSettings }) {
        if (p.onDependentProfileClick) {
            // TODO Bhav: rethink this
            // currently we're sending back the whole user as some components
            // require the whole user (settings, details) instead of just the guid
            // this is to save a call to the backend
            p.onDependentProfileClick({ userGuid, userDetails, userSettings });
        }
        else {
            history.push(routes.Profile.toPath({ guid: userGuid }));
        }
    }
    /* ---------------------------------- Render Component --------------------------------- */
    return (_jsxs(_Fragment, { children: [_jsx(LayoutContentHeader, { title: p.title, description: p.description }, void 0), _jsxs(ProfileCardContainer, { children: [_jsx(FieldHeader, { title: "Select Patient" }, void 0), p.userProfiles.map((userProfile) => {
                        return (_jsx(PatientSelectCard, { userGuid: userProfile.userGuid, userDetails: userProfile.userDetails, userSettings: userProfile.userSettings, label: userProfile.relationshipLabelCaption, handleClick: handleClick, isMedVisit: p.isMedVisit, isDexcom: p.isDexcom, isRxCount: p.isRxCount, isRxReview: p.isRxReview, isServiceClinical: p.isServiceClinical }, userProfile.userGuid));
                    }), p.isAddPatient && _jsx(PatientSelectListCardAdd, { handleClick: handleCreateNewProfile }, void 0)] }, void 0), _jsx(AssistanceLink, {}, void 0)] }, void 0));
};
