import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getPriceInCentsAsString } from "@/src/common/utility/price/Price";
import { InvoiceRowHeader } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRowHeader";
import { InvoiceRowItem } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRowItem";
import { InvoiceRowShipping } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRowShipping";
import { InvoiceRowTip } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRowTip";
import { InvoiceRowTotal } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRowTotal";
import { workflowPaymentTotal } from "@/src/modules/workflow/type/WorkflowPayment";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const InvoiceTable = (p) => {
    var _a;
    const workflow = p.workflow;
    const payment = p.payment;
    const isTip = Boolean(payment.isTip);
    const total = getPriceInCentsAsString(workflowPaymentTotal(payment), true);
    return (_jsxs(Root, { children: [_jsx(InvoiceRowHeader, {}, void 0), (_a = payment.items) === null || _a === void 0 ? void 0 : _a.map((item, index) => {
                return _jsx(InvoiceRowItem, { item: item }, index);
            }), isTip && _jsx(InvoiceRowTip, { onEdit: p.onEditTip, payment: payment, workflow: workflow }, void 0), _jsx(InvoiceRowShipping, {}, void 0), _jsx(InvoiceRowTotal, { total: total }, void 0)] }, void 0));
};
