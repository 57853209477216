import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: start;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const Benefits = (p) => {
    const bc = useContext(BrandContext);
    return (_jsxs(Root, { children: [_jsxs(GridRow, { children: [_jsx(Symbol, { icon: "check", color: bc.Colors.icon }, void 0), _jsx("span", { children: "Instant access to a pharmacist" }, void 0)] }, void 0), _jsxs(GridRow, { children: [_jsx(Symbol, { icon: "check", color: bc.Colors.icon }, void 0), _jsx("span", { children: "Easy prescription refills" }, void 0)] }, void 0), _jsxs(GridRow, { children: [_jsx(Symbol, { icon: "check", color: bc.Colors.icon }, void 0), _jsx("span", { children: "Fast, free delivery" }, void 0)] }, void 0)] }, void 0));
};
