import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
const RootLoader = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: column;
	}
`;
export const TaxReportGenerating = (p) => {
    return (_jsxs(Root, { children: [_jsx(LayoutContentHeader
            //
            , { 
                //
                title: `Generating ${p.year} year-end tax receipt report for ${getUserDetailsNameFull(p.patientDetails)}` }, void 0), _jsx(RootLoader, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)] }, void 0));
};
