var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldError } from "@/src/common/components/field/FieldError";
import { rxNumberListAppend, rxNumberListRemove } from "@/src/modules/user/type/pharmacylink/RxNumber";
import { FormikRxListHeader } from "@/src/modules/workflow/type/rx-refill-v3/components/draft/rx-list/FormikRxListHeader";
import { FormikRxListItem } from "@/src/modules/workflow/type/rx-refill-v3/components/draft/rx-list/FormikRxListItem";
import { resolveErrorTouched } from "@/src/utils/PathValue";
import { useFormikContext } from "formik";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const FormikRxList = (p) => {
    const w = p.workflow;
    const f = useFormikContext();
    const rxNumberList = f.values[p.formikId] || [];
    return (_jsxs(Root, { children: [_jsx(FormikRxListHeader, { patient: w.patient }, void 0), p.rxList.map((rx) => {
                return (_jsx(FormikRxListItem, { onSelect: (is) => __awaiter(void 0, void 0, void 0, function* () {
                        if (is)
                            yield f.setFieldValue(p.formikId, rxNumberListAppend(rxNumberList, rx.rxNumber));
                        if (!is)
                            yield f.setFieldValue(p.formikId, rxNumberListRemove(rxNumberList, rx.rxNumber));
                    }), rx: rx, rxNumberList: rxNumberList }, rx.rxNumber));
            }), _jsx(FieldError, { error: resolveErrorTouched(f, p.formikId) }, void 0)] }, void 0));
};
