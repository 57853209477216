import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
export const RootContent = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const RootImage = styled.img `
	& {
		border-radius: 8px;
		margin-bottom: 24px;
		max-width: 100%;
		width: 100%;
	}
`;
export const RootHeader = styled.div `
	& {
		${TypographyV3.Header3};
		text-align: center;
	}
`;
export const RootText = styled.div `
	& {
		${TypographyV3.Body};
		text-align: center;
	}
`;
