import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import Modal from "@mui/material/Modal";
const ButtonStyled = styled.button `
	border: none;
	padding: 0;
	background: none;
`;
const ImageStyled = styled.div `
	background-image: url(${(props) => props.imageSrc});
	width: calc(100vw - 16px);
	height: calc(100% - 16px);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin: 8px;
`;
export default function BasicModal({ children, imageSrc, }) {
    const [isOpen, setIsOpen] = useState(false);
    function handleModalOpen() {
        setIsOpen(true);
    }
    function handleModalClose() {
        setIsOpen(false);
    }
    return (_jsxs(_Fragment, { children: [_jsx(Modal, Object.assign({ open: isOpen, onClose: handleModalClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsx(ImageStyled, { onClick: handleModalClose, imageSrc: imageSrc }, void 0) }), void 0), _jsx(ButtonStyled, Object.assign({ type: "button", onClick: handleModalOpen }, { children: children }), void 0)] }, void 0));
}
