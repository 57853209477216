import { SlideshowStep01 } from "@/src/modules/onboarding/components/slideshow/SlideshowStep01";
import { SlideshowStep02 } from "@/src/modules/onboarding/components/slideshow/SlideshowStep02";
import { SlideshowStep03 } from "@/src/modules/onboarding/components/slideshow/SlideshowStep03";
import { SlideshowStep04 } from "@/src/modules/onboarding/components/slideshow/SlideshowStep04";
import { SlideshowStep05 } from "@/src/modules/onboarding/components/slideshow/SlideshowStep05";
export const SlideshowStepList = [
    {
        Component: SlideshowStep01,
    },
    {
        Component: SlideshowStep02,
    },
    {
        Component: SlideshowStep03,
    },
    {
        Component: SlideshowStep04,
    },
    {
        Component: SlideshowStep05,
    },
];
