import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Icons } from "@/src/features/common";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { Nav } from "@/src/features/Nav/Nav";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { useHyperlinkResolveSingleSendAgainMutation, useHyperlinkViewSingleQuery } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const Root = styled.div `
	margin-top: 64px;
	text-align: center;
`;
const RootImage = styled.div `
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
`;
export const ResendHyperlinkPage = () => {
    var _a;
    const history = useHistory();
    const { query } = useQuery();
    const hyperlinkToken = (query === null || query === void 0 ? void 0 : query.get("token")) || "";
    const apiView = useHyperlinkViewSingleQuery(hyperlinkToken, { skip: !hyperlinkToken });
    const hyperlink = (_a = apiView.data) === null || _a === void 0 ? void 0 : _a.data.hyperlink;
    const [apiSendAgain, apiSendAgainState] = useHyperlinkResolveSingleSendAgainMutation();
    const handleClick = () => {
        apiSendAgain({
            token: hyperlinkToken,
        }).then(() => {
            history.push(routes.ResendHyperlinkSuccess.toPath({
                type: hyperlink === null || hyperlink === void 0 ? void 0 : hyperlink.type,
            }));
        });
    };
    // TODO - There may be cases where resend link throws an error
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader, { title: t("linkExpired_header") }, void 0), _jsx(RootImage, { children: _jsx(Icons.HyperlinkExpired, { width: 187, height: 102 }, void 0) }, void 0), _jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.SolidPrimary, content: "Resend Link", iconRight: "refresh", onClick: handleClick, isLoading: apiSendAgainState.isLoading, isWide: true }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
