import { jsx as _jsx } from "react/jsx-runtime";
import { FulfillmentPharmacy } from "@/src/modules/workflow/layout/workflow/draft/fulfillment/FulfillmentPharmacy";
import { SummaryList } from "@/src/modules/workflow/layout/workflow/summary/SummaryList";
import { SummaryNote } from "@/src/modules/workflow/layout/workflow/summary/SummaryNote";
import { WorkflowFulfillment } from "@/src/modules/workflow/type/WorkflowFulfillment";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const WorkflowPickup = (p) => {
    const workflow = p.workflow;
    const details = workflow.details;
    const isPickup = details.fulfillment == WorkflowFulfillment.Pickup;
    if (!isPickup) {
        return null;
    }
    return (_jsx(SummaryList, Object.assign({ title: "Pickup details" }, { children: _jsx(SummaryNote, { children: _jsx(FulfillmentPharmacy, { workflow: workflow }, void 0) }, void 0) }), void 0));
};
