import { jsx as _jsx } from "react/jsx-runtime";
import { BreakpointPx } from "@/src/enums/breakpoints";
import { SoundNotificationVolumeToggleButton } from "@/src/features/SoundNotifications/SoundNotificationVolumeToggleButton";
import styled from "styled-components";
const Root = styled.div `
	margin-right: 26px;
	margin-top: 8px;

	// Mobile only styles
	// NOTE: we are not showing ChatMessageInputExtraFooter on mobile
	// width, because we do not need it (specificy do not need volumne on/off).
	display: none;

	// Desktop only styles
	@media (min-width: ${BreakpointPx.Desktop}) {
		// NOTE: show volume on/off (and ChatMessageInputExtraFooter in general)
		// on desktop width only
		display: block;
	}
`;
const SoundNotificationVolumeToggleButtonStyled = styled(SoundNotificationVolumeToggleButton) `
	margin-left: auto;
`;
export const ChatMessageInputExtraFooter = (p) => {
    return (_jsx(Root, { children: _jsx(SoundNotificationVolumeToggleButtonStyled, {}, void 0) }, void 0));
};
