import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { getDateStringAsLocaleDate } from "@/src/common/utility/time/DateString";
import { getUserRxResponseDispenseQuantity } from "@/src/modules/user/redux/contracts/UserRxViewList";
import { RxCardCount } from "@/src/modules/workflow/type/rx-refill-v3/components/draft/rx-list/RxCardCount";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex: 1;
		flex-direction: row;
		gap: 12px;
		position: relative;
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 4px;
	}

	${Root}.disabled & {
		opacity: 0.5;
	}
`;
const RootNumber = styled.div `
	& {
		${TypographyV3.Body};
		color: ${(p) => p.theme.textHeader};
		font-weight: 600;
		display: inline;
	}
`;
const RootName = styled.div `
	& {
		${TypographyV3.Body};
		display: inline;
	}
`;
const RootQuantity = styled.div `
	& {
		${TypographyV3.Body};
		display: inline;
	}
`;
const RootDateRefill = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
export const RxCard = (p) => {
    const rx = p.rx;
    const isDisabled = !rx.isRefill;
    const dispenseQuantity = getUserRxResponseDispenseQuantity(rx);
    return (_jsxs(Root, Object.assign({ className: isDisabled ? "disabled" : "" }, { children: [_jsxs(RootContent, { children: [_jsxs(GridRow, { children: [_jsx(RootNumber, { children: `Rx. ${rx.rxNumber}` }, void 0), _jsx(RootQuantity, { children: dispenseQuantity }, void 0)] }, void 0), _jsx(RootName, { children: rx.namePrimary }, void 0), rx.timeRefill && _jsx(RootDateRefill, { children: `Depletion date: ${getDateStringAsLocaleDate(rx.timeRefill)}` }, void 0)] }, void 0), _jsx("div", { children: _jsx(RxCardCount, { rx: rx }, void 0) }, void 0)] }), void 0));
};
