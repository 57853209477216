import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const SummaryRxWorkflow = (p) => {
    const rx = p.rx;
    const parts = [
        //
        rx.drugNamePrimary,
        rx.quantityDispense,
        (rx.drugForm || "UNIT") + ",",
        rx.drugNameSecondary,
    ];
    return _jsx(Root, { children: parts.join(" ") }, void 0);
};
