import { ProfileAccount } from "@/src/features/Profile/Profile/ProfileAccount";
import { ProfileAllergies } from "@/src/features/Profile/Profile/ProfileAllergies";
import { ProfileInsurance } from "@/src/features/Profile/Profile/ProfileInsurance";
import { ProfileWallet } from "@/src/features/Profile/Profile/ProfileWallet";
export var TabValue;
(function (TabValue) {
    TabValue["Account"] = "account";
    TabValue["Coverage"] = "coverage";
    TabValue["Wallet"] = "wallet";
    TabValue["Allergies"] = "allergies";
})(TabValue || (TabValue = {}));
export const DEFAULT_PROFILE_TAB_VALUE = TabValue.Account;
export const profileTabs = {
    [TabValue.Account]: {
        value: TabValue.Account,
        Component: ProfileAccount,
        labelCopyKey: "profile_tab_account_tabLabel",
    },
    [TabValue.Coverage]: {
        value: TabValue.Coverage,
        Component: ProfileInsurance,
        labelCopyKey: "profile_tab_insurance_tabLabel",
    },
    [TabValue.Wallet]: {
        value: TabValue.Wallet,
        Component: ProfileWallet,
        labelCopyKey: "profile_tab_wallet_tabLabel",
        isHideForDependent: true,
    },
    [TabValue.Allergies]: {
        value: TabValue.Allergies,
        Component: ProfileAllergies,
        labelCopyKey: "profile_tab_allergies_tabLabel",
    },
};
// "profileTabsAsArray" is just used internally
const profileTabsAsArray = Object.values(profileTabs);
/* --------------------------- Exported Functions --------------------------- */
/**
 * This will return the profile tabs, and exclude any which should
 * not be included.
 */
export function getProfileTabs({ isSelectedPatientDependent }) {
    return profileTabsAsArray.filter((tab) => {
        const isDependentAndShouldHideThisTab = isSelectedPatientDependent && tab.isHideForDependent;
        return !isDependentAndShouldHideThisTab;
    });
}
export function getProfileTabValues({ isSelectedPatientDependent }) {
    return getProfileTabs({ isSelectedPatientDependent }).map((tab) => tab.value);
}
export function getSanitizedTabValue({ tabValue, isSelectedPatientDependent }) {
    const tabValues = getProfileTabValues({ isSelectedPatientDependent });
    if (!tabValue) {
        return DEFAULT_PROFILE_TAB_VALUE;
    }
    if (tabValues.includes(tabValue)) {
        return tabValue;
    }
    return DEFAULT_PROFILE_TAB_VALUE;
}
