import { BreakpointPx } from "@/src/enums/breakpoints";
import styled, { css } from "styled-components";
/* ------------------------- Style Helper Functions ------------------------- */
/**
 * This converts the "CssBackgroundImagePosition" type to its relative css value.
 */
function getCssBackgroundPosition({ backgroundPosition, multiplier = 1 }) {
    // This will return for example: ["top 0px", "right -30px"]
    const backgroundPosAsArray = Object.entries(backgroundPosition).map(([key, value]) => {
        return `${key} ${value * multiplier}px`;
    });
    return backgroundPosAsArray.join(" ");
}
function getCalculatedBackgroundCssValues(backgroundImage) {
    const { imageSource, backgroundPosition, maxWidth, minSizeMultiplier } = backgroundImage;
    const defaultBackgroundRepeat = "no-repeat";
    return {
        backgroundImage: `url(${imageSource})`,
        backgroundPosition: getCssBackgroundPosition({
            backgroundPosition,
            multiplier: minSizeMultiplier,
        }),
        backgroundPositionLarge: getCssBackgroundPosition({
            backgroundPosition,
        }),
        backgroundRepeat: defaultBackgroundRepeat,
        backgroundSize: `${maxWidth * minSizeMultiplier}px`,
        backgroundSizeLarge: `${maxWidth}px`,
    };
}
/**
 * This function can be used within a styled component which can use a
 * "$backgroundImages" prop to add background images to the style.
 */
export function cssAddBackgroundImages({ $backgroundImages }) {
    // If there is no background image source, then do not apply any css background images.
    if (!$backgroundImages || ($backgroundImages === null || $backgroundImages === void 0 ? void 0 : $backgroundImages.length) === 0) {
        return "";
    }
    // NOTE: We will only show desktop background images for breakpoint above "md" (medium, aka, default 900px)
    // we can use the theme's breakpoints, as specified by slackoverflow answer:
    // https://stackoverflow.com/a/59922445/946217
    // Also see:
    // https://mui.com/material-ui/customization/breakpoints/#theme-breakpoints-between-start-end-media-query
    const backgroundImages = [];
    const backgroundPositions = [];
    const backgroundSizes = [];
    const backgroundRepeat = [];
    const backgroundSizesLarge = []; // Background sizes when on DesktopLarge
    $backgroundImages.forEach((backgroundImage) => {
        const calculatedBackgroundCssValues = getCalculatedBackgroundCssValues(backgroundImage);
        backgroundImages.push(calculatedBackgroundCssValues.backgroundImage);
        backgroundPositions.push(calculatedBackgroundCssValues.backgroundPosition);
        backgroundSizes.push(calculatedBackgroundCssValues.backgroundSize);
        backgroundRepeat.push(calculatedBackgroundCssValues.backgroundRepeat);
        backgroundSizesLarge.push(calculatedBackgroundCssValues.backgroundSizeLarge);
    });
    return css `
		@media (min-width: ${BreakpointPx.Desktop}) {
			background-image: ${backgroundImages.join(", ")};
			background-position: ${backgroundPositions.join(", ")};
			background-size: ${backgroundSizes.join(", ")};
			background-repeat: ${backgroundRepeat.join(", ")};
		}
		@media (min-width: ${BreakpointPx.DesktopLarge}) {
			background-size: ${backgroundSizesLarge.join(", ")};
		}
	`;
}
/* --------------------------------- HOCs --------------------------------- */
/**
 * This Higher Order Component (HOC) will add the "$backgroundImages?: CssBackgroundImage[];"
 * prop to be added to a component so that the component will add background images
 * to it.
 */
const StyledCssBackgroundImages = styled.div `
	${cssAddBackgroundImages}
`;
export const withCssBackgroundImages = StyledCssBackgroundImages.withComponent;
