import { jsx as _jsx } from "react/jsx-runtime";
import { StreamContext } from "@/src/features/Chat/StreamProvider";
import { WorkflowStatusChatClosed } from "@/src/modules/workflow/type/chat-v1/Status";
import { useWorkflowEditMutation } from "@/src/redux/apiServices/suiteApi";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useContext } from "react";
import styled from "styled-components";
const NavChatButtonContainer = styled.button `
	display: flex;
	background: none;
	margin: 12px;
	padding-top: 6px;
	height: 32px;
	justify-content: right;
`;
export const NavCancelChatButton = ({ className }) => {
    const { segmentTrackEvent } = useSegmentAnalytics();
    const sc = useContext(StreamContext);
    const [apiWorkflowEdit] = useWorkflowEditMutation();
    const handleClick = () => {
        const orderWorkflowDetails = {};
        if (sc.chatWorkflow) {
            apiWorkflowEdit({
                guid: sc.chatWorkflow.guid,
                status: WorkflowStatusChatClosed,
                details: orderWorkflowDetails,
            });
        }
        // Analytics tracking
        segmentTrackEvent("select_end_chat");
        sc.isEndedSet(true);
        sc.channelsSet([]);
    };
    return _jsx(NavChatButtonContainer, { onClick: handleClick, className: className, type: "button", "data-cy": "end-chat-button" }, void 0);
};
