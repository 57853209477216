import { cssAddBackgroundImages } from "@/src/features/common/HOCs/withCssBackgroundImages/withCssBackgroundImages";
import { CssBackgroundImagesValues } from "@/src/features/cssBackgroundImages/cssBackgroundImageValues";
import styled from "styled-components";
/* ------------------------- Style Helper Functions ------------------------- */
/**
 * This function can be used within a styled component which can use a
 * "$backgroundImages" prop to add background images to the style.
 *
 * As a fallback, if no "$backgroundImages" is passed the default background images
 * used for a page is used. This is so across multiple pages we have an easy HOC
 * to add default background images without having to repeatedly pass "$backgroundImages"
 * with the default values. While also ensuring "cssAddBackgroundImages" function can be
 * used in the future on other components that may used background images that have a different
 * fallback (i.e. on a component that isn't the "PageOverflowContainer" component).
 */
function cssAddBackgroundImagesWithDefaultPageFallback({ $backgroundImages = CssBackgroundImagesValues.Page.Default, }) {
    return cssAddBackgroundImages({ $backgroundImages });
}
/* ---------------------------------- HOCs ---------------------------------- */
/**
 * This Higher Order Component (HOC) will add the "$backgroundImages?: CssBackgroundImage[];"
 * prop to be added to a component so that the component will add background images
 * to it.
 *
 * But if no $backgroundImages value is passed then fallback to default page background images.
 * This is so the default page background images used across the app aren't needed to be added
 * multiple times across the sites. While still ensuring "withCssBackgroundImages(...)" HOC can be
 * used for future use to add backgroundImages to components easily
 */
const StyledCssBackgroundImagesWithDefaultPageFallback = styled.div `
	${cssAddBackgroundImagesWithDefaultPageFallback}
`;
export const withCssBackgroundImagesWithDefaultPageFallback = StyledCssBackgroundImagesWithDefaultPageFallback.withComponent;
