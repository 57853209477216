import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AdvisoryBox } from "@/src/common/components/advistory/AdvisoryBox";
import { AdvisoryStyle } from "@/src/common/components/advistory/AdvisoryStyle";
import { getDateStringAsLocaleDate } from "@/src/common/utility/time/DateString";
import { getHourStringRangeAsLocaleString } from "@/src/common/utility/time/HourString";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import { Schedule } from "@mui/icons-material";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const UberScheduled = (p) => {
    var _a, _b;
    const w = p.workflow;
    const wd = w.details;
    const statusInfo = getWorkflowStatusInfo(w.status);
    if (!statusInfo.StatusIsScheduled) {
        return null;
    }
    return (_jsx(AdvisoryBox
    //
    , { 
        //
        style: AdvisoryStyle.Success, icon: _jsx(Schedule, {}, void 0), title: "Uber", content: _jsxs(_Fragment, { children: [_jsx("div", { children: getDateStringAsLocaleDate((_a = wd.deliverySchedule) === null || _a === void 0 ? void 0 : _a.date) }, void 0), _jsx("div", { children: getHourStringRangeAsLocaleString((_b = wd.deliverySchedule) === null || _b === void 0 ? void 0 : _b.hourRange) }, void 0)] }, void 0) }, void 0));
};
