var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { consoleCatch } from "@/src/common/utility/log/Log";
import { MessageSimple } from "@/src/features/Chat/MessageSimpleCustom";
import { chatClient } from "@/src/features/Chat/StreamProvider";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { useAuth } from "@/src/hooks/useAuth";
import { useEffect, useState } from "react";
import { Channel, Chat, MessageList, Window } from "stream-chat-react";
import styled from "styled-components";
const Root = styled.div `
	& {
		overflow: hidden;
	}

	& .str-chat {
		height: initial !important;
		max-height: initial !important;
	}

	& .str-chat__list {
		padding: 0 12px !important;
	}

	& .str-chat__reverse-infinite-scroll {
		padding-top: 12px !important;
	}
`;
export const ChatterHistoryItemContent = (p) => {
    const auth = useAuth();
    const user = auth.user;
    const w = p.workflow;
    const [channel, setChannel] = useState();
    const handleChannel = () => __awaiter(void 0, void 0, void 0, function* () {
        const channels = yield chatClient.queryChannels({
            type: "messaging",
            members: { $in: [user.guid] },
            id: { $eq: w.guid },
        }, { last_message_at: -1 }, { watch: true, presence: true });
        setChannel(channels[0]);
    });
    useEffect(() => {
        handleChannel().catch(consoleCatch);
    }, []);
    if (!channel) {
        return _jsx(LoaderSpinner, {}, void 0);
    }
    return (_jsx(Root, { children: _jsx(Chat, Object.assign({ client: chatClient, theme: "commerce light" }, { children: _jsx(Channel, Object.assign({ channel: channel }, { children: _jsx(Window, { children: _jsx(MessageList, { disableDateSeparator: true, messageActions: [], Message: MessageSimple }, void 0) }, void 0) }), void 0) }), void 0) }, void 0));
};
