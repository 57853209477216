import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AdvisoryList } from "@/src/common/components/advistory/AdvisoryList";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ButtonTelephone } from "@/src/common/components/button/ButtonTelephone";
import { ModalFooterRowEnd } from "@/src/common/components/modal/ModalFooterRowEnd";
import { ModalContext } from "@/src/common/components/modal/ModalProvider";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		margin: 24px;
	}
`;
export const WorkflowModalUrgent = (p) => {
    return (_jsxs(Root, { children: [_jsx(AdvisoryList, { title: "Please Note:", items: [
                    {
                        title: "Delivery and pickup times are dependent on location.",
                        description: (_jsxs(_Fragment, { children: ["If you need this medication urgently, please call the pharmacy directly at ", _jsx(ButtonTelephone, {}, void 0)] }, void 0)),
                    },
                ] }, void 0), _jsxs(ModalFooterRowEnd, { children: [_jsx(ButtonStandard, { content: "Back", onClick: p.onBack, style: ButtonStyle.SolidGhost }, void 0), _jsx(ButtonStandard, { content: "Ok, understood!", onClick: p.onNext, style: ButtonStyle.SolidPrimary }, void 0)] }, void 0)] }, void 0));
};
export const useWorkflowModalUrgent = () => {
    const mc = useContext(ModalContext);
    return {
        doModal: (p) => {
            mc.AddModal({
                guid: generateGuid(),
                content: (m) => {
                    return (_jsx(WorkflowModalUrgent, { onBack: () => {
                            var _a;
                            (_a = p.onBack) === null || _a === void 0 ? void 0 : _a.call(p);
                            m.onClose();
                        }, onNext: () => {
                            p.onNext();
                            m.onClose();
                        } }, void 0));
                },
            });
        },
    };
};
