import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { SummaryRxUser } from "@/src/modules/workflow/layout/workflow/summary/SummaryRxUser";
const Root = styled.div `
	& {
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
	}
`;
export const SummaryRxUserList = (p) => {
    return (_jsx(Root, { children: p.rxList.map((rx) => {
            return _jsx(SummaryRxUser, { rx: rx }, rx.rxNumber);
        }) }, void 0));
};
