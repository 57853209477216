import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	${TypographyV3.Badge};
	width: ${({ $width }) => ($width ? `${$width}px` : "24px")};
	height: ${({ $height }) => ($height ? `${$height}px` : "24px")};
	align-items: center;
	border-radius: ${({ $borderRadius }) => ($borderRadius ? `${$borderRadius}px` : "50%")};
	padding: 0;
	background: ${(p) => { var _a; return (_a = p.$background) !== null && _a !== void 0 ? _a : p.theme.statusError; }};
	color: ${(p) => { var _a; return (_a = p.$color) !== null && _a !== void 0 ? _a : p.theme.invert; }};
	display: flex;
	justify-content: center;
	position: ${({ $position }) => ($position ? $position : "absolute")};
	z-index: ${({ $zIndex }) => ($zIndex ? `${$zIndex}` : "2")};
	right: ${({ $right }) => ($right ? `${$right}px` : "auto")};
	left: ${({ $left }) => ($left ? `${$left}px` : "auto")};
	top: ${({ $top }) => ($top ? `${$top}px` : "auto")};
	bottom: ${({ $bottom }) => ($bottom ? `${$bottom}px` : "auto")};
	text-align: center;
`;
/** @deprecated TODO: reidenzon - Kill this with fire! */
export const ChipSmall = (p) => {
    return (_jsx(Root, Object.assign({ className: p.className, "$height": p.height, "$width": p.width, "$borderRadius": p.borderRadius, "$position": p.position, "$background": p.background, "$color": p.color, "$zIndex": p.zIndex, "$right": p.right, "$left": p.left, "$bottom": p.bottom, "$top": p.top }, { children: p.children }), void 0));
};
