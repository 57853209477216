var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Config } from "@/src/common/utility/config/Config";
import { ROUTE_LOGIN_WITH_BASE_PATH } from "@/src/constants";
import { Icons } from "@/src/features/common";
import { sendErrorLogToSuiteApi } from "@/src/features/common/ErrorLogging/sendErrorLogToSuiteApi";
import { ErrorLevel } from "@/src/features/common/ErrorLogging/typesAndEnums";
import { t } from "@/src/features/Localization";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { getVersionNumber } from "@/src/utils/BuildVersionHelpers";
import { useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 64px;
	margin-bottom: 40px;
`;
const RootImage = styled.div `
	aspect-ratio: 300/240;
	height: 160px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 32px;

	& svg {
		width: 100%;
		height: 100%;
	}
`;
function getNavigatorDetails() {
    return {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        platform: navigator.platform,
        product: navigator.product,
        productSub: navigator.productSub,
        userAgent: navigator.userAgent,
        vendor: navigator.vendor,
        vendorSub: navigator.vendorSub,
    };
}
const ErrorAppCrashPageContentInitialPage = ({ handleSubmitReport, isSubmittingReport }) => {
    return (_jsxs(Root, { children: [_jsx(LayoutContentHeader, { title: t("appCrash_header1") }, void 0), _jsx(RootImage, { children: _jsx(Icons.FancyCircleError, {}, void 0) }, void 0), _jsx(LayoutContentHeader, { description: t("appCrash_content1") }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, onClick: handleSubmitReport, isLoading: isSubmittingReport, content: t("appCrash_button_submitReport") }, void 0)] }, void 0));
};
const ErrorAppCrashPageContentSuccessPage = () => {
    return (_jsxs(Root, { children: [_jsx(LayoutContentHeader, { title: t("appCrash_header2") }, void 0), _jsx(RootImage, { children: _jsx(Icons.FancyCircleSuccess, {}, void 0) }, void 0), _jsx(LayoutContentHeader, { description: t("appCrash_content2") }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("appCrash_button_goToLogin"), onClick: () => {
                    // This will force a reload, etc... which is good.
                    location.href = ROUTE_LOGIN_WITH_BASE_PATH;
                } }, void 0)] }, void 0));
};
export const ErrorAppCrashPageContent = ({ 
// error,
// errorInfo,
errorDetails, }) => {
    const [isDidSuccessfullySubmitReport, setIsDidSuccessfullySubmitReport] = useState(false);
    const [isSubmittingReport, setIsSubmitting] = useState(false);
    function handleSubmitReport() {
        return __awaiter(this, void 0, void 0, function* () {
            const details = {
                // error,
                // errorInfo,
                errorDetails,
                navigatorDetails: getNavigatorDetails(),
                version: getVersionNumber(),
                buildId: Config.GitLabCommit,
                source: "web",
                locationHref: location.href,
            };
            setIsSubmitting(true);
            yield sendErrorLogToSuiteApi({
                level: ErrorLevel.Warning,
                details: JSON.stringify(details),
            });
            setIsDidSuccessfullySubmitReport(true);
            setIsSubmitting(false);
        });
    }
    if (isDidSuccessfullySubmitReport) {
        return _jsx(ErrorAppCrashPageContentSuccessPage, {}, void 0);
    }
    return _jsx(ErrorAppCrashPageContentInitialPage, { handleSubmitReport: handleSubmitReport, isSubmittingReport: isSubmittingReport }, void 0);
};
