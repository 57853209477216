import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
`;
export const SummarySet = (p) => {
    return _jsx(Root, { children: p.children }, void 0);
};
