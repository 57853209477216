import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
/* eslint-disable max-classes-per-file */
// For reference see:
// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/
//
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-optimization */
/* eslint-disable class-methods-use-this */
import { useState } from "react";
import styled from "styled-components";
/* --------------------------------- Styles --------------------------------- */
const TestButton = styled.button `
	height: 30px;
	background: grey;
	border: 1px solid black;
	border-radius: 15px;
	padding: 5px;
	margin: 5px;
`;
/* -------------------------------------------------------------------------- */
/*                               Main Component                               */
/* -------------------------------------------------------------------------- */
export const ErrorBoundaryTest = () => {
    /* ---------------------------------- Hooks --------------------------------- */
    const [isCrashApp, setIsCrashApp] = useState(false);
    /* ---------------------------- Helper Functions ---------------------------- */
    function handleClick() {
        setIsCrashApp(true);
    }
    /* ------------------------------- Conditions ------------------------------- */
    if (isCrashApp) {
        throw new Error("I crashed!");
    }
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(_Fragment, { children: _jsx(TestButton, Object.assign({ style: { marginTop: 40 }, type: "button", onClick: handleClick }, { children: "Click here to crash" }), void 0) }, void 0));
};
