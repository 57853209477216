import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		//background: lightgoldenrodyellow; // debug
		flex: 1;
		justify-content: center;
		padding: 4px 0;
	}
`;
const RootLine = styled.div `
	& {
		background: ${(p) => p.stateInfo.ColorLinePrimary};
		flex: 1;
		min-height: 24px;
		max-width: 2px;
		width: 2px;
	}
`;
export const TimelineStepLine = (p) => {
    const stateInfo = p.state;
    return (_jsx(Root, { children: _jsx(RootLine, { stateInfo: stateInfo }, void 0) }, void 0));
};
