import { jsx as _jsx } from "react/jsx-runtime";
import { JunctionNextStepsItem } from "@/src/modules/junction/components/home/JunctionNextStepsItem";
import styled from "styled-components";
const Root = styled.div `
	& {
		border-radius: 4px;
		display: flex;
		flex-direction: column;
	}

	&.highlight {
		background: ${(p) => p.theme.badgeBackground};
		gap: 8px;
	}

	&.standard {
		background: ${(p) => p.theme.cardBackground};
		border: 1px solid ${(p) => p.theme.divider};
	}
`;
export const JunctionNextStepsList = (p) => {
    // TODO: reidenzon - For DEBUG only!
    // const items = [...p.items, ...p.items, ...p.items];
    const items = p.items;
    const isHighlight = p.isHighlight;
    const isStandard = !isHighlight;
    return (_jsx(Root, Object.assign({ className: `${isHighlight && "highlight"} ${isStandard && "standard"}` }, { children: items.map((item, index) => {
            var _a;
            return _jsx(JunctionNextStepsItem, { item: item }, `${index}-${(_a = item.link) === null || _a === void 0 ? void 0 : _a.title}`);
        }) }), void 0));
};
