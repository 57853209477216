import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.FieldTitle};
	}
`;
const RootList = styled.div `
	& {
	}
`;
export const SummaryList = (p) => {
    return (_jsxs(Root, Object.assign({ className: "SummaryList" }, { children: [p.title && _jsx(RootTitle, { children: p.title }, void 0), _jsx(RootList, { children: p.children }, void 0)] }), void 0));
};
