import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { DeliveryMethodSelectHeader } from "@/src/modules/devliery-method/components/select/DeliveryMethodSelectHeader";
import { UberAvailabilityGeneral } from "@/src/modules/devliery-method/type/uber/components/UberAvailabilityGeneral";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootContent = styled.div `
	& {
		${TypographyV3.Hint};
	}
`;
export const DeliveryMethodSelect = (p) => {
    const w = p.workflow;
    const m = p.method;
    const d = m.details;
    return (_jsxs(Root, { children: [_jsx(DeliveryMethodSelectHeader
            //
            , { 
                //
                title: "Uber (to be scheduled)", price: "FREE" }, void 0), _jsx(RootContent, { children: _jsx(UberAvailabilityGeneral, { workflow: w }, void 0) }, void 0)] }, void 0));
};
