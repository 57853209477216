import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { DotStepper } from "@/src/common/components/dot-stepper/DotStepper";
import styled from "styled-components";
const Root = styled.header `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;
export const SlideshowHeader = (p) => {
    return (_jsxs(Root, { children: [p.isBack && (_jsx(ButtonStandard
            //
            , { 
                //
                style: ButtonStyle.TextSubtle, onClick: p.onBack, content: "Back" }, void 0)), _jsx("div", {}, void 0), _jsx(DotStepper, { onStep: (s) => p.onStep(s), step: p.step, steps: p.steps }, void 0)] }, void 0));
};
