import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	position: relative;
	box-sizing: border-box;
	padding-bottom: ${({ $isUseBottomPadding }) => ($isUseBottomPadding ? "32px" : "")};
	min-height: ${({ $useMaxHeight, $height }) => ($useMaxHeight ? "100%;" : $height)};
	height: ${({ $height }) => $height};
	display: ${({ $isCenterItems }) => ($isCenterItems ? "flex" : "block")};
	flex-direction: ${({ $isCenterItems }) => $isCenterItems && "column"};
`;
export const RootTitle = styled.div `
	${TypographyV3.Header3};
	margin-bottom: 32px;
`;
export const DrawerContent = ({ title, drawerHeader, drawerFooter, children, useMaxHeight, height, isCenterItems, className, }) => {
    // Do not use bottom padding if there is a `drawerFooter`
    // This is because otherwise the bottom padding when the
    // drawer is scrolled down does not exist which leads
    // to inconsistent bottom padding for the `drawerFooter`.
    const isUseBottomPadding = !drawerFooter;
    return (_jsxs(Root
    //
    , Object.assign({ "$useMaxHeight": useMaxHeight, "$height": height, "$isCenterItems": isCenterItems, className: className, "$isUseBottomPadding": isUseBottomPadding }, { children: [title && _jsx(RootTitle, { children: title }, void 0), drawerHeader, children, drawerFooter] }), void 0));
};
