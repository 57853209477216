import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { t } from "@/src/features/Localization";
import { NavMenuButton } from "@/src/features/Nav/NavLeftButtons/NavMenuButton";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.button `
	& {
		${TypographyV3.Header6};
		align-items: center;
		color: ${(p) => p.theme.invert};
		display: flex;
		margin: auto 12px;
	}
`;
const RootAnchor = styled.a `
	& {
		align-items: center;
		color: ${(p) => p.theme.invert};
		display: flex;
		margin: auto 12px;
		text-decoration: none;
	}
`;
/**
 * `getLeftItem` will either take onClick or linkTo, but never both.
 * The NavLeftItemProps type will ensure this is the case.
 */
function getLeftItem(leftItemType) {
    switch (leftItemType) {
        case "Back":
            return (_jsxs(_Fragment, { children: [_jsx(Symbol, { icon: "arrow_back_ios" }, void 0), _jsx("span", { children: t("header_back") }, void 0)] }, void 0));
        case "Cancel":
            return (_jsxs(_Fragment, { children: [_jsx(Symbol, { icon: "arrow_back_ios" }, void 0), _jsx("span", { children: t("header_cancel") }, void 0)] }, void 0));
        case "Menu":
            return _jsx(NavMenuButton, {}, void 0);
        case "Login":
            return (_jsx(_Fragment, { children: _jsx("span", { children: t("header_login") }, void 0) }, void 0));
        default:
            return (_jsxs(_Fragment, { children: [_jsx(Symbol, { icon: "arrow_back_ios" }, void 0), _jsx("span", { children: t("header_home") }, void 0)] }, void 0));
    }
}
export const NavLeftItem = ({ onClick, dataCy, leftItemType, linkTo, href }) => {
    const history = useHistory();
    function onClickHandler() {
        const route = linkTo ? linkTo : "/";
        history.push(route);
    }
    if (href) {
        return (_jsx(RootAnchor, Object.assign({ "data-cy": dataCy, href: href }, { children: getLeftItem(leftItemType) }), void 0));
    }
    return (_jsx(Root, Object.assign({ "data-cy": dataCy, onClick: onClick ? onClick : onClickHandler, type: "button" }, { children: getLeftItem(leftItemType) }), void 0));
};
