import { jsx as _jsx } from "react/jsx-runtime";
import { HoursOperation } from "@/src/common/components/hours-operation/HoursOperation";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const FulfillmentPharmacy = (p) => {
    const workflow = p.workflow;
    // TODO: reidenzon - Result should be same, no?!
    // const settings = workflow.patient.settings;
    const settings = workflow.group.settings;
    return (_jsx(Root, { children: _jsx(HoursOperation, { settings: settings }, void 0) }, void 0));
};
