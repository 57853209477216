import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getPopupPositionStyle } from "@/src/common/components/popup/PopupPosition";
import { consoleLog } from "@/src/common/utility/log/Log";
import { LayoutContext } from "@/src/modules/layout/context/LayoutProvider";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
const RootAnimation = keyframes `
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;
const Root = styled.div `
	& {
		position: fixed;

		bottom: 0;
		left: 0;
		right: 0;
		top: 0;

		z-index: 1;
	}
`;
const RootSizer = styled.div `
	& {
		box-sizing: border-box;
		padding: 4px;
		pointer-events: none;
		position: absolute;
		visibility: hidden;
	}
`;
const RootPosition = styled.div `
	&& {
		animation: ${RootAnimation} 200ms ease-out both;
		box-sizing: border-box;
		padding: 4px;
		position: absolute;
	}
`;
const RootContent = styled.div `
	& {
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
		background: #ffffff;
		border: 1px solid gray;
		//border-radius: 4px;
		//padding: 2px;
	}
`;
var PopupMode;
(function (PopupMode) {
    PopupMode["Hidden"] = "hidden";
    PopupMode["Sizing"] = "sizing";
    PopupMode["Showing"] = "showing";
})(PopupMode || (PopupMode = {}));
var StateAction;
(function (StateAction) {
    StateAction["ShowSizer"] = "show-sizer";
    StateAction["MeasureSizer"] = "measure-sizer";
    StateAction["Show"] = "show";
})(StateAction || (StateAction = {}));
const stateReducer = (state, action) => {
    consoleLog("Popup.stateReducer", action.type, { state: state, action: action });
    switch (action.type) {
        case StateAction.ShowSizer: {
            return {
                mode: PopupMode.Sizing,
                style: {},
            };
        }
        case StateAction.MeasureSizer: {
            return {
                mode: PopupMode.Sizing,
                style: getPopupPositionStyle(action.target, action.sizer),
            };
        }
        case StateAction.Show: {
            return Object.assign(Object.assign({}, state), { mode: PopupMode.Showing });
        }
    }
};
export const Popup = (p) => {
    const lc = useContext(LayoutContext); // resize
    const [state, stateDispatch] = useReducer(stateReducer, {
        mode: PopupMode.Hidden,
        style: {},
    });
    const target = p.p1.target;
    const refSizer = useRef(null);
    const handleClose = () => {
        p.onClose();
    };
    const content = p.p1.content({
        onClose: handleClose,
    });
    useEffect(() => {
        stateDispatch({ type: StateAction.ShowSizer });
    }, [lc.Dimensions]);
    useEffect(() => {
        if (state.mode == PopupMode.Sizing) {
            stateDispatch({ type: StateAction.MeasureSizer, target: target, sizer: refSizer.current });
            stateDispatch({ type: StateAction.Show });
        }
    }, [state.mode]);
    return (_jsx(_Fragment, { children: createPortal(_jsxs(Root, Object.assign({ onClick: p.onClose }, { children: [(state.mode == PopupMode.Sizing || state.mode == PopupMode.Showing) && (_jsx(RootSizer, Object.assign({ ref: refSizer }, { children: _jsx(RootContent, { children: content }, void 0) }), void 0)), state.mode == PopupMode.Showing && (_jsx(RootPosition, Object.assign({ style: state.style }, { children: _jsx(RootContent, { children: content }, void 0) }), void 0))] }), void 0), document.body) }, void 0));
};
export const usePopup = () => {
    const [popupList, setPopupList] = useState([]);
    const handleClose = (guid) => {
        setPopupList((popupList) => popupList.filter((p1) => p1.guid != guid));
    };
    return {
        AddPopup: (p1) => {
            setPopupList((popupList) => [p1, ...popupList]);
        },
        Outlet: (_jsx(_Fragment, { children: popupList.map((p1) => {
                return _jsx(Popup, { p1: p1, onClose: () => handleClose(p1.guid) }, p1.guid);
            }) }, void 0)),
    };
};
