var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { getPriceInCentsAsString } from "@/src/common/utility/price/Price";
import { Form } from "@/src/features/common";
import { AcceptedCreditCards } from "@/src/features/common/AcceptedCreditCards";
import { Footer } from "@/src/features/common/Footer";
import { FormikRadioButtons } from "@/src/features/common/FormInputs/FormikRadioButtons";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { LoaderSpinnerModal } from "@/src/features/common/LoaderSpinnerModal";
import { FormikNotificationSetting } from "@/src/features/common/NotificationSetting";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { PaymentMethodCard } from "@/src/features/Payment/PaymentMethodCard";
import { SelectedPaymentMethodType } from "@/src/features/Payment/types";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { workflowPaymentTotal, } from "@/src/modules/workflow/type/WorkflowPayment";
import { useUserPaymentMethodCreateMutation } from "@/src/redux/apiServices/suiteApi";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { FormikProvider, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import * as Yup from "yup";
const NotificationSettingContainer = styled.div `
	margin-top: -20px;
`;
const PaymentMethodsContentContainer = styled.div `
	width: 330px;
	opacity: ${(props) => (props.isLoadingPage ? 0.5 : 1)};
`;
const ExistingPaymentContentContainer = styled.div ``;
const ProfileWalletCardDrawerContentContainer = styled.div `
	margin-top: 20px;
	margin-bottom: 20px;
	opacity: ${(props) => (props.isLoadingForm ? 0.5 : 1)};
	width: 330px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
const InputStyle = css `
	background-color: ${(p) => p.theme.inputBackgroundEmpty};
	border-radius: 8px;
	padding: 0px 12px 0px 12px;
`;
const CardNumberContainer = styled.div `
	${InputStyle}
`;
const CardDetailsContainer = styled.div `
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
`;
const CardExpiryElementContainer = styled.div `
	width: 147px;
	${InputStyle}
`;
const CardCvcElementContainer = styled.div `
	width: 147px;
	${InputStyle}
`;
const CardPostalCodeContainer = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 24px;
`;
const LoaderSpinnerContainer = styled.div `
	z-index: 10;
	position: absolute;
	margin-top: calc(35vh - 20px);
	margin-left: 138px;
`;
const AcceptedCreditCardsContainer = styled.div `
	display: flex;
	margin-bottom: 12px;
`;
export const PaymentMethodsForm = (p) => {
    var _a;
    const bc = useContext(BrandContext);
    let user = p.user;
    let refetchCurrentUser = p.refetchCurrentUser;
    let userPaymentMethodRefetch = p.userPaymentMethodRefetch;
    let hasPaymentMethods = p.hasPaymentMethods;
    let paymentMethods = p.paymentMethods;
    let workflowPaymentItems = p.workflowPaymentItems;
    let workflowPaymentIntent = p.workflowPaymentIntent;
    /* --------------------------------- States --------------------------------- */
    const [paymentError, setPaymentError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    /* ---------------------------------- Hooks --------------------------------- */
    const stripe = useStripe();
    const elements = useElements();
    const { snackbar } = useCustomSnackbar();
    const { segmentTrackEvent } = useSegmentAnalytics();
    /* -------------------------------- API Hooks ------------------------------- */
    const [createUserPaymentMethod] = useUserPaymentMethodCreateMutation();
    /* ------------------------------ useEffect ----------------------------- */
    useEffect(() => {
        if (paymentError) {
            snackbar.error(t("payment_error_failedToProcessPayment"));
            setPaymentError(false);
        }
    }, [paymentError, setPaymentError]);
    useEffect(() => {
        const formLoadingTimer = setTimeout(() => setIsPageLoading(false), 2000);
        return () => {
            clearTimeout(formLoadingTimer);
        };
    }, []);
    /* -------------------------------- Variables ------------------------------- */
    const StripeFieldBaseStyle = {
        base: {
            color: bc.Colors.inputPrimary,
            iconColor: bc.Colors.invert,
            lineHeight: "48px",
            height: "48px",
            fontSize: "15px",
            backgroundColor: bc.Colors.inputBackgroundEmpty,
            "::placeholder": {
                color: bc.Colors.inputPrimary,
            },
        },
    };
    const CardNumberElementOptions = {
        showIcon: false,
        style: StripeFieldBaseStyle,
        placeholder: "Card number",
    };
    const CardExpiryElementOptions = {
        style: StripeFieldBaseStyle,
        placeholder: "MM / YY",
    };
    const CardCvcElementOptions = {
        style: StripeFieldBaseStyle,
        placeholder: "CVV",
    };
    const workflowPaymentIntentClientSecret = workflowPaymentIntent === null || workflowPaymentIntent === void 0 ? void 0 : workflowPaymentIntent.clientSecret;
    const total = getPriceInCentsAsString(workflowPaymentTotal(p.workflowPayment), true);
    const paymentMethodId = (_a = paymentMethods === null || paymentMethods === void 0 ? void 0 : paymentMethods[0]) === null || _a === void 0 ? void 0 : _a.id;
    /* ------------------------------ Formik Setup ----------------------------- */
    const WorkflowPaymentMethodValidationSchema = Yup.object().shape({
        paymentPostalCode: Yup.string().when("selectedPaymentMethod", {
            is: (paymentMethodSelected) => paymentMethodSelected === SelectedPaymentMethodType.UseNew,
            then: () => Yup.string().required("Please enter a valid postal code"),
        }),
    });
    const formikInitialValues = {
        paymentPostalCode: "",
        savePaymentSetting: false,
        selectedPaymentMethod: hasPaymentMethods ? SelectedPaymentMethodType.UseExisitng : SelectedPaymentMethodType.UseNew,
    };
    const handleSubmit = ({ paymentPostalCode, savePaymentSetting, selectedPaymentMethod, }) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        if (!user || elements === null || stripe === null || !workflowPaymentIntent || !workflowPaymentIntentClientSecret) {
            return;
        }
        const cardElement = elements.getElement(CardNumberElement);
        // Create payment method first if checkbox selected and the radio
        // button selected was "new card"
        // Then make payment
        // Then save payment to backend
        let newPaymentMethodResult;
        if (selectedPaymentMethod === SelectedPaymentMethodType.UseNew && savePaymentSetting && cardElement) {
            const { error, paymentMethod } = yield stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
            if (error) {
                setIsLoading(false);
                setPaymentError(true);
            }
            if (paymentMethod) {
                newPaymentMethodResult = paymentMethod;
            }
        }
        // Here we want to pay the intent with either our existing payment method
        // or a new payment method.
        let paymentIntentResult;
        if (selectedPaymentMethod === SelectedPaymentMethodType.UseExisitng) {
            paymentIntentResult = yield stripe.confirmCardPayment(workflowPaymentIntentClientSecret, {
                // eslint-disable-next-line camelcase
                payment_method: paymentMethodId,
            });
        }
        else if (selectedPaymentMethod === SelectedPaymentMethodType.UseNew && cardElement) {
            paymentIntentResult = yield stripe.confirmCardPayment(workflowPaymentIntentClientSecret, {
                // eslint-disable-next-line camelcase
                payment_method: {
                    card: cardElement,
                    // eslint-disable-next-line camelcase
                    billing_details: {
                        address: {
                            // eslint-disable-next-line camelcase
                            postal_code: paymentPostalCode,
                        },
                    },
                },
            });
        }
        else {
            return;
        }
        if (paymentIntentResult.error) {
            setIsLoading(false);
            setPaymentError(true);
        }
        if (paymentIntentResult.paymentIntent) {
            setIsLoading(false);
            snackbar.success(t("payment_message_processedPayment"));
            segmentTrackEvent("Order Completed", {
                guid: user === null || user === void 0 ? void 0 : user.guid,
                // eslint-disable-next-line camelcase
                order_id: paymentIntentResult.paymentIntent.id,
                total: paymentIntentResult.paymentIntent.amount,
            });
            p.handleNextStepPaymentScreen();
        }
        // payment method only saved if there is a newPaymentMethodResult
        if (newPaymentMethodResult && !paymentIntentResult.error) {
            createUserPaymentMethod({
                userFor: user.guid,
                workflowFor: p.workflowGuid,
                paymentMethodId: newPaymentMethodResult.id,
            });
        }
    });
    const formikId = generateGuid();
    const formik = useFormik({
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: WorkflowPaymentMethodValidationSchema,
        onSubmit: handleSubmit,
    });
    /* ------------------------------ Render Component ----------------------------- */
    const PaymentMethodCardComponent = (_jsx(PaymentMethodCard, { paymentMethods: paymentMethods, user: user, refetchCurrentUser: refetchCurrentUser, userPaymentMethodRefetch: userPaymentMethodRefetch }, void 0));
    const PaymentMethodStripeInput = (_jsxs(ProfileWalletCardDrawerContentContainer, { children: [_jsx(AcceptedCreditCardsContainer, { children: _jsx(AcceptedCreditCards, {}, void 0) }, void 0), _jsx(CardNumberContainer, { children: _jsx(CardNumberElement, { options: CardNumberElementOptions }, void 0) }, void 0), _jsxs(CardDetailsContainer, { children: [_jsx(CardExpiryElementContainer, { children: _jsx(CardExpiryElement, { options: CardExpiryElementOptions }, void 0) }, void 0), _jsx(CardCvcElementContainer, { children: _jsx(CardCvcElement, { options: CardCvcElementOptions }, void 0) }, void 0)] }, void 0), _jsx(CardPostalCodeContainer, { children: _jsx(FormikTextField, { formikId: "paymentPostalCode", label: "Postal Code", fullWidth: true, type: FormikTextFieldTypes.PostalCode }, void 0) }, void 0)] }, void 0));
    const childValues = [
        {
            label: t("payment_subheader_useExisting"),
            value: "useExisting",
            component: PaymentMethodCardComponent,
        },
        {
            label: t("payment_subheader_useNew"),
            value: "useNew",
            component: PaymentMethodStripeInput,
        },
    ];
    return (_jsxs(FormikProvider, Object.assign({ value: formik }, { children: [_jsx(PageOverflowContainer, { children: _jsxs(PageWrapper, Object.assign({ isLargeVerticalPadding: true }, { children: [isPageLoading && (_jsx(LoaderSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0)), _jsx(LoaderSpinnerModal, { isOpen: isLoading, title: "Payment processing...." }, void 0), _jsx(LayoutContentHeader, { title: hasPaymentMethods ? t("payment_header_paymentMethodAvailable") : t("payment_header_paymentMethodUnavailable") }, void 0), _jsx(PaymentMethodsContentContainer, Object.assign({ isLoadingPage: isPageLoading }, { children: _jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: formikId }, { children: [hasPaymentMethods && paymentMethods ? (_jsx(ExistingPaymentContentContainer, { children: _jsx(FormikRadioButtons, { formikId: "selectedPaymentMethod", childValues: childValues, label: "selectPaymentMethodGroup", name: "selectPaymentMethodRadio" }, void 0) }, void 0)) : (PaymentMethodStripeInput), _jsx(NotificationSettingContainer, { children: _jsx(FormikNotificationSetting, { formikId: "savePaymentSetting", description: t("payment_replacePaymentMethod"), isFlex: true }, void 0) }, void 0)] }), void 0) }), void 0)] }), void 0) }, void 0), _jsx(Footer, { children: _jsx(ButtonStandard
                //
                , { 
                    //
                    style: ButtonStyle.SolidPrimary, content: t("payment_button_pay", { totalPrice: total }), form: formikId, isForward: true, isSubmit: true, isWide: true }, void 0) }, void 0)] }), void 0));
};
