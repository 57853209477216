import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getButtonStyleInfo } from "@/src/common/components/button/ButtonStyle";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
export const ButtonNavigateRoot = styled.a `
	& {
		align-items: center;
		box-sizing: border-box;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 1ch;
	}

	${(p) => p.$info.Css};
`;
export const ButtonNavigate = (p) => {
    const history = useHistory();
    const styleInfo = getButtonStyleInfo(p.style);
    const handleClick = (e) => {
        const isNewTab = e.ctrlKey || p.isNewTab;
        if (!isNewTab) {
            e.preventDefault();
            history.push(p.pathTo);
        }
    };
    return (_jsxs(ButtonNavigateRoot
    //
    , Object.assign({ "$info": styleInfo, href: p.pathTo, onClick: handleClick, target: p.isNewTab ? "_blank" : undefined }, { children: [p.contentLeft, p.content && _jsx("span", { children: p.content }, void 0), p.children] }), void 0));
};
