import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Button};
		align-items: center;
		background: ${(p) => p.theme.inputBackgroundEmpty};
		border: 1px solid ${(p) => p.theme.divider};
		border-top: none;
		//border-bottom-left-radius: 4px;
		//border-bottom-right-radius: 4px;
		cursor: pointer;
		display: flex;
		flex: 0;
		flex-direction: row;
		justify-content: space-between;
		gap: 4px;
		padding: 4px 8px;
		//margin-bottom: 2px;
	}

	&:hover {
		background: ${(p) => p.theme.buttonPrimaryHover};
		color: ${(p) => p.theme.invert};
	}
`;
const RootPlaceholder = styled.div `
	& {
		visibility: hidden;
	}
`;
const RootTitle = styled.div `
	& {
	}
`;
const RootIcon = styled.div `
	& {
		color: ${(p) => p.theme.icon};
	}

	${Root}:hover & {
		color: inherit;
	}
`;
export const ChatterHistoryHeader = (p) => {
    var _a;
    const isExpanded = (_a = p.isExpanded) !== null && _a !== void 0 ? _a : false;
    return (_jsxs(Root, Object.assign({ className: "ChatterHistoryHeader", onClick: p.onClick }, { children: [_jsx(RootPlaceholder, { children: _jsx(Symbol, { icon: "expand_more" }, void 0) }, void 0), _jsx(RootTitle, { children: "Chat History" }, void 0), _jsxs(RootIcon, { children: [!isExpanded && _jsx(Symbol, { icon: "expand_more" }, void 0), isExpanded && _jsx(Symbol, { icon: "expand_less" }, void 0)] }, void 0)] }), void 0));
};
