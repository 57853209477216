import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import mednowAssistance from "@/src/public/app/images/png/mednow_assistance.png";
import { routes } from "@/src/routes";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	margin-top: 38px;
`;
const RootText = styled.div `
	${TypographyV3.Body};
	margin-top: 10px;
	margin-bottom: 14px;
	padding: 0 32px;
	text-align: center;
`;
const RootImage = styled.img `
	aspect-ratio: 479/344;
	height: 96px;
`;
export const AssistanceLink = (p) => {
    var _a;
    const history = useHistory();
    function handleChatWithUs() {
        history.push(routes.Chat.toPath());
    }
    return (_jsxs(Root, { children: [_jsx(RootImage, { src: mednowAssistance }, void 0), _jsx(RootText, { children: (_a = p.title) !== null && _a !== void 0 ? _a : "Need assistance?" }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "Chat with Us", onClick: handleChatWithUs }, void 0)] }, void 0));
};
