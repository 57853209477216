import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Form } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { useResetUserPasswordRequestMutation } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
const ForgotPasswordContainer = styled.div `
	width: 500px;
	max-width: 100%;
	text-align: center;
`;
const TextfieldContainer = styled.div `
	margin-bottom: 40px;
`;
export const ForgotPassword = () => {
    const { snackbar } = useCustomSnackbar();
    const history = useHistory();
    const [resetUserPasswordRequest, { error, isSuccess: isSuccessResetUserPasswordRequest, isLoading: isLoadingResetUserPasswordRequest, data: resetUserPasswordResponseData },] = useResetUserPasswordRequestMutation();
    const [submittedEmail, setSubmittedEmail] = useState("");
    const LoginValidationSchema = Yup.object().shape({
        email: Yup.string().email(t("common_emailInput_error_invalidFormat")).required(t("common_emailInput_error_invalidFormat")),
    });
    const formikInitialValues = {
        email: "",
    };
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: LoginValidationSchema,
        onSubmit: ({ email }) => {
            if (!isLoadingResetUserPasswordRequest) {
                setSubmittedEmail(email);
                resetUserPasswordRequest({ email });
            }
        },
    };
    useEffect(() => {
        var _a;
        if (isSuccessResetUserPasswordRequest) {
            snackbar.handleApiMessages((_a = resetUserPasswordResponseData === null || resetUserPasswordResponseData === void 0 ? void 0 : resetUserPasswordResponseData.data) === null || _a === void 0 ? void 0 : _a.messages);
            history.push(routes.ForgotPasswordSuccess.toPath({ email: submittedEmail }));
            setSubmittedEmail("");
        }
    }, [isSuccessResetUserPasswordRequest]);
    useEffect(() => {
        var _a, _b;
        if (error) {
            if ((_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.messages) {
                snackbar.handleApiMessages((_b = error === null || error === void 0 ? void 0 : error.data) === null || _b === void 0 ? void 0 : _b.messages);
            }
            else {
                snackbar.error("There was an error processing your request.");
            }
        }
    }, [error]);
    return (_jsx(ForgotPasswordContainer, { children: _jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit }, { children: [_jsx(TextfieldContainer, { children: _jsx(FormikTextField
                        //
                        , { 
                            //
                            isSubmitEnter: true, fullWidth: true, formikId: "email", label: "Email Address", "data-cy": "forgot-password-email-input" }, void 0) }, void 0), _jsx(ButtonStandard
                    //
                    , { 
                        //
                        style: ButtonStyle.SolidPrimary, content: "Email Instructions", iconRight: "send", isSubmit: true, isWide: true }, void 0)] }), void 0)) }), void 0) }, void 0));
};
