import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonIconLegacy } from "@/src/common/components/button/ButtonIconLegacy";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.header `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
		text-align: center;
	}
`;
const RootToolbar = styled.div `
	& {
		display: flex;
		flex-direction: row;
		justify-content: end;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Header3};
	}
`;
const RootDescription = styled.div `
	& {
		${TypographyV3.Body};
		display: flex;
		flex-direction: column;
		gap: 1em;
	}
`;
export const ModalHeader = (p) => {
    const isToolbar = Boolean(p.onClose);
    return (_jsxs(Root, { children: [isToolbar && _jsx(RootToolbar, { children: p.onClose && _jsx(ButtonIconLegacy, { icon: _jsx(Icons.Clear, {}, void 0), onClick: p.onClose }, void 0) }, void 0), p.title && _jsx(RootTitle, { children: p.title }, void 0), p.description && _jsx(RootDescription, { children: p.description }, void 0)] }, void 0));
};
