import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { StreamContext } from "@/src/features/Chat/StreamProvider";
import { Icons } from "@/src/features/common";
import { WorkflowStatusChatActive, WorkflowStatusChatTriage } from "@/src/modules/workflow/type/chat-v1/Status";
import { routes } from "@/src/routes";
import { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
const NavChatButtonContainer = styled.button `
	display: flex;
	align-items: center;
	background: none;
	border: none;
	cursor: pointer;
	position: relative;
	width: 32px;
	height: 32px;
	margin-right: 16px;
`;
const InactiveIconContainer = styled.div ``;
const ActiveIconContainer = styled.div `
	& {
		color: ${(p) => p.theme.menuHighlight};
	}
`;
const UnreadMessageCountIconContainer = styled.div `
	display: block;
`;
const UnreadMessagesCountTextContainer = styled.div `
	${TypographyV3.Header6};
	align-items: center;
	color: ${(p) => p.theme.textBody};
	display: flex;
	justify-content: center;
	position: absolute;

	top: 0;
	left: 0;
	right: 0;
	height: 82%;
	//background: rgba(0, 0, 0, 0.2);

	z-index: 2;
`;
export const NavChatButton = ({ className }) => {
    var _a, _b;
    const history = useHistory();
    const sc = useContext(StreamContext);
    const channels = sc.channels;
    const currentChannel = channels && channels.length > 0 ? channels[0] : null;
    let currentChannelStatus;
    let unreadMessageCount = 0;
    if (currentChannel) {
        currentChannelStatus = (_a = currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.data) === null || _a === void 0 ? void 0 : _a["meta-status"];
        unreadMessageCount = ((_b = currentChannel === null || currentChannel === void 0 ? void 0 : currentChannel.state) === null || _b === void 0 ? void 0 : _b.unreadCount) || 0;
    }
    let ChatIcon;
    function onStartChatWorkflow() {
        history.push(routes.Chat.toPath());
    }
    if (unreadMessageCount > 0) {
        ChatIcon = (_jsxs(UnreadMessageCountIconContainer, { children: [_jsx(Icons.ChatPendingMessages, { size: 32 }, void 0), _jsx(UnreadMessagesCountTextContainer, { children: unreadMessageCount < 10 ? unreadMessageCount : "9+" }, void 0)] }, void 0));
    }
    else if (currentChannelStatus && [WorkflowStatusChatTriage, WorkflowStatusChatActive].includes(currentChannelStatus)) {
        ChatIcon = (_jsx(ActiveIconContainer, { children: _jsx(Icons.ChatActive, { size: 32 }, void 0) }, void 0));
    }
    else {
        ChatIcon = (_jsx(InactiveIconContainer, { children: _jsx(Icons.Chat, { size: 32 }, void 0) }, void 0));
    }
    return (_jsx(_Fragment, { children: _jsx(NavChatButtonContainer, Object.assign({ onClick: onStartChatWorkflow, className: className, type: "button", "data-cy": "chat-icon" }, { children: ChatIcon }), void 0) }, void 0));
};
