import { jsx as _jsx } from "react/jsx-runtime";
import CardMUI from "@mui/material/Card";
import styled from "styled-components";
import { Elevations } from "@/src/enums/elevations";
const CardStyled = styled(CardMUI) `
	&&.MuiCard-root {
		box-shadow: ${Elevations.Tight1};
		border-radius: 7px;
	}
`;
export const Card = ({ children }) => {
    return _jsx(CardStyled, { children: children }, void 0);
};
export { default as CardActions } from "@mui/material/CardActions";
export { default as CardContent } from "@mui/material/CardContent";
