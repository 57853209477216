import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { UberAvailabilityGeneral } from "@/src/modules/devliery-method/type/uber/components/UberAvailabilityGeneral";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const DeliveryMethodTimeline = (p) => {
    const w = p.workflow;
    const wd = w.details;
    const m = p.method;
    const md = m.details;
    const isScheduled = Boolean(wd.deliverySchedule);
    return _jsx(_Fragment, { children: !isScheduled && _jsx(UberAvailabilityGeneral, { workflow: w }, void 0) }, void 0);
};
