import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { useContext } from "react";
import styled from "styled-components";
/** @deprecated TODO: reidenzon - Use UStatusInfo instead~ */
export var ListCTAItemLabelStyle;
(function (ListCTAItemLabelStyle) {
    ListCTAItemLabelStyle["Info"] = "info";
    ListCTAItemLabelStyle["Success"] = "success";
    ListCTAItemLabelStyle["Warning"] = "warning";
    ListCTAItemLabelStyle["Error"] = "error";
})(ListCTAItemLabelStyle || (ListCTAItemLabelStyle = {}));
const infoMap = {
    [ListCTAItemLabelStyle.Info]: {
        color: (bc) => bc.Colors.statusInfo,
        background: (bc) => bc.Colors.statusInfoBackground,
    },
    [ListCTAItemLabelStyle.Success]: {
        color: (bc) => bc.Colors.statusSuccess,
        background: (bc) => bc.Colors.statusSuccessBackground,
    },
    [ListCTAItemLabelStyle.Warning]: {
        color: (bc) => bc.Colors.statusWarning,
        background: (bc) => bc.Colors.statusWarningBackground,
    },
    [ListCTAItemLabelStyle.Error]: {
        color: (bc) => bc.Colors.statusError,
        background: (bc) => bc.Colors.statusErrorBackground,
    },
};
export const getListCTAItemLabelStyleInfo = (s) => {
    return infoMap[s];
};
export const userListCTAItemLabelStyleInfo = (s) => {
    const bc = useContext(BrandContext);
    const info = getListCTAItemLabelStyleInfo(s);
    return {
        color: info.color(bc),
        background: info.background(bc),
    };
};
const Root = styled.div `
	align-items: center;
	background: ${(p) => p.$info.background};
	border-radius: 16px;
	color: ${(p) => p.$info.color};
	display: inline-flex;
	height: 22px;
	justify-content: center;
	padding: 0 8px;
`;
const RootValue = styled.div `
	${TypographyV3.Badge};
`;
export const ListCTAItemLabel = (p) => {
    var _a;
    const info = userListCTAItemLabelStyleInfo((_a = p.style) !== null && _a !== void 0 ? _a : ListCTAItemLabelStyle.Success);
    if (!p.isLoading && p.text == undefined) {
        return null;
    }
    if (p.isLoading) {
        return _jsx(LoaderSpinner, { size: 22 }, void 0);
    }
    return (_jsx(Root, Object.assign({ "$info": info }, { children: _jsx(RootValue, { children: p.text }, void 0) }), void 0));
};
