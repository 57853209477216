import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { translate } from "@/src/common/utility/translation/Translate";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { Nav } from "@/src/features/Nav/Nav";
import { useAuth } from "@/src/hooks/useAuth";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { RefillReminderList } from "@/src/modules/rx-reminder/layout/refill-reminders/RefillReminderList";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
import { useUserViewSingleCustomerRelationeeQuery } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export var LayoutRefillRemindersKey;
(function (LayoutRefillRemindersKey) {
    LayoutRefillRemindersKey["UserFor"] = "guid";
})(LayoutRefillRemindersKey || (LayoutRefillRemindersKey = {}));
export const LayoutRefillReminders = () => {
    var _a;
    const auth = useAuth();
    const { query } = useQuery();
    const user = auth.user;
    const patientGuid = (query === null || query === void 0 ? void 0 : query.get(LayoutRefillRemindersKey.UserFor)) || (user === null || user === void 0 ? void 0 : user.guid);
    const apiRelationee = useUserViewSingleCustomerRelationeeQuery({ userForGuid: patientGuid }, { skip: patientGuid == (user === null || user === void 0 ? void 0 : user.guid) });
    const patient = patientGuid == (user === null || user === void 0 ? void 0 : user.guid) ? user : (_a = apiRelationee.data) === null || _a === void 0 ? void 0 : _a.data.user;
    const name = getUserDetailsNameFull(patient === null || patient === void 0 ? void 0 : patient.details);
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { 
                //
                dataLeft: { leftItemType: "Back", linkTo: routes.Home.toPath() }, dataRight: { rightItemType: "StartChat" } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader
                            //
                            , { 
                                //
                                title: "Refill Reminders", description: translate("Manage refill reminders for {name}.", {
                                    name: name,
                                }) }, void 0), patientGuid && _jsx(RefillReminderList, { patientGuid: patientGuid }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
