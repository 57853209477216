import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useContext } from "react";
import { ModalContext } from "@/src/common/components/modal/ModalProvider";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ModalFooterRowCenter } from "@/src/common/components/modal/ModalFooterRowCenter";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
export const WorkflowModalCancel = (p) => {
    return (_jsxs(Root, { children: [_jsx(ModalHeader
            //
            , { 
                //
                title: "Do you really want to cancel?", description: "That's fine. We just want to make sure." }, void 0), _jsxs(ModalFooterRowCenter, { children: [_jsx(ButtonStandard, { content: "No, continue", onClick: p.onNo, style: ButtonStyle.SolidGhost }, void 0), _jsx(ButtonStandard, { content: "Yes, please cancel", onClick: p.onYes, style: ButtonStyle.SolidPrimary }, void 0)] }, void 0)] }, void 0));
};
export const useWorkflowModalCancel = () => {
    const mc = useContext(ModalContext);
    return {
        doModal: (p) => {
            mc.AddModal({
                guid: generateGuid(),
                content: (m) => {
                    return (_jsx(WorkflowModalCancel, { onNo: () => {
                            var _a;
                            (_a = p.onNo) === null || _a === void 0 ? void 0 : _a.call(p);
                            m.onClose();
                        }, onYes: () => {
                            var _a;
                            (_a = p.onYes) === null || _a === void 0 ? void 0 : _a.call(p);
                            m.onClose();
                        } }, void 0));
                },
            });
        },
    };
};
