import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { css } from "styled-components";
/** @deprecated TODO: reidenzon - Move to {@link StyleCommon} perhaps?! */
export const FieldStyle = {
    Rest: css `
		& {
			${TypographyV3.FieldInput};
			background: transparent;
			border: 1px solid ${(p) => p.theme.inputPrimary};
			box-sizing: border-box;
			color: ${(p) => p.theme.inputPrimary};
			//font: inherit;
			outline: none;
		}

		&[type="email"],
		&[type="number"],
		&[type="password"],
		&[type="text"],
		&[type="time"] {
			height: 1.4em;
		}
	`,
    Disabled: css `
		& {
			border-color: transparent;
			padding: 0;
		}
	`,
    Active: css `
		& {
			border: 1px solid ${(p) => p.theme.inputPrimary};
			color: ${(p) => p.theme.inputPrimary};
			outline: none;
		}
	`,
};
