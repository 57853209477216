import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonIcon } from "@/src/common/components/button/ButtonIcon";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Field } from "@/src/common/components/field/Field";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Form, Icons } from "@/src/features/common";
import { FormikDatePicker } from "@/src/features/common/FormikDatePicker";
import { FormikGooglePlacesAPIAutocomplete } from "@/src/features/common/FormikGooglePlacesAPIAutocomplete";
import { FormikRelationship } from "@/src/features/common/FormikRelationship";
import { FormikTextFieldMaskedPhoneNumber } from "@/src/features/common/FormikTextFieldMaskedPhoneNumber";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { FormikImageUploader } from "@/src/features/common/ImageUploader/FormikImageUploader";
import { t } from "@/src/features/Localization";
import { FormikTextarea } from "@/src/features/utils/Formik/FormikTextarea";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikEditFieldType } from "@/src/features/utils/Formik/formikTypes";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { formatNumberToPhoneNumber } from "@/src/utils/HelperUtils";
import { resolveValue } from "@/src/utils/PathValue";
import { Formik, useFormikContext } from "formik";
import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
const DEFAULT_BLANK_FIELD_VALUE = "--";
const FormContainer = styled(Form) `
	position: relative;
`;
const RootToolbar = styled.div `
	${TypographyV3.Button};
	color: ${(p) => p.theme.buttonPrimary};
	position: absolute;
	top: 0;
	right: 0;
`;
const RootFooter = styled.div `
	${TypographyV3.Button};
	display: flex;
	align-items: center;
	justify-content: right;
	flex-direction: row;
	margin: auto 0px;
	gap: 11px;
`;
const LabelText = styled.div `
	& {
		${TypographyV3.FieldTitle}
		width: 265px;
		margin-bottom: 8px;
	}
`;
const HelperText = styled.div `
	& {
		${TypographyV3.FieldDescription}
		width: 265px;
		margin-bottom: 16px;
	}
`;
const ValueText = styled.div `
	${TypographyV3.FieldInput};
	width: 265px;
	margin-bottom: 8px;
	white-space: pre-wrap;
`;
const ValueTextLocked = styled.div `
	${TypographyV3.FieldInput};
	width: 265px;
`;
const FormikCheckboxContainer = styled.div `
	height: 24px;
	display: flex;
`;
const FormikCheckboxText = styled.div `
	${TypographyV3.FieldInput};
	margin-left: 8px;
	padding: 0.2em 0;
`;
const FormikDatePickerContainer = styled.div `
	margin-bottom: 12px;
`;
const EditFieldAndCancelContainer = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const EditFieldContainerWrapper = styled.div `
	display: block;
`;
/**
 * This hook helps to revert within formik value, the
 * value of a checkbox upon cancel.
 * i.e. if user edits a field which has an additional checkbox
 * and before editing it was checked, then they go to edit mode
 * and uncheck it, and then press cancel, then within formik's
 * values the checkbox's value should revert to the value before the
 * edit (checked).
 */
function useRevertCheckboxOnCancel(p) {
    const { checkBoxFormikId, isEditing } = p;
    /* --------------------------- Hooks and variables -------------------------- */
    const { values, setFieldValue } = useFormikContext() || {};
    const checkboxValue = resolveValue(values, checkBoxFormikId);
    const [checkboxValueBeforeEdit, setCheckboxValueBeforeEdit] = useState(undefined);
    const [hasInitiatedEditMode, setHasInitiatedEditMode] = useState(false);
    /* ---------------------------- Helper Functions ---------------------------- */
    // We need to revert the checkboxes value if we cancel.
    const handleCancel = useCallback(() => {
        // Revert the value of the checkbox
        setFieldValue(checkBoxFormikId, checkboxValueBeforeEdit);
    }, [checkBoxFormikId, checkboxValueBeforeEdit]);
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        // The first time this component goes to edit mode, store the initial checkbox value
        if (isEditing && !hasInitiatedEditMode) {
            setCheckboxValueBeforeEdit(checkboxValue);
            setHasInitiatedEditMode(true);
        }
        else if (!isEditing && hasInitiatedEditMode) {
            setHasInitiatedEditMode(false);
        }
    }, [isEditing, checkboxValueBeforeEdit, checkboxValue, hasInitiatedEditMode]);
    /* ---------------------------- Returned function --------------------------- */
    return handleCancel;
}
function RenderedFormikTextField(p) {
    const { value, label, formikId, isEditing, setIsEditingFalse, isLocked } = p;
    if (isLocked) {
        return (_jsxs("div", { children: [_jsx(LabelText, { children: label }, void 0), _jsx(ValueTextLocked, { children: value ? value : DEFAULT_BLANK_FIELD_VALUE }, void 0)] }, void 0));
    }
    else if (isEditing) {
        return (_jsxs(EditFieldContainerWrapper, { children: [_jsx(LabelText, { children: label }, void 0), _jsxs(EditFieldAndCancelContainer, { children: [_jsx(FormikTextField, { formikId: formikId, isSubmitEnter: true }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: t("profile_button_cancel"), onClick: setIsEditingFalse }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("common_button_save"), isSubmit: true }, void 0)] }, void 0)] }, void 0)] }, void 0));
    }
    return (_jsxs("div", { children: [_jsx(LabelText, { children: label }, void 0), _jsx(ValueText, { children: value ? value : DEFAULT_BLANK_FIELD_VALUE }, void 0)] }, void 0));
}
function RenderFormikTextArea(p) {
    const { formikId, label, labelEdit, nullValueLabel, placeholderText, helperText, helperTextEdit, checkBoxText, checkBoxFormikId, textAreaData, value, isEditing, setIsEditingFalse, } = p;
    const bc = useContext(BrandContext);
    const { values } = useFormikContext() || {};
    const checkboxValue = checkBoxFormikId ? resolveValue(values, checkBoxFormikId) : null;
    const revertCheckboxOnCancel = checkBoxFormikId ? useRevertCheckboxOnCancel({ checkBoxFormikId, isEditing }) : () => null;
    // We need to revert the checkboxes value if we cancel.
    function handleCancel() {
        // Revert the value of the checkbox
        revertCheckboxOnCancel();
        setIsEditingFalse();
    }
    if (isEditing) {
        return (_jsxs(Field
        //
        , Object.assign({ 
            //
            title: labelEdit !== null && labelEdit !== void 0 ? labelEdit : label, description: helperTextEdit !== null && helperTextEdit !== void 0 ? helperTextEdit : helperText }, { children: [!checkboxValue && _jsx(FormikTextarea, { formikId: formikId, placeholder: placeholderText }, void 0), checkBoxFormikId && checkBoxText && _jsx(FormikCheckbox, { formikId: checkBoxFormikId, label: checkBoxText }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: t("profile_button_cancel"), onClick: handleCancel }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("common_button_save"), isSubmit: true }, void 0)] }, void 0)] }), void 0));
    }
    const renderedFieldValue = () => {
        if (!value && nullValueLabel) {
            return _jsx(ValueText, { children: nullValueLabel }, void 0);
        }
        else if (value && value !== "") {
            return _jsx(ValueText, { children: value }, void 0);
        }
        return !checkboxValue && _jsx(HelperText, { children: helperText }, void 0);
    };
    return (_jsxs(Field, Object.assign({ title: label }, { children: [renderedFieldValue(), textAreaData && !textAreaData.isHave && (_jsx(_Fragment, { children: _jsxs(FormikCheckboxContainer, { children: [_jsx(Icons.Check, { color: bc.Colors.icon }, void 0), _jsx(FormikCheckboxText, { children: checkBoxText }, void 0)] }, void 0) }, void 0))] }), void 0));
}
const ImageContentContainer = styled.div `
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const ImageItem = styled.div `
	display: flex;
	position: relative;
	border-radius: 4px;
	border: 1px solid ${(p) => p.theme.divider};
`;
const Image = styled.img `
	border-radius: 4px;
`;
function RenderedFormikImageUpload(p) {
    var _a;
    const { label, labelEdit, helperText, helperTextEdit, checkBoxText, checkBoxFormikId, imageUploadData, formikId, isEditing, setIsEditingFalse, imageType } = p;
    const bc = useContext(BrandContext);
    const { values } = useFormikContext() || {};
    const imageValue = resolveValue(values, formikId);
    const hasImages = imageValue && (imageValue === null || imageValue === void 0 ? void 0 : imageValue.length) > 0;
    const checkboxValue = checkBoxFormikId ? resolveValue(values, checkBoxFormikId) : undefined;
    const revertCheckboxOnCancel = checkBoxFormikId
        ? useRevertCheckboxOnCancel({
            checkBoxFormikId,
            isEditing,
        })
        : () => null;
    /* ---------------------------- Helper Functions ---------------------------- */
    // We need to revert the checkboxes value if we cancel.
    function handleCancel() {
        // Revert the value of the checkbox upon cancelling.
        revertCheckboxOnCancel();
        setIsEditingFalse();
    }
    if (isEditing) {
        return (_jsxs(Field, { children: [_jsx(FormikImageUploader, { formikId: formikId, imageType: imageType, title: labelEdit !== null && labelEdit !== void 0 ? labelEdit : label, description: helperTextEdit !== null && helperTextEdit !== void 0 ? helperTextEdit : helperText, isHideUpload: checkboxValue }, void 0), checkBoxFormikId && checkBoxText && _jsx(FormikCheckbox, { formikId: checkBoxFormikId, label: checkBoxText }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: t("profile_button_cancel"), onClick: handleCancel }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("common_button_save"), isSubmit: true }, void 0)] }, void 0)] }, void 0));
    }
    return (_jsxs("div", { children: [_jsx(LabelText, { children: label }, void 0), helperText && !checkboxValue && !hasImages && _jsx(HelperText, { children: helperText }, void 0), _jsx(ImageContentContainer, { children: (_a = imageUploadData === null || imageUploadData === void 0 ? void 0 : imageUploadData.files) === null || _a === void 0 ? void 0 : _a.map((file, index) => (_jsx(ImageItem, { children: _jsx(Image, { src: file.url, alt: "", width: "96", height: "96" }, void 0) }, index))) }, void 0), imageUploadData && !imageUploadData.isHave && checkBoxFormikId && (_jsxs(FormikCheckboxContainer, { children: [_jsx(Icons.Check, { color: bc.Colors.icon }, void 0), _jsx(FormikCheckboxText, { children: checkBoxText }, void 0)] }, void 0))] }, void 0));
}
function RenderedFormikTextMask(p) {
    const { formikId, label, value, isEditing, setIsEditingFalse } = p;
    if (isEditing) {
        return (_jsxs("div", { children: [_jsx(LabelText, { children: label }, void 0), _jsxs(EditFieldAndCancelContainer, { children: [_jsx(FormikTextFieldMaskedPhoneNumber, { formikId: formikId }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: t("profile_button_cancel"), onClick: setIsEditingFalse }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("common_button_save"), isSubmit: true }, void 0)] }, void 0)] }, void 0)] }, void 0));
    }
    return (_jsxs("div", { children: [_jsx(LabelText, { children: label }, void 0), _jsx(ValueText, { children: value ? formatNumberToPhoneNumber(value) : DEFAULT_BLANK_FIELD_VALUE }, void 0)] }, void 0));
}
function RenderedFormikDatePicker(p) {
    const { formikId, label, value, isEditing, setIsEditingFalse } = p;
    if (isEditing) {
        return (_jsxs("div", { children: [_jsx(LabelText, { children: label }, void 0), _jsxs(EditFieldAndCancelContainer, { children: [_jsx(FormikDatePickerContainer, { children: _jsx(FormikDatePicker, { formikId: formikId }, void 0) }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: t("profile_button_cancel"), onClick: setIsEditingFalse }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("common_button_save"), isSubmit: true }, void 0)] }, void 0)] }, void 0)] }, void 0));
    }
    return (_jsxs("div", { children: [_jsx(LabelText, { children: label }, void 0), _jsx(ValueText, { children: value ? value : DEFAULT_BLANK_FIELD_VALUE }, void 0)] }, void 0));
}
function RenderedFormikGooglePlacesAPIAutocomplete(p) {
    const { formikId, label, value, isEditing, setIsEditingFalse } = p;
    if (isEditing) {
        return (_jsxs("div", { children: [_jsx(LabelText, Object.assign({ "data-cy": `${formikId}-label` }, { children: label }), void 0), _jsxs(EditFieldAndCancelContainer, { children: [_jsx(FormikGooglePlacesAPIAutocomplete, { formikId: formikId }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: t("profile_button_cancel"), onClick: setIsEditingFalse }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("common_button_save"), isSubmit: true }, void 0)] }, void 0)] }, void 0)] }, void 0));
    }
    return (_jsxs("div", { children: [_jsx(LabelText, Object.assign({ "data-cy": `${formikId}-label` }, { children: label }), void 0), _jsx(ValueText, Object.assign({ "data-cy": `${formikId}-value` }, { children: value ? value : DEFAULT_BLANK_FIELD_VALUE }), void 0)] }, void 0));
}
function RenderedFormikRelationship(p) {
    const { formikId, label, valueView, isEditing, setIsEditingFalse } = p;
    if (isEditing) {
        // TODO: reidenzon - Make this a select!
        return (_jsxs("div", { children: [_jsx(LabelText, Object.assign({ "data-cy": `${formikId}-label` }, { children: label }), void 0), _jsxs(EditFieldAndCancelContainer, { children: [_jsx(FormikRelationship, { formikId: formikId }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, content: t("profile_button_cancel"), onClick: setIsEditingFalse }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: t("common_button_save"), isSubmit: true }, void 0)] }, void 0)] }, void 0)] }, void 0));
    }
    return (_jsxs("div", { children: [_jsx(LabelText, Object.assign({ "data-cy": `${formikId}-label` }, { children: label }), void 0), _jsx(ValueText, Object.assign({ "data-cy": `${formikId}-value` }, { children: valueView || DEFAULT_BLANK_FIELD_VALUE }), void 0)] }, void 0));
}
export function RenderedFormikField(p) {
    const { formikEditFieldType, label, labelEdit, nullValueLabel, placeholderText, helperText, helperTextEdit, checkBoxText, checkBoxFormikId, imageUploadData, textAreaData, value, valueView, formikId, isEditing, setIsEditingFalse, isLocked, imageType, } = p;
    let component;
    if (formikEditFieldType === FormikEditFieldType.TextInput) {
        component = (_jsx(RenderedFormikTextField, { formikId: formikId, label: label, labelEdit: labelEdit, value: value, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse, isLocked: isLocked }, void 0));
    }
    if (formikEditFieldType === FormikEditFieldType.TextArea) {
        component = (_jsx(RenderFormikTextArea, { formikId: formikId, label: label, labelEdit: labelEdit, nullValueLabel: nullValueLabel, placeholderText: placeholderText, helperText: helperText, helperTextEdit: helperTextEdit, checkBoxText: checkBoxText, checkBoxFormikId: checkBoxFormikId, textAreaData: textAreaData, value: value, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse }, void 0));
    }
    if (formikEditFieldType === FormikEditFieldType.ImageUpload && helperText && imageType) {
        component = (_jsx(RenderedFormikImageUpload, { formikId: formikId, label: label, labelEdit: labelEdit, helperText: helperText, helperTextEdit: helperTextEdit, checkBoxText: checkBoxText, checkBoxFormikId: checkBoxFormikId, imageUploadData: imageUploadData, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse, imageType: imageType }, void 0));
    }
    if (formikEditFieldType === FormikEditFieldType.TextMask) {
        component = _jsx(RenderedFormikTextMask, { formikId: formikId, label: label, value: value, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse }, void 0);
    }
    if (formikEditFieldType === FormikEditFieldType.DatePicker) {
        component = _jsx(RenderedFormikDatePicker, { formikId: formikId, label: label, value: value, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse }, void 0);
    }
    if (formikEditFieldType === FormikEditFieldType.GooglePlacesAPIAutocompleteProps) {
        component = (_jsx(RenderedFormikGooglePlacesAPIAutocomplete, { formikId: formikId, label: label, value: value, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse }, void 0));
    }
    if (formikEditFieldType === FormikEditFieldType.Relationship) {
        component = (_jsx(RenderedFormikRelationship, { formikId: formikId, label: label, valueView: valueView, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse }, void 0));
    }
    if (!component) {
        component = (_jsx(RenderedFormikTextField, { formikId: formikId, label: label, value: value, isEditing: isEditing, setIsEditingFalse: setIsEditingFalse, isLocked: isLocked }, void 0));
    }
    return component;
}
export const FormikGenericEditField = (p) => {
    const { editFieldType, formikId, value, valueView, label, labelEdit, nullValueLabel, placeholderText, helperText, helperTextEdit, imageUploadData, imageType, checkBoxText, checkBoxFormikId, checkBoxValue, textAreaData, options, validationSchema, handleSubmit, isLocked, } = p;
    const [isEditing, setIsEditing] = useState(false);
    function setIsEditingTrue() {
        setIsEditing(true);
    }
    function setIsEditingFalse() {
        setIsEditing(false);
    }
    const renderedComponent = (_jsx(RenderedFormikField, { formikEditFieldType: editFieldType, label: label, labelEdit: labelEdit, nullValueLabel: nullValueLabel, placeholderText: placeholderText, helperText: helperText, helperTextEdit: helperTextEdit, checkBoxText: checkBoxText, checkBoxFormikId: checkBoxFormikId, imageUploadData: imageUploadData, textAreaData: textAreaData, value: value, valueView: valueView, options: options, formikId: formikId, isEditing: isEditing, isLocked: isLocked, imageType: imageType, setIsEditingFalse: setIsEditingFalse }, void 0));
    const formikInitialValues = {
        [formikId]: value,
    };
    if (checkBoxFormikId) {
        let calculatedCheckBoxValue;
        if (checkBoxValue === true) {
            calculatedCheckBoxValue = false;
        }
        else if (checkBoxValue === false) {
            calculatedCheckBoxValue = true;
        }
        else {
            calculatedCheckBoxValue = undefined;
        }
        formikInitialValues[checkBoxFormikId] = calculatedCheckBoxValue;
    }
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSubmit: (formValues) => {
            handleSubmit(formValues);
            setIsEditing(false);
        },
    };
    return (_jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(FormContainer, Object.assign({ onSubmit: formik.handleSubmit, id: `${formikId}-submit` }, { children: [renderedComponent, !isEditing && (_jsxs(RootToolbar, { children: [!isLocked && _jsx(ButtonIcon, Object.assign({ onClick: setIsEditingTrue }, { children: _jsx(Symbol, { icon: "edit" }, void 0) }), void 0), isLocked && _jsx(ButtonIcon, Object.assign({ isDisabled: true }, { children: _jsx(Symbol, { icon: "lock" }, void 0) }), void 0)] }, void 0))] }), void 0)) }), void 0));
};
