/**
 * NOTE: this should be the same values that are used with Material UI breakpoints:
 * https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
 *
 * 	xs, extra-small: 0px
 * 	sm, small: 600px
 * 	md, medium: 900px
 * 	lg, large: 1200px
 * 	xl, extra-large: 1536px
 *
 * NOTE: Ensure when updating "Breakpoint" that "BreakpointPx" is also updated.
 */
export var Breakpoint;
(function (Breakpoint) {
    Breakpoint[Breakpoint["xs"] = 0] = "xs";
    Breakpoint[Breakpoint["sm"] = 600] = "sm";
    Breakpoint[Breakpoint["md"] = 900] = "md";
    // This the same value as "md" but we explicitly call this out as "Desktop" in case "md" changes in teh future.
    Breakpoint[Breakpoint["Desktop"] = 900] = "Desktop";
    Breakpoint[Breakpoint["lg"] = 1200] = "lg";
    Breakpoint[Breakpoint["DesktopLarge"] = 1440] = "DesktopLarge";
    Breakpoint[Breakpoint["xl"] = 1536] = "xl";
})(Breakpoint || (Breakpoint = {}));
/**
 * BreakpointForMaxWidth is useful when wanting to use with
 * "max-width", since the regular "Breakpoint" values are for
 * a mobile first approach (i.e. using "min-width").
 *
 * Otherwise, if "max-width" is used in a media query along with
 * min-width, then the media query will be off by 1 pixel for having
 * a consistent change.
 *
 * ` @media (max-width: ${BreakpointForMaxWidth}px) { ... }
 *
 */
export const BreakpointForMaxWidth = {
    xs: Breakpoint.xs,
    sm: Breakpoint.sm - 1,
    md: Breakpoint.md - 1,
    // This the same value as "md" but we explicitly call this out as "Desktop" in case "md" changes in teh future.
    Desktop: Breakpoint.Desktop - 1,
    lg: Breakpoint.lg - 1,
    DesktopLarge: Breakpoint.DesktopLarge - 1,
    xl: Breakpoint.xl - 1,
};
/**
 * The breakpoints with px at the end. E.g. BreakpointPx.sm = "600px"
 *
 * NOTE: Ensure when updating "Breakpoint" that "BreakpointPx" is also updated.
 */
export const BreakpointPx = {
    xs: `${Breakpoint.xs}px`,
    sm: `${Breakpoint.sm}px`,
    md: `${Breakpoint.md}px`,
    Desktop: `${Breakpoint.Desktop}px`,
    lg: `${Breakpoint.lg}px`,
    DesktopLarge: `${Breakpoint.DesktopLarge}px`,
    xl: `${Breakpoint.xl}px`,
};
// This the same value as "md" but we explicitly call this out as "Desktop" in case "md" changes in teh future.
/**
 * BreakpointForMaxWidth is useful when wanting to use with
 * "max-width", since the regular "Breakpoint" values are for
 * a mobile first approach (i.e. using "min-width").
 *
 * Otherwise, if "max-width" is used in a media query along with
 * min-width, then the media query will be off by 1 pixel for having
 * a consistent change.
 *
 * ` @media (max-width: ${BreakpointForMaxWidth}px) { ... }
 *
 */
export const BreakpointPxForMaxWidth = {
    xs: BreakpointPx.xs,
    sm: `${Breakpoint.sm - 1}px`,
    md: `${Breakpoint.md - 1}px`,
    // This the same value as "md" but we explicitly call this out as "Desktop" in case "md" changes in teh future.
    Desktop: `${Breakpoint.Desktop - 1}px`,
    lg: `${Breakpoint.lg - 1}px`,
    DesktopLarge: `${Breakpoint.DesktopLarge - 1}px`,
    xl: `${Breakpoint.xl - 1}px`,
};
