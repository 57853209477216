import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UberAvailabilityGeneral } from "@/src/modules/devliery-method/type/uber/components/UberAvailabilityGeneral";
import { SummaryItem } from "@/src/modules/workflow/layout/workflow/summary/SummaryItem";
import { SummaryNote } from "@/src/modules/workflow/layout/workflow/summary/SummaryNote";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const DeliveryMethodConfirm = (p) => {
    const w = p.workflow;
    const s = p.state;
    const m = s.deliveryMethod;
    const d = m === null || m === void 0 ? void 0 : m.details;
    return (_jsxs(_Fragment, { children: [_jsx(SummaryItem, Object.assign({ title: "Delivery" }, { children: "Uber (to be scheduled)" }), void 0), _jsx(SummaryNote, { children: _jsx(UberAvailabilityGeneral, { workflow: w }, void 0) }, void 0)] }, void 0));
};
