import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { t } from "@/src/features/Localization";
import { getUserGroupAboveRank0 } from "@/src/features/User/userHelpers";
import { useAuth } from "@/src/hooks/useAuth";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { GroupType } from "@/src/modules/group/type/GroupType";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import PNGMayaPhotoGreeting from "@/src/public/app/images/png/pages/home-page/maya-photo-greeting.png";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	display: flex;
	gap: 16px;
`;
const RootImage = styled.div `
	flex-basis: 80px;
	display: flex;
	align-items: center;

	& img {
		height: 80px;
		width: 80px;
	}
`;
const RootHeader = styled.div `
	display: flex;
	flex-direction: column;
	padding-top: 9px;
	padding-bottom: 9px;
	gap: 8px;
	white-space: pre-wrap; // This ensures the text wraps and uses properly.
`;
const Header1 = styled.div `
	${TypographyV3.Body};
	font-weight: 600;
`;
const Header2 = styled.div `
	${TypographyV3.Body};
`;
export const HomeHeaderGreeting = () => {
    var _a, _b, _c;
    const bc = useContext(BrandContext);
    const { user } = useAuth();
    const group = getUserGroupAboveRank0(user);
    const imageUrl = ((_a = group === null || group === void 0 ? void 0 : group.details.images.logoAppHome) === null || _a === void 0 ? void 0 : _a.url) || PNGMayaPhotoGreeting;
    const firstName = (_b = user === null || user === void 0 ? void 0 : user.details) === null || _b === void 0 ? void 0 : _b.nameFirst;
    const brand = getSettingDetailsByType(SettingType.GroupBrand, user === null || user === void 0 ? void 0 : user.settings);
    const isPharmacy = (group === null || group === void 0 ? void 0 : group.type) == GroupType.Pharmacy;
    const isInstitution = (group === null || group === void 0 ? void 0 : group.type) == GroupType.Institution;
    const isGeneric = !isPharmacy && !isInstitution;
    return (_jsxs(Root, { children: [_jsx(RootImage, { children: _jsx("img", { src: imageUrl }, void 0) }, void 0), _jsxs(RootHeader, { children: [_jsx(Header1, { children: t("home_greeting_header", { firstName }) }, void 0), _jsx(Header2, { children: (_c = bc.Copy.homeWelcome) !== null && _c !== void 0 ? _c : (_jsxs(_Fragment, { children: [isGeneric && _jsx(_Fragment, { children: "How can we help?" }, void 0), isInstitution && _jsx(_Fragment, { children: `Welcome to the preferred pharmacy platform of ${group === null || group === void 0 ? void 0 : group.details.name}. How can we help?` }, void 0), isPharmacy && _jsx(_Fragment, { children: `Welcome to ${group === null || group === void 0 ? void 0 : group.details.name}. How can we help?` }, void 0)] }, void 0)) }, void 0)] }, void 0)] }, void 0));
};
