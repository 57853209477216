import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { SummaryItemTitle } from "@/src/modules/workflow/layout/workflow/summary/SummaryItemTitle";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div ``;
const RootLink = styled.a `
	width: 325px;
	height: 18px;
	border-radius: 3px;
	background-color: ${(p) => p.theme.badgeBackground};
	display: flex;
	padding: 15px 12px;
	gap: 212px;
	text-decoration: none;
`;
const RootIcon = styled.div ``;
const RootNotes = styled.div `
	padding: 15px 12px;
`;
const RootContent = styled.div `
	& {
		${TypographyV3.Hint};
	}
`;
export const WorkflowTrackingInfo = (p) => {
    const bc = useContext(BrandContext);
    const workflowDetails = p.workflow.details;
    const hasTrackingUrl = Boolean(workflowDetails.trackingURL);
    const hasTrackingNotes = Boolean(workflowDetails.trackingNotes);
    if (!hasTrackingUrl && !hasTrackingNotes) {
        return _jsx(_Fragment, {}, void 0);
    }
    return (_jsxs(Root, { children: [hasTrackingUrl && (_jsxs(RootLink, Object.assign({ href: workflowDetails === null || workflowDetails === void 0 ? void 0 : workflowDetails.trackingURL, target: "_blank", rel: "noopener" }, { children: [_jsx(SummaryItemTitle, { children: "Track Package" }, void 0), _jsx(RootIcon, { children: _jsx(Icons.ChevronRight, { size: 18, color: bc.Colors.icon }, void 0) }, void 0)] }), void 0)), hasTrackingNotes && (_jsxs(RootNotes, { children: [_jsx(SummaryItemTitle, { children: t("rxFillDetails_subheader_trackingNotes") }, void 0), _jsx(RootContent, { children: workflowDetails === null || workflowDetails === void 0 ? void 0 : workflowDetails.trackingNotes }, void 0)] }, void 0))] }, void 0));
};
