import { jsx as _jsx } from "react/jsx-runtime";
import { TimelineStep } from "@/src/modules/workflow/layout/workflow/progress/timeline/TimelineStep";
import { TimelineStepState } from "@/src/modules/workflow/layout/workflow/progress/timeline/TimelineStepState";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const Timeline = (p) => {
    const stepCurrent = p.step;
    const stepLength = p.steps.length;
    return (_jsx(Root, { children: p.steps.map((stepInfo, stepIndex) => {
            const isLast = stepIndex == stepLength - 1;
            const state = getStateInfo(p.steps, stepCurrent, stepIndex);
            return _jsx(TimelineStep, { isLast: isLast, state: state, step: stepInfo }, stepIndex);
        }) }, void 0));
};
const getStateInfo = (steps, stepCurrent, stepIndex) => {
    const stepInfo = steps[stepCurrent];
    let state;
    if (stepIndex < stepCurrent) {
        state = TimelineStepState.Complete;
    }
    else if (stepIndex == stepCurrent && stepInfo.isActiveCustomer) {
        state = TimelineStepState.ActiveCustomer;
    }
    else if (stepIndex == stepCurrent) {
        state = TimelineStepState.ActiveDashboard;
    }
    else {
        state = TimelineStepState.Pending;
    }
    return state;
};
