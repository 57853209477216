import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStyle, getButtonStyleInfo } from "@/src/common/components/button/ButtonStyle";
import { usePopup } from "@/src/common/components/popup/Popup";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { useRef } from "react";
import styled from "styled-components";
export const Root = styled.div `
	&,
	&.disabled,
	&:focus,
	&:hover {
		background: transparent;
		border: 1px solid transparent;
		//border-radius: 4px;
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		font-size: 0.8em;
		//font-weight: lighter;
		font-weight: normal;
		margin: 0;
		outline: none;
		padding: 0.25em;
		position: relative;
		text-transform: ${(p) => (p.$isCaps ? "uppercase" : "none")};
		transition-duration: 200ms;
		transition-property: background, border, color, opacity;
	}

	&.disabled {
		cursor: inherit;
		opacity: 0.5;
	}

	${(p) => p.$info.Css};
`;
const RootContent = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 0.25em;
		white-space: inherit;
		word-break: inherit;
	}

	& svg {
		//font-size: 0.8em;
	}

	& span {
		display: block;
	}

	& span:first-child {
		padding-left: 0.25em;
	}

	& span:last-child {
		padding-right: 0.25em;
	}
`;
const RootPopup = styled.div `
	& {
		align-items: stretch;
		display: flex;
		flex-direction: column;
		gap: 2px;
		padding: 4px;
		min-width: 12ch;
		white-space: nowrap;
	}
`;
export const ButtonIcon = (p) => {
    var _a, _b, _c;
    const po = usePopup();
    const color = (_a = p.color) !== null && _a !== void 0 ? _a : ButtonStyle.Blank;
    const colorInfo = getButtonStyleInfo(color);
    const isActive = Boolean(p.isActive);
    const isCaps = (_b = p.isCaps) !== null && _b !== void 0 ? _b : false;
    const isBubble = (_c = p.isBubble) !== null && _c !== void 0 ? _c : true;
    const isLoading = Boolean(p.isLoading);
    const isDisabled = Boolean(p.isDisabled) || isLoading;
    const refRoot = useRef(null);
    const handleClick = (e) => {
        if (!isBubble) {
            e.stopPropagation();
        }
        if (isDisabled) {
            return;
        }
        if (p.onClick) {
            p.onClick(e);
        }
        if (p.onClickPopup) {
            po.AddPopup({
                guid: generateGuid(),
                target: refRoot.current,
                content: (p1) => {
                    return _jsx(RootPopup, { children: p.onClickPopup() }, void 0);
                },
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Root, Object.assign({ className: `${isActive && "active"} ${isDisabled && "disabled"} ${isLoading && "loading"}`, "$info": colorInfo, "$isCaps": isCaps, onClick: handleClick, ref: refRoot }, { children: _jsx(RootContent, { children: p.children }, void 0) }), void 0), po.Outlet] }, void 0));
};
