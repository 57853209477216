var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Field } from "@/src/common/components/field/Field";
import { FieldList } from "@/src/common/components/field/FieldList";
import { FormId } from "@/src/enums/forms";
import { Form } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { LayoutUserRegisterPartnerStep } from "@/src/modules/user/layout/LayoutUserRegisterPartner";
import { useUserRegisterPartnerEmailCheckMutation, useUserRegisterPartnerEmailSendMutation } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { FormikProvider, useFormik } from "formik";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	padding-top: 32px;
	max-width: 100%;
`;
export const FormEmail = (p) => {
    const history = useHistory();
    const [apiEmailCheck, apiEmailCheckState] = useUserRegisterPartnerEmailCheckMutation();
    const [apiEmailSend, apiEmailSendState] = useUserRegisterPartnerEmailSendMutation();
    const formikInitialValues = {
        email: "",
    };
    const formik = useFormik({
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: Yup.object().shape({
            email: Yup.string().email(t("common_emailInput_error_invalidFormat")).required(t("common_emailInput_error_invalidFormat")),
        }),
        onSubmit: (v) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const responseEmailCheck = (yield apiEmailCheck({
                    tokenRegister: p.tokenRegister,
                    email: v.email,
                }).unwrap());
                if (responseEmailCheck.code == 200) {
                    const isExists = responseEmailCheck.data.isExists;
                    if (!isExists) {
                        history.push(routes.RegisterPartner.toPath({
                            step: LayoutUserRegisterPartnerStep.Information,
                            email: v.email,
                            token: p.tokenRegister,
                        }));
                        return;
                    }
                    const responseEmailSend = (yield apiEmailSend({
                        tokenRegister: p.tokenRegister,
                        email: v.email,
                    }).unwrap());
                    if (responseEmailSend.code == 200) {
                        history.push(routes.CheckEmail.toPath({
                            email: v.email,
                        }));
                    }
                }
            }
            catch (e) {
                // TODO: reidenzon - Do something!
                // debugger;
            }
        }),
    });
    return (_jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader
                //
                , { 
                    //
                    title: "Welcome to Mednow online pharmacy", description: "Get prescription medications and clinical support without ever leaving your house." }, void 0), _jsx(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.RegisterPartnerEmailCheck }, { children: _jsxs(FieldList, { children: [_jsx(Field
                            //
                            , Object.assign({ 
                                //
                                title: "What is your personal email?", description: "By using your personal email you can ensure there is no disruption to your service if you work email changes in the future." }, { children: _jsx(FormikTextField, { fullWidth: true, formikId: "email", isSubmitEnter: true, inputProps: {
                                        autoComplete: "username",
                                    }, label: "Email address" }, void 0) }), void 0), _jsx(ButtonStandard
                            //
                            , { 
                                //
                                style: ButtonStyle.SolidPrimary, onClick: formik.handleSubmit, content: "Continue", isForward: true, isSubmit: true, isWide: true }, void 0)] }, void 0) }), void 0)] }, void 0) }), void 0));
};
