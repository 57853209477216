import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Drawer, DrawerAnchorEnum, DrawerContent } from "@/src/features/common/Drawer";
import { t } from "@/src/features/Localization";
import { NavDrawerContent } from "@/src/features/Nav/NavDrawer/NavDrawerContent";
import { NavDrawerHeader } from "@/src/features/Nav/NavDrawer/NavDrawerHeader";
import { VersionAndConditionalBuildId } from "@/src/features/VersionNumber/VersionAndConditionalBuildId";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { useContext } from "react";
import styled from "styled-components";
const DrawerContentStyled = styled(DrawerContent) ``;
const NavDrawerFooter = styled.div `
	${TypographyV3.Hint};
	display: block;
	padding: 32px;

	&,
	& * {
		color: white !important;
	}
`;
export const NavDrawer = (p) => {
    const { isOpen, onClose } = p;
    const bc = useContext(BrandContext);
    return (_jsx(Drawer, Object.assign({ anchor: DrawerAnchorEnum.Left, open: isOpen, onClose: onClose, PaperProps: {
            sx: {
                backgroundColor: bc.Colors.menuBackground,
            },
        } }, { children: _jsx(DrawerContentStyled, Object.assign({ drawerHeader: _jsx(NavDrawerHeader, { handleNavDrawerClose: onClose }, void 0), drawerFooter: _jsx(NavDrawerFooter, { children: t("main_drawer_version", {
                    version: _jsx(VersionAndConditionalBuildId, {}, void 0),
                }) }, void 0), useMaxHeight: true, isCenterItems: true }, { children: _jsx(NavDrawerContent, {}, void 0) }), void 0) }), void 0));
};
