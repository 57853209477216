import { jsx as _jsx } from "react/jsx-runtime";
import { SummaryRxWorkflow } from "@/src/modules/workflow/layout/workflow/summary/SummaryRxWorkflow";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
	}
`;
export const SummaryRxWorkflowList = (p) => {
    return (_jsx(Root, { children: p.rxList.map((rx) => {
            return _jsx(SummaryRxWorkflow, { rx: rx }, rx.rxNumber);
        }) }, void 0));
};
