import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import styled from "styled-components";
const Root = styled.footer `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`;
export const SlideshowFooter = (p) => {
    const segment = useSegmentAnalytics();
    const handleClose = () => {
        p.onClose();
        segment.segmentTrackEvent("select_close", {
            entrypoint: SegmentEntryPoint.OnboardingSlideshow,
        });
    };
    const handleSkip = () => {
        p.onClose();
        segment.segmentTrackEvent("select_skip", {
            entrypoint: SegmentEntryPoint.OnboardingSlideshow,
        });
    };
    return (_jsxs(Root, { children: [p.isClose && (_jsx(ButtonStandard
            //
            , { 
                //
                style: ButtonStyle.TextSubtle, onClick: handleClose, content: "Close" }, void 0)), p.isSkip && (_jsx(ButtonStandard
            //
            , { 
                //
                style: ButtonStyle.TextSubtle, onClick: handleSkip, content: "Skip Tour" }, void 0)), p.isNext && _jsx(ButtonStandard, { content: "Next", onClick: p.onNext, isForward: true }, void 0)] }, void 0));
};
