import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.div ``;
const RootIcon = styled.div `
	margin: 16px auto;
	display: block;
	width: 78px;
	height: 84px;
`;
const RootTitle = styled.div `
	${TypographyV3.Hint};
	text-align: center;
`;
export const OrderCardNoOrders = () => {
    return (_jsxs(Root, { children: [_jsx(RootIcon, { children: _jsx(Icons.OrdersNone, { width: 78, height: 84 }, void 0) }, void 0), _jsx(RootTitle, { children: "No orders" }, void 0)] }, void 0));
};
