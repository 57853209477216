var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { consoleCatch } from "@/src/common/utility/log/Log";
import { Drawer, DrawerAnchorEnum } from "@/src/features/common/Drawer";
import { JunctionPopupEventType } from "@/src/modules/junction/components/popup/JunctionPopupEvent";
import { getJunctionPopupSizeInfo } from "@/src/modules/junction/type/JunctionPopupSize";
import { useEffect, useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
		//padding: 12px;
	}

	${(p) => p.$sizeInfo.Css};
`;
const RootFrame = styled.iframe `
	& {
		border: none;
		flex: 1;
		width: 100%;
	}
`;
export const JunctionPopupDrawer = (p) => {
    const sizeInfo = getJunctionPopupSizeInfo(p.size);
    const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        // TODO: reidenzon - Uncomment once flag is sent from API.
        // if (!popup?.dismissible) {
        // 	return;
        // }
        setIsOpen(false);
        (_a = p.onDismiss) === null || _a === void 0 ? void 0 : _a.call(p);
    });
    const handleMessage = (event) => {
        const type = event.data;
        if (type === JunctionPopupEventType.Close) {
            handleClose().catch(consoleCatch);
        }
    };
    useEffect(() => {
        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, []);
    return (_jsx(Drawer
    //
    , Object.assign({ 
        //
        anchor: DrawerAnchorEnum.Bottom, open: isOpen, onClose: handleClose }, { children: _jsx(Root, Object.assign({ "$sizeInfo": sizeInfo }, { children: _jsx(RootFrame, { src: p.url }, void 0) }), void 0) }), void 0));
};
