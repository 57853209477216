/* eslint-disable max-len */
import SVGAdd from "@/src/features/common/Media/SVGs/SvgAdd";
import SVGCancelRounded from "@/src/features/common/Media/SVGs/SvgCancelRounded";
import SVGCheck from "@/src/features/common/Media/SVGs/SvgCheck";
import SVGCheckRounded from "@/src/features/common/Media/SVGs/SvgCheckRounded";
import SVGChevronDownRound from "@/src/features/common/Media/SVGs/SvgChevronDownRound";
import SVGChevronRight from "@/src/features/common/Media/SVGs/SvgChevronRight";
import SVGClear from "@/src/features/common/Media/SVGs/SvgClear";
import SVGEdit from "@/src/features/common/Media/SVGs/SvgEdit";
import SvgPrescription from "@/src/features/common/Media/SVGs/SvgPrescription";
import SVGSave from "@/src/features/common/Media/SVGs/SvgSave";
import SVGSearch from "@/src/features/common/Media/SVGs/SvgSearch";
/**
 * IMPORTANT NOTE:
 * If the contents of the svg contains a "mask" id, then we need to convert that to
 * react component, located in: "@/src/features/common/Media/SVGs/".
 * E.g.: @/src/features/common/Media/SVGs/SvgArrowBack
 *
 * The reason for this is if the svg is used more than once on a page, the Safari
 * browser has a bug where it loses the mask if one of those repeated svgs is removed.
 * And the svg then looks like a square (i.e. loses mask)
 *
 * E.g. see the id "mask0" in:
 *
 * <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
 *		<mask id="mask0" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-3" y="-3" width="24" height="24">
 *			<path d="M16 10H10V16H8V10H2V8H8V2H10V8H16V10Z" fill="white"/>
 *		</mask>
 *		<g mask="url(#mask0)">
 *			<rect width="18" height="18" fill="white"/>
 *		</g>
 *	</svg>
 *
 *
 * NOTE: When converting to svg you will need to change some attributes.
 * E.g. from kebab case to camel case:
 *  <path fill-rule="evenodd" ... > to <path fillRule="evenodd" ... >
 * Or
 * <mask ... style="mask-type:alpha" > to <mask ... style={{ maskType: "alpha" }} >
 *
 */
import SVGAccordian from "@/src/public/app/images/svg/accordian.svg";
import SVGAddProfile from "@/src/public/app/images/svg/add-profile.svg";
import SVGWalletAmericanExpress from "@/src/public/app/images/svg/american_express_card.svg";
import SVGArrowForward from "@/src/public/app/images/svg/arrow-forward.svg";
import SVGArrowForwardRound from "@/src/public/app/images/svg/arrow-right-default.svg";
import SVGAvatarPatientProfile from "@/src/public/app/images/svg/avatar-patient.svg";
import SVGBusiness from "@/src/public/app/images/svg/business.svg";
import SVGButtonPower from "@/src/public/app/images/svg/button-power.svg";
import SVGCancelProfile from "@/src/public/app/images/svg/cancel-profile.svg";
import SVGCancel from "@/src/public/app/images/svg/cancel.svg";
import SVGChatActive from "@/src/public/app/images/svg/chat-active.svg";
import SVGChatPendingMessages from "@/src/public/app/images/svg/chat-pending-messages.svg";
import SVGChat from "@/src/public/app/images/svg/chat.svg";
import SVGChevronRightAqua from "@/src/public/app/images/svg/chevron-right-aqua.svg";
import SVGWalletDinersClub from "@/src/public/app/images/svg/diners_club_card.svg";
import SVGWalletDiscover from "@/src/public/app/images/svg/discover_card.svg";
import SVGDoctor from "@/src/public/app/images/svg/doctor.svg";
import SVGDownloadApple from "@/src/public/app/images/svg/download-apple.svg";
import SVGDownloadGoogle from "@/src/public/app/images/svg/download-google.svg";
import SVGExternalLink from "@/src/public/app/images/svg/external-link.svg";
import SVGFancyCircleError from "@/src/public/app/images/svg/fancy-circle-error.svg";
import SVGFancyCircleSuccess from "@/src/public/app/images/svg/fancy-circle-success.svg";
import SVGFooterHome from "@/src/public/app/images/svg/footer/home.svg";
import SVGFooterOrders from "@/src/public/app/images/svg/footer/orders.svg";
import SVGFooterProfile from "@/src/public/app/images/svg/footer/profile.svg";
import SVGFooterServices from "@/src/public/app/images/svg/footer/services.svg";
import SVGHome from "@/src/public/app/images/svg/home.svg";
import SVGHyperlinkExpired from "@/src/public/app/images/svg/hyperlink-expired.svg";
import SVGWalletJCB from "@/src/public/app/images/svg/jcb_card.svg";
import SVGMednowIcon from "@/src/public/app/images/svg/logo/mednow-icon-color.svg";
import SVGMednowIconWhite from "@/src/public/app/images/svg/logo/mednow-icon-white.svg";
import SVGMednowLogoWithName from "@/src/public/app/images/svg/logo/mednow-logo-color.svg";
import SVGMednowLogoWithWhiteName from "@/src/public/app/images/svg/logo/mednow-logo-white.svg";
import SVGWalletMasterCard from "@/src/public/app/images/svg/mastercard_card.svg";
import SVGOrders from "@/src/public/app/images/svg/orders-home.svg";
import SVGOrdersNone from "@/src/public/app/images/svg/orders-none.svg";
import SVGPhone from "@/src/public/app/images/svg/phone.svg";
import SVGPrescriptionNotSubmitted from "@/src/public/app/images/svg/prescription-not-submitted.svg";
import SVGProfile from "@/src/public/app/images/svg/profile-icon.svg";
import SVGRefillNotAvailable from "@/src/public/app/images/svg/refill-not-available.svg";
import SVGStepItemActiveCustomer from "@/src/public/app/images/svg/step-item-active-customer.svg";
import SVGStepItemActiveDashboard from "@/src/public/app/images/svg/step-item-active-dashboard.svg";
import SVGStepItemComplete from "@/src/public/app/images/svg/step-item-complete.svg";
import SVGStepItemPending from "@/src/public/app/images/svg/step-item-pending.svg";
import SVGWalletUnionPay from "@/src/public/app/images/svg/union_pay_card.svg";
import SVGWalletVisa from "@/src/public/app/images/svg/visa_card.svg";
import SVGVisibility from "@/src/public/app/images/svg/visibility_black_24dp.svg";
import SVGVisibilityOff from "@/src/public/app/images/svg/visibility_off_black_24dp.svg";
import SVGVolumeOff from "@/src/public/app/images/svg/volume-off-2.svg";
import SVGVolumeOn from "@/src/public/app/images/svg/volume-on-2.svg";
import SVGWalletNoOrderPayment from "@/src/public/app/images/svg/wallet-no-order-payment.svg";
import styled, { css } from "styled-components";
// It will take "size" or "width" and "height"
const addSize = ({ size, width = 24, height = 24 }) => {
    const iconWidth = size !== null && size !== void 0 ? size : width;
    const iconHeight = size !== null && size !== void 0 ? size : height;
    return `
		width: ${iconWidth}px;
		height: ${iconHeight}px;
`;
};
const addColorFillToCircle = ({ color }) => color
    ? css `
				& circle {
					fill: ${color};
				}
		  `
    : "";
const addColorFillToPath = ({ color }) => color
    ? css `
				& path {
					fill: ${color};
				}
		  `
    : "";
const addColorFillToRect = ({ color }) => color
    ? css `
				& rect {
					fill: ${color};
				}
		  `
    : "";
const addColorStrokeToPath = ({ color }) => color
    ? css `
				& path {
					stroke: ${color};
				}
		  `
    : "";
export const Accordian = styled(SVGAccordian) `
	${addSize}
	${addColorFillToPath}
`;
export const Add = styled(SVGAdd) `
	${addSize}
	${addColorFillToRect}
`;
export const AddProfile = styled(SVGAddProfile) `
	${addSize}
	${addColorFillToRect}
`;
export const ArrowForward = styled(SVGArrowForward) `
	${addSize}
	${addColorFillToPath}
`;
export const ArrowForwardRound = styled(SVGArrowForwardRound) `
	${addSize}
	${addColorStrokeToPath}
`;
export const AvatarPatientProfile = styled(SVGAvatarPatientProfile) `
	${addSize}
	${addColorFillToCircle}
`;
export const Business = styled(SVGBusiness) `
	${addSize}
	${addColorFillToPath}
`;
export const ButtonPower = styled(SVGButtonPower) `
	${addSize}
	${addColorFillToPath}
`;
export const Cancel = styled(SVGCancel) `
	${addSize}
	${addColorFillToPath}
`;
export const CancelProfile = styled(SVGCancelProfile) `
	${addSize}
	${addColorFillToPath}
`;
export const CancelRounded = styled(SVGCancelRounded) `
	${addSize}
	${addColorFillToRect}
`;
export const Chat = styled(SVGChat) `
	${addSize}
	${addColorStrokeToPath}
`;
export const ChatActive = styled(SVGChatActive) `
	${addSize}
	${addColorFillToPath}
`;
export const ChatPendingMessages = styled(SVGChatPendingMessages) `
	${addSize}
	${addColorFillToPath}
`;
export const Check = styled(SVGCheck) `
	${addSize}
	${addColorFillToRect}
`;
export const ChevronDownRound = styled(SVGChevronDownRound) `
	${addSize}
	${addColorFillToRect}
`;
export const ChevronRight = styled(SVGChevronRight) `
	${addSize}
	${addColorFillToRect}
`;
export const ChevronRightAqua = styled(SVGChevronRightAqua) `
	${addSize}
	${addColorFillToPath}
`;
export const CheckRounded = styled(SVGCheckRounded) `
	${addSize}
	${addColorFillToRect}
`;
export const Clear = styled(SVGClear) `
	${addSize}
	${addColorFillToPath}
`;
export const Doctor = styled(SVGDoctor) `
	${addSize}
	${addColorFillToPath}
`;
export const DownloadApple = styled(SVGDownloadApple) `
	${addColorFillToPath}
`;
export const DownloadGoogle = styled(SVGDownloadGoogle) `
	${addColorFillToPath}
`;
export const Edit = styled(SVGEdit) `
	${addSize}
`;
export const ExternalLink = styled(SVGExternalLink) `
	${addSize}
	${addColorFillToPath}
`;
export const FancyCircleError = styled(SVGFancyCircleError) `
	${addSize}
	${addColorFillToPath}
`;
export const FancyCircleSuccess = styled(SVGFancyCircleSuccess) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterHome = styled(SVGFooterHome) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterOrders = styled(SVGFooterOrders) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterProfile = styled(SVGFooterProfile) `
	${addSize}
	${addColorFillToPath}
`;
export const FooterServices = styled(SVGFooterServices) `
	${addSize}
	${addColorFillToPath}
`;
export const Home = styled(SVGHome) `
	${addSize}
	${addColorFillToPath}
`;
export const HyperlinkExpired = styled(SVGHyperlinkExpired) `
	${addSize}
	${addColorFillToPath}
`;
export const MednowIcon = styled(SVGMednowIcon) `
	${addSize}
	${addColorFillToPath}
`;
export const MednowIconWhite = styled(SVGMednowIconWhite) `
	${addSize}
	${addColorFillToPath}
`;
export const MednowLogoWithName = styled(SVGMednowLogoWithName) `
	${addSize}
	${addColorFillToPath}
`;
export const MednowLogoWithWhiteName = styled(SVGMednowLogoWithWhiteName) `
	${addSize}
	${addColorFillToPath}
`;
export const Orders = styled(SVGOrders) `
	${addSize}
	${addColorFillToPath}
`;
export const OrdersNone = styled(SVGOrdersNone) `
	${addSize}
	${addColorFillToPath}
`;
export const Phone = styled(SVGPhone) `
	${addSize}
	${addColorFillToPath}
`;
export const Prescription = styled(SvgPrescription) `
	${addSize}
	${addColorFillToPath}
`;
export const PrescriptionNotSubmitted = styled(SVGPrescriptionNotSubmitted) `
	${addSize}
	${addColorFillToPath}
`;
export const Profile = styled(SVGProfile) `
	${addSize}
	${addColorFillToPath}
`;
export const RefillNotAvailable = styled(SVGRefillNotAvailable) `
	${addSize}
	${addColorFillToPath}
`;
export const Save = styled(SVGSave) `
	${addSize}
	${addColorFillToPath}
`;
export const Search = styled(SVGSearch) `
	${addSize}
	${addColorFillToPath}
`;
export const StepItemActiveCustomer = styled(SVGStepItemActiveCustomer) `
	${addSize}
	${addColorFillToPath}
`;
export const StepItemActiveDashboard = styled(SVGStepItemActiveDashboard) `
	${addSize}
	${addColorFillToPath}
`;
export const StepItemComplete = styled(SVGStepItemComplete) `
	${addSize}
	${addColorFillToPath}
`;
export const StepItemPending = styled(SVGStepItemPending) `
	${addSize}
	${addColorFillToPath}
`;
export const Visibility = styled(SVGVisibility) `
	${addSize}
	${addColorFillToPath}
`;
export const VisibilityOff = styled(SVGVisibilityOff) `
	${addSize}
	${addColorFillToPath}
`;
export const VolumeOff = styled(SVGVolumeOff) `
	${addSize}
	${addColorFillToPath}
`;
export const VolumeOn = styled(SVGVolumeOn) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletNoOrderPayment = styled(SVGWalletNoOrderPayment) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletAmericanExpress = styled(SVGWalletAmericanExpress) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletDinersClub = styled(SVGWalletDinersClub) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletDiscover = styled(SVGWalletDiscover) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletJCB = styled(SVGWalletJCB) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletMasterCard = styled(SVGWalletMasterCard) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletUnionPay = styled(SVGWalletUnionPay) `
	${addSize}
	${addColorFillToPath}
`;
export const WalletVisa = styled(SVGWalletVisa) `
	${addSize}
	${addColorFillToPath}
`;
