import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { ListCTAItemLabel, ListCTAItemLabelStyle } from "@/src/features/common/ListCTA/ListCTAItemLabel";
import { t } from "@/src/features/Localization";
import { PatientAvatar } from "@/src/features/Patient/PatientAvatar";
import { doesSettingHaveKrollIntegration } from "@/src/features/User/userHelpers";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { useUserDexcomQuery } from "@/src/modules/user/redux/ApiCoreUser";
import { useUserRxCountRefillQuery, useUserRxReviewQuery } from "@/src/redux/apiServices/suiteApi";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.button `
	& {
		background: ${(p) => p.theme.cardBackground};
		border: 1px solid ${(p) => p.theme.divider};
		border-radius: 3px;
		cursor: pointer;
		min-height: 64px;
		padding: 12px;
		width: 350px;
	}

	&.disabled {
		cursor: initial;
		opacity: 0.5;
	}
`;
const RootInner = styled.div `
	align-items: center;
	display: flex;
	gap: 12px;
`;
const RootAvatar = styled.div ``;
const RootContent = styled.div `
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 210px;
	text-align: left;
`;
const RootName = styled.div `
	${TypographyV3.Button};
	color: ${(p) => p.theme.buttonPrimary};
`;
const RootRelationship = styled.div `
	${TypographyV3.Hint};
`;
const RightIconContainer = styled.div `
	padding-top: 6px;
`;
export const PatientSelectCard = (p) => {
    var _a, _b;
    const bc = useContext(BrandContext);
    const isUserKroll = doesSettingHaveKrollIntegration(p.userSettings);
    const isGroupDexcom = getSettingDetailsByType(SettingType.GroupDexcom, p.userSettings).isEnabled;
    const isGroupServiceClinical = getSettingDetailsByType(SettingType.GroupServiceClinical, p.userSettings).isEnabled;
    const isGroupMedicationReview = getSettingDetailsByType(SettingType.GroupMedicationReview, p.userSettings).isEnabled;
    const isGroupMedVisit = getSettingDetailsByType(SettingType.GroupMedVisit, p.userSettings).isEnabled;
    const isDexcom = p.isDexcom && isGroupDexcom;
    const isMedVisit = p.isMedVisit && isGroupMedVisit;
    const isRxCount = p.isRxCount && isUserKroll;
    const isRxReview = p.isRxReview && isGroupMedicationReview;
    const isServiceClinical = p.isServiceClinical && isGroupServiceClinical;
    const apiDexcom = useUserDexcomQuery({ userForGuid: p.userGuid, isAddDependents: false }, { skip: !isDexcom });
    const apiRxCount = useUserRxCountRefillQuery({ userForGuid: p.userGuid, isAddDependents: false }, { skip: !isRxCount });
    const apiRxReview = useUserRxReviewQuery({ userForGuid: p.userGuid, isAddDependents: false }, { skip: !isRxReview });
    const rxCount = ((_a = apiRxCount.data) === null || _a === void 0 ? void 0 : _a.data.count) || 0;
    const rxReview = Boolean((_b = apiRxReview.data) === null || _b === void 0 ? void 0 : _b.data.isAvailable);
    let isEnabled = true;
    let className = "";
    let isDisabled = false;
    if ((p.isRxReview && !rxReview) || (p.isServiceClinical && !isServiceClinical) || (p.isMedVisit && !isMedVisit) || (p.isDexcom && !isDexcom)) {
        isDisabled = true;
    }
    if (isDisabled) {
        isEnabled = false;
        className = "disabled";
    }
    return (_jsx(Root, Object.assign({ className: className, onClick: () => isEnabled &&
            p.handleClick({
                userGuid: p.userGuid,
                userDetails: p.userDetails,
                userSettings: p.userSettings,
            }) }, { children: _jsxs(RootInner, { children: [_jsx(RootAvatar, { children: _jsx(PatientAvatar, { color: p.userDetails.color }, void 0) }, void 0), _jsxs(RootContent, { children: [_jsx(RootName, { children: `${p.userDetails.nameFirst} ${p.userDetails.nameLast}` }, void 0), _jsx(RootRelationship, { children: p.label }, void 0)] }, void 0), isRxCount && (_jsx(ListCTAItemLabel, { text: rxCount.toString(), style: rxCount > 0 ? ListCTAItemLabelStyle.Success : ListCTAItemLabelStyle.Warning, isLoading: apiRxCount.isLoading }, void 0)), _jsx(RightIconContainer, { children: _jsx(Symbol, { icon: "chevron_right", color: bc.Colors.divider }, void 0) }, void 0)] }, void 0) }), void 0));
};
export const PatientSelectListCardAdd = ({ handleClick }) => {
    const bc = useContext(BrandContext);
    return (_jsx(Root, Object.assign({ onClick: handleClick }, { children: _jsxs(RootInner, { children: [_jsx(RootAvatar, { children: _jsx(Icons.AddProfile, { size: 42 }, void 0) }, void 0), _jsx(RootContent, { children: _jsx(RootName, { children: t("patientProfiles_addPatient_button") }, void 0) }, void 0), _jsx(RightIconContainer, { children: _jsx(Symbol, { icon: "add", color: bc.Colors.divider }, void 0) }, void 0)] }, void 0) }), void 0));
};
