import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons } from "@/src/features/common";
import { getUserGroupAboveRank0 } from "@/src/features/User/userHelpers";
import { useAuth } from "@/src/hooks/useAuth";
import styled from "styled-components";
export const Root = styled.div `
	align-items: center;
	display: flex;
	margin: 12px;

	& svg {
		display: block;
	}
`;
const RootImage = styled.img `
	& {
		height: 32px;
	}
`;
export const NavCenterItem = (p) => {
    var _a;
    const auth = useAuth();
    const group = getUserGroupAboveRank0(auth.user);
    const logoUrl = (_a = group === null || group === void 0 ? void 0 : group.details.images.logoAppMenu) === null || _a === void 0 ? void 0 : _a.url;
    const centerItem = p.centerItem;
    if (centerItem) {
        return _jsx(_Fragment, { children: centerItem }, void 0);
    }
    return (_jsxs(Root, { children: [logoUrl && _jsx(RootImage, { src: logoUrl }, void 0), !logoUrl && _jsx(Icons.MednowIconWhite, { size: 32 }, void 0)] }, void 0));
};
