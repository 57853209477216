import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChatHoursMedVisit } from "@/src/modules/workflow/layout/chat/ChatHoursMedVisit";
import { ChatHoursPharmacy } from "@/src/modules/workflow/layout/chat/ChatHoursPharmacy";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const ChatHoursBoxList = (p) => {
    return (_jsxs(Root, { children: [_jsx(ChatHoursPharmacy, {}, void 0), _jsx(ChatHoursMedVisit, {}, void 0)] }, void 0));
};
