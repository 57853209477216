import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { WalletAmericanExpress, WalletDiscover, WalletMasterCard, WalletVisa } from "@/src/features/common/Icons";
import { t } from "@/src/features/Localization";
import styled from "styled-components";
const ICON_STYLE = { width: 40, height: 28 };
const Root = styled.div `
	display: flex;
	align-items: center;
	gap: 12px;
`;
const Brands = styled.div `
	display: flex;
	gap: 8px;
	align-items: center;
`;
const Label = styled.div `
	${TypographyV3.Body};
`;
export const AcceptedCreditCards = () => (_jsxs(Root, { children: [_jsx(Label, { children: t("profile_tab_wallet_addPaymentMethod_bottomSheet_acceptedMethods") }, void 0), _jsxs(Brands, { children: [_jsx(WalletVisa, { style: ICON_STYLE }, void 0), _jsx(WalletAmericanExpress, { style: ICON_STYLE }, void 0), _jsx(WalletMasterCard, { style: ICON_STYLE }, void 0), _jsx(WalletDiscover, { style: ICON_STYLE }, void 0)] }, void 0)] }, void 0));
