import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { getUserDetailsNameFull } from "@/src/modules/user/type/UserDetails";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
const RootButtons = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`;
export const TaxReportSelect = (p) => {
    const yearList = Array.from({ length: 7 }, (v, i) => i);
    const yearNow = new Date().getUTCFullYear(); // close enough
    return (_jsxs(Root, { children: [_jsx(LayoutContentHeader
            //
            , { 
                //
                title: `Generate a tax receipt report for ${getUserDetailsNameFull(p.patientDetails)}` }, void 0), _jsx(RootButtons, { children: yearList.map((y) => {
                    const year = yearNow - y;
                    return (_jsx(ButtonStandard
                    //
                    , { style: ButtonStyle.SolidPrimary, content: `Generate for ${year}`, onClick: () => p.onYear(year) }, "" + y));
                }) }, void 0)] }, void 0));
};
