import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const DeliveryMethodDetailsFooter = (p) => {
    const w = p.workflow;
    const wd = w.details;
    const m = p.method;
    const md = m.details;
    const isScheduled = Boolean(wd.deliverySchedule);
    return (_jsx(_Fragment, { children: isScheduled && (_jsx(_Fragment, { children: _jsx(AssistanceLink, { title: "If you would like to reschedule this delivery, please contact support." }, void 0) }, void 0)) }, void 0));
};
