import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Drawer, DrawerAnchorEnum, DrawerContent } from "@/src/features/common/Drawer";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { PaymentMethodCard } from "@/src/features/Payment/PaymentMethodCard";
import { ProfileWalletCardDrawerContent } from "@/src/features/Payment/Wallet/ProfileWalletCardDrawerContent";
import { ProfileWalletCardDrawerHeader } from "@/src/features/Payment/Wallet/ProfileWalletCardDrawerHeader";
import { useAuth } from "@/src/hooks/useAuth";
import SVGWalletNoCard from "@/src/public/app/images/svg/wallet-no-card.svg";
import { useUserPaymentMethodsQuery } from "@/src/redux/apiServices/suiteApi";
import { useEffect, useState } from "react";
import styled from "styled-components";
/* --------------------------------- Styles --------------------------------- */
const Root = styled.div ``;
const RootAdd = styled.div `
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin-top: 64px;
`;
const RootLoader = styled.div `
	& {
	}
`;
const RootImage = styled.div `
	& {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	& svg {
		aspect-ratio: 289/206;
		height: 160px;
	}
`;
const RootDrawer = styled.div `
	margin-left: 23px;
	margin-right: 23px;
`;
export const ProfileWalletCards = ({ pharmacyStripeKey }) => {
    var _a;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(true);
    const { user, fetchCurrentUser } = useAuth();
    const { data: userPaymentMethodResponseData, isFetching: isFetchingUserPaymentMethodResponse, refetch: userPaymentMethodRefetch, } = useUserPaymentMethodsQuery({ userFor: user ? user.guid : "" }, {
        skip: !user,
    });
    const paymentMethods = (_a = userPaymentMethodResponseData === null || userPaymentMethodResponseData === void 0 ? void 0 : userPaymentMethodResponseData.data) === null || _a === void 0 ? void 0 : _a.paymentMethods;
    const hasPaymentMethods = paymentMethods && paymentMethods.length > 0;
    /* ---------------------------- Helper Functions ---------------------------- */
    function handleDrawerClose() {
        setIsDrawerOpen(false);
    }
    function handleDrawerOpen() {
        setIsDrawerOpen(true);
    }
    useEffect(() => {
        if (isDrawerOpen) {
            setIsLoadingForm(true);
        }
    }, [isDrawerOpen]);
    // We want to set a 2 second delay every time we open/close the payment method form
    // as Stripe takes some time to render the input components
    useEffect(() => {
        let formLoadingTimer;
        if (isDrawerOpen) {
            formLoadingTimer = setTimeout(() => setIsLoadingForm(false), 2000);
        }
        return () => {
            clearTimeout(formLoadingTimer);
        };
    }, [isDrawerOpen]);
    let RenderedComponent;
    if (isFetchingUserPaymentMethodResponse) {
        RenderedComponent = (_jsx(RootAdd, { children: _jsx(RootLoader, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0) }, void 0));
    }
    else if (hasPaymentMethods) {
        RenderedComponent = (_jsx(PaymentMethodCard, { paymentMethods: paymentMethods, user: user, refetchCurrentUser: fetchCurrentUser, userPaymentMethodRefetch: userPaymentMethodRefetch, isProfile: true }, void 0));
    }
    else {
        RenderedComponent = (_jsxs(RootAdd, { children: [_jsx(RootImage, { children: _jsx(SVGWalletNoCard, {}, void 0) }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "+ Add Card", onClick: handleDrawerOpen }, void 0)] }, void 0));
    }
    // This is the main component responsble for rendering the Card component and displaying
    // the drawer which user may use to update their payment method on file
    return (_jsx(_Fragment, { children: _jsxs(Root, { children: [RenderedComponent, _jsx(Drawer, Object.assign({ anchor: DrawerAnchorEnum.Bottom, open: isDrawerOpen, onClose: handleDrawerClose }, { children: _jsx(RootDrawer, { children: _jsxs(DrawerContent, Object.assign({ isCenterItems: true }, { children: [_jsx(ProfileWalletCardDrawerHeader, {}, void 0), _jsx(ProfileWalletCardDrawerContent, { pharmacyStripeKey: pharmacyStripeKey, handleDrawerClose: handleDrawerClose, refetchCurrentUser: fetchCurrentUser, userPaymentMethodRefetch: userPaymentMethodRefetch, user: user, isLoadingForm: isLoadingForm }, void 0)] }), void 0) }, void 0) }), void 0)] }, void 0) }, void 0));
};
