import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ModalFooterRowCenter } from "@/src/common/components/modal/ModalFooterRowCenter";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { t } from "@/src/features/Localization";
import { Box, Modal } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import styled from "styled-components";
function getModalStyle() {
    return {
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
    };
}
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        position: "absolute",
        width: 327,
        backgroundColor: theme.palette.background.paper,
        border: "2px",
        boxShadow: theme.shadows[1],
    },
}));
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
const BoxStyled = styled(Box) `
	&& {
		text-align: center;
		outline: none;
		box-shadow: none;
		border-radius: 7px;
	}

	&& .MuiButtonBase-root {
		margin-top: 24px;
	}
`;
export const AutoLogoutModal = (p) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const countdown = p.countdown;
    const minutes = Math.floor(countdown / 60).toString();
    const seconds = countdown % 60 === 0 ? "00" : (countdown % 60).toString();
    return (_jsx(Modal, Object.assign({ open: p.isShowModal, onClose: p.setUserActive, "aria-labelledby": "simple-modal-title", "aria-describedby": "simple-modal-description" }, { children: _jsx(BoxStyled, Object.assign({ style: modalStyle, className: classes.paper, "data-cy": "auto-logout-modal" }, { children: _jsxs(Root, { children: [_jsx(ModalHeader
                    //
                    , { 
                        //
                        title: t("common_dialog_inactivity_header"), description: t("common_dialog_inactivity_content", {
                            timeLeft: _jsx("b", { children: t("common_dialog_inactivity_content_timeLeft", { minutes, seconds }) }, void 0),
                        }) }, void 0), _jsx(ModalFooterRowCenter, { children: _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "Dismiss", onClick: p.setUserActive }, void 0) }, void 0)] }, void 0) }), void 0) }), void 0));
};
