var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { JunctionHomeCardsItem } from "@/src/modules/junction/components/home/JunctionHomeCardsItem";
import { useJunctionHomeCardDismissMutation, useJunctionHomeCardsQuery } from "@/src/modules/junction/redux/ApiJunction";
import { useApiJunctionRequest } from "@/src/modules/junction/redux/ApiJunctionRequest";
import { useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const JunctionHomeCards = (p) => {
    var _a;
    const junctionRequest = useApiJunctionRequest();
    const apiList = useJunctionHomeCardsQuery(junctionRequest({}));
    let itemList = (((_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.items) || []);
    const stepMax = itemList.length - 1;
    const [step, setStep] = useState(0);
    const item = itemList[step];
    const [apiDismiss, apiDismissState] = useJunctionHomeCardDismissMutation();
    const isLoading = apiList.isLoading || apiList.isFetching;
    const handleDismiss = (item) => __awaiter(void 0, void 0, void 0, function* () {
        const response = (yield apiDismiss({
            cardId: item.id,
        }).unwrap());
        apiList.refetch();
    });
    const handleStep = (s) => {
        if (s < 0) {
            handleStep(s + itemList.length);
            return;
        }
        if (s > stepMax) {
            handleStep(s - itemList.length);
            return;
        }
        setStep(s);
    };
    if (!item) {
        return null;
    }
    return (_jsx(Root, { children: _jsx(JunctionHomeCardsItem
        //
        , { isDismissing: apiDismissState.isLoading, item: item, onDismiss: handleDismiss, onStep: handleStep, step: step, steps: itemList.length }, `${step}-${item.title}`) }, void 0));
};
