import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
const Root = styled.a `
	& {
		display: block;
		height: inherit;
		//flex: 1;
		//height: 24px;
	}

	& svg {
		height: 100%;
		max-height: 100%;
	}
`;
export const AppStoreLink = (p) => {
    const segment = useSegmentAnalytics();
    const handleClick = (e) => {
        e.preventDefault();
        segment.segmentTrackEvent("select_app_store", {
            app_store: p.store,
        });
        window.open(p.href, "_blank");
    };
    return (_jsx(Root, Object.assign({ href: p.href, onClick: handleClick }, { children: p.icon }), void 0));
};
