import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const defaultSidePadding = 16;
function getPadding({ $isDisableVerticalPadding, $isLargeVerticalPadding }) {
    if ($isDisableVerticalPadding) {
        return `0px ${defaultSidePadding}px`;
    }
    if ($isLargeVerticalPadding) {
        return `40px ${defaultSidePadding}px 32px ${defaultSidePadding}px`;
    }
    return `16px ${defaultSidePadding}px`;
}
/**
 * PageWrapper will automatically ensure the content is within
 * a wrapper with the default defined max width and centering this
 * wrapper on the page.
 */
export const Root = styled.div `
	width: 100%;
	max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : "375px")};
	padding: ${getPadding};
	margin: 0 auto;
	box-sizing: border-box;
	height: ${({ $height }) => $height !== null && $height !== void 0 ? $height : "100%"};
`;
export const PageWrapper = (p) => {
    return (_jsx(Root, Object.assign({ "$height": p.height, "$isDisableVerticalPadding": p.isDisableVerticalPadding, "$isLargeVerticalPadding": p.isLargeVerticalPadding, "$maxWidth": p.maxWidth, style: p.style }, { children: p.children }), void 0));
};
