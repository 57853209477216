export var VirtualCareReason;
(function (VirtualCareReason) {
    VirtualCareReason["Acne"] = "acne";
    VirtualCareReason["Allergies"] = "allergies";
    VirtualCareReason["ColdSores"] = "cold-sores";
    VirtualCareReason["Dexcom"] = "dexcom";
    VirtualCareReason["ErectileDysfunction"] = "erectile-dysfunction";
    VirtualCareReason["FamilyPlanning"] = "family-planning";
    VirtualCareReason["MalePatternBaldness"] = "male-pattern-baldness";
    VirtualCareReason["MentalHealth"] = "mental-health";
    VirtualCareReason["Rosacea"] = "rosacea";
})(VirtualCareReason || (VirtualCareReason = {}));
const reasonInfoMap = new Map();
const reasonInfoList = [
    {
        Reason: VirtualCareReason.Acne,
        Title: "Acne",
        Description: "Acne, or acne vulgaris, is a skin problem that starts when oil and dead skin cells clog up your pores. It affects people of all ages. Mednow Nurse Practitioners can help you navigate the many creams, lotions and pills and prescribe the correct ones to treat your acne.",
        Url: "https://mednow.typeform.com/acne-mn",
    },
    {
        Reason: VirtualCareReason.Allergies,
        Title: "Allergies",
        Description: "Allergy symptoms like sneezing, hives, runny nose and itchy eyes can make it difficult to live your life. Prescription medications, nasal sprays and topical creams can help relieve symptoms so you can feel better again.",
        Url: "https://mednow.typeform.com/allergies",
    },
    {
        Reason: VirtualCareReason.ColdSores,
        Title: "Cold Sores",
        Description: "Cold sores are small, painful blisters that typically appear on or around the lips. It is important to treat as soon as the first signs of an outbreak occur; make sure you always have your pills on hand.",
        Url: "https://mednow.typeform.com/coldsore",
    },
    {
        Reason: VirtualCareReason.Dexcom,
        Title: "Dexcom Devices",
        Description: "Dexcom Continuous Glucose Monitors are used by diabetics to help monitor and control their blood sugars without pricking their finger many times per day. Our pharmacists and nurse practitioners work together to support you in all aspects of your diabetes care.",
        Url: "https://mednow.typeform.com/Dexcom",
    },
    {
        Reason: VirtualCareReason.ErectileDysfunction,
        Title: "Erectile Dysfunction",
        Description: "Erectile Dysfunction medication to improve sexual health for men 40 and over.",
        Warning: "Please note that our NPs do not prescribe ED medications for men under the age of 40.",
        Url: "https://mednow.typeform.com/erectile",
    },
    {
        Reason: VirtualCareReason.FamilyPlanning,
        Title: "Family Planning (birth control)",
        Description: "There are many different options for birth control and family planning. Mednow’s knowledgeable clinicians provide easy access to the birth control that is right for you.",
        Url: "https://mednow.typeform.com/birth-control",
    },
    {
        Reason: VirtualCareReason.MalePatternBaldness,
        Title: "Male Pattern Baldness",
        Description: "In most cases, hair loss is inherited. Age, stress, poor diet, certain diseases, and side effects of medicines or medical treatments can also cause hair loss. Our Nurse Practitioners can help you navigate the cause, and provide treatment options in the form of pills and creams.",
        Url: "https://mednow.typeform.com/hair-loss",
    },
    {
        Reason: VirtualCareReason.MentalHealth,
        Title: "Mental Health (anxiety/depression)",
        Description: "Anxiety and depression can affect every aspect of life. Mednow Nurse Practitioners can support you in your journey to mental wellness by providing medications, prescription refills and connecting you to local resources.",
        Url: "https://mednow.typeform.com/mentalhealth",
    },
    {
        Reason: VirtualCareReason.Rosacea,
        Title: "Rosacea",
        Description: "Rosacea is a common skin condition that primarily affects the face, causing redness, visible blood vessels, and sometimes small, red, pus-filled bumps. Prescription creams and pills can be helpful to treat Rosacea.",
        Url: "https://mednow.typeform.com/rosacea",
    },
];
reasonInfoList.forEach((info) => reasonInfoMap.set(info.Reason, info));
export const getVirtualCareReasonInfo = (r) => {
    return reasonInfoMap.get(r);
};
