import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Config } from "@/src/common/utility/config/Config";
import { getVersionNumber } from "@/src/utils/BuildVersionHelpers";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const VersionAndConditionalBuildId = ({ className }) => {
    if (Config.DebugInfoIsEnabled) {
        const gitlabCommitBaseUrl = "https://gitlab.com/mednow/suite/-/commits/";
        return (_jsxs(Root, Object.assign({ className: className }, { children: [getVersionNumber(), " (", _jsx("a", Object.assign({ target: "_blank", rel: "noreferrer", href: `${gitlabCommitBaseUrl}${Config.GitLabCommit}` }, { children: Config.GitLabCommit }), void 0), ")"] }), void 0));
    }
    return _jsx(Root, Object.assign({ className: className }, { children: getVersionNumber() }), void 0);
};
