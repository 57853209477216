import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.button `
	& {
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		gap: 1ch;
	}
`;
/** @deprecated TODO: reidenzon - Use {@link ButtonIcon} instead! */
export const ButtonIconLegacy = (p) => {
    return _jsx(Root, Object.assign({ onClick: p.onClick }, { children: p.icon }), void 0);
};
