import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { InvoiceTable } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceTable";
import { SummaryList } from "@/src/modules/workflow/layout/workflow/summary/SummaryList";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import styled from "styled-components";
const RootHint = styled.div `
	& {
		${TypographyV3.Hint};
	}
`;
export const WorkflowInvoice = (p) => {
    const workflow = p.workflow;
    const statusInfo = getWorkflowStatusInfo(workflow.status);
    const details = workflow.details;
    const isShow = Boolean(statusInfo.InvoiceIsShow) && details.payment;
    return (_jsxs(SummaryList, Object.assign({ title: "Order summary" }, { children: [!isShow && _jsx(RootHint, { children: "Invoice will appear once your order is processed." }, void 0), isShow && _jsx(InvoiceTable, { onEditTip: p.onEditTip, payment: details.payment, workflow: workflow }, void 0)] }), void 0));
};
