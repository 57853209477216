import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { SummaryImage } from "@/src/modules/workflow/layout/workflow/summary/SummaryImage";
const Root = styled.div `
	& {
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
	}
`;
export const SummaryImageList = (p) => {
    return (_jsx(Root, { children: p.files.map((f) => {
            return _jsx(SummaryImage, { file: f }, f.guid);
        }) }, void 0));
};
