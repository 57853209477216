import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import React, { useRef } from "react";
import { areMessageUIPropsEqual, EditMessageForm as DefaultEditMessageForm, MessageDeleted as DefaultMessageDeleted, messageHasAttachments, messageHasReactions, MessageInput, MessageOptions as DefaultMessageOptions, MessageRepliesCountButton as DefaultMessageRepliesCountButton, MessageStatus as DefaultMessageStatus, MessageText, MessageTimestamp as DefaultMessageTimestamp, MML, Modal, ReactionSelector, ReactionsList, useComponentContext, useMessageContext, } from "stream-chat-react";
import styled from "styled-components";
const RootMessage = styled.div `
	&.str-chat__message--me .str-chat__message-inner {
		margin-left: 35px !important;
	}

	&:not(.str-chat__message--me) .str-chat__message-inner {
		margin-right: 35px !important;
	}
`;
const RootInner = styled.div `
	& {
	}
`;
const RootText = styled.div `
	.str-chat__message-text-inner p,
	.str-chat__message-simple-text-inner p {
		${TypographyV3.Body};
		padding: 8px 4px;
		line-height: 125%;
	}
`;
const MessageSimpleWithContext = (props) => {
    var _a;
    const { additionalMessageInputProps, clearEditingState, editing, endOfGroup, firstOfGroup, groupedByUser, handleAction, handleOpenThread, handleRetry, isMyMessage, isReactionEnabled, message, reactionSelectorRef, showDetailedReactions, threadList, } = props;
    const { Attachment, EditMessageInput = DefaultEditMessageForm, MessageDeleted = DefaultMessageDeleted, MessageOptions = DefaultMessageOptions, MessageRepliesCountButton = DefaultMessageRepliesCountButton, MessageStatus = DefaultMessageStatus, MessageTimestamp = DefaultMessageTimestamp, } = useComponentContext("MessageSimple");
    const messageWrapperRef = useRef(null);
    const hasAttachment = messageHasAttachments(message);
    const hasReactions = messageHasReactions(message);
    const messageClasses = isMyMessage()
        ? "str-chat__message str-chat__message--me str-chat__message-simple str-chat__message-simple--me"
        : "str-chat__message str-chat__message-simple";
    if (message.customType === "message.date") {
        return null;
    }
    if (message.deleted_at || message.type === "deleted") {
        return _jsx(MessageDeleted, { message: message }, void 0);
    }
    return (_jsxs(_Fragment, { children: [editing && (_jsx(Modal, Object.assign({ onClose: clearEditingState, open: editing }, { children: _jsx(MessageInput, Object.assign({ clearEditingState: clearEditingState, Input: EditMessageInput, message: message }, additionalMessageInputProps), void 0) }), void 0)), _jsxs(RootMessage, Object.assign({ className: `
						${messageClasses}
						str-chat__message--${message.type}
						str-chat__message--${message.status}
						${message.text ? "str-chat__message--has-text" : "has-no-text"}
						${hasAttachment ? "str-chat__message--has-attachment" : ""}
            ${hasReactions && isReactionEnabled ? "str-chat__message--with-reactions" : ""}
            ${message.pinned ? "pinned-message" : ""}
            ${groupedByUser ? "str-chat__virtual-message__wrapper--group" : ""}
            ${firstOfGroup ? "str-chat__virtual-message__wrapper--first" : ""}
            ${endOfGroup ? "str-chat__virtual-message__wrapper--end" : ""}
					`.trim(), ref: messageWrapperRef }, { children: [_jsx(MessageStatus, {}, void 0), _jsxs(RootInner, Object.assign({ className: "str-chat__message-inner", onClick: message.status === "failed" && message.errorStatusCode !== 403 ? () => handleRetry(message) : undefined, onKeyDown: () => "", role: "button", tabIndex: 0 }, { children: [_jsxs(_Fragment, { children: [_jsx(MessageOptions, { messageWrapperRef: messageWrapperRef }, void 0), hasReactions && !showDetailedReactions && isReactionEnabled && _jsx(ReactionsList, { reverse: true }, void 0), showDetailedReactions && isReactionEnabled && _jsx(ReactionSelector, { ref: reactionSelectorRef }, void 0)] }, void 0), ((_a = message.attachments) === null || _a === void 0 ? void 0 : _a.length) && !message.quoted_message ? _jsx(Attachment, { actionHandler: handleAction, attachments: message.attachments }, void 0) : null, _jsx(RootText, { children: _jsx(MessageText, { message: message }, void 0) }, void 0), message.mml && _jsx(MML, { actionHandler: handleAction, align: isMyMessage() ? "right" : "left", source: message.mml }, void 0), !threadList && Boolean(message.reply_count) && (_jsx("div", Object.assign({ className: "str-chat__message-simple-reply-button" }, { children: _jsx(MessageRepliesCountButton, { onClick: handleOpenThread, reply_count: message.reply_count }, void 0) }), void 0)), (!groupedByUser || endOfGroup) && (_jsxs("div", Object.assign({ className: `str-chat__message-data str-chat__message-simple-data` }, { children: [!isMyMessage() && message.user ? _jsx("span", Object.assign({ className: "str-chat__message-simple-name" }, { children: message.user.name || message.user.id }), void 0) : null, _jsx(MessageTimestamp, { calendar: true, customClass: "str-chat__message-simple-timestamp" }, void 0)] }), void 0))] }), void 0)] }), message.id)] }, void 0));
};
const MemoizedMessageSimple = React.memo(MessageSimpleWithContext, areMessageUIPropsEqual);
/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const MessageSimple = (props) => {
    const messageContext = useMessageContext("MessageSimple");
    return _jsx(MemoizedMessageSimple, Object.assign({}, messageContext, props), void 0);
};
