import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Icons } from "@/src/features/common";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { t } from "@/src/features/Localization";
const NavPhoneButtonStyled = styled.a `
	display: flex;
	flex-wrap: wrap;
`;
export const NavPhoneButton = () => {
    const { segmentTrackEvent } = useSegmentAnalytics();
    function handlePhoneClick() {
        segmentTrackEvent("select_contact");
    }
    return (_jsx(NavPhoneButtonStyled, Object.assign({ "data-cy": "nav-phone-contact-button", href: `tel:${t("chat_topics_footer_phoneNumber")}`, target: "_blank", rel: "noopener", onClick: handlePhoneClick }, { children: _jsx(Icons.Phone, { size: 32 }, void 0) }), void 0));
};
