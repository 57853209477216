import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Icons } from "@/src/features/common";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { Nav } from "@/src/features/Nav/Nav";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { routes } from "@/src/routes";
import styled from "styled-components";
const Root = styled.div `
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 64px;
`;
const RootImage = styled.div `
	aspect-ratio: 300/240;
	height: 160px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 32px;

	& svg {
		width: 100%;
		height: 100%;
	}
`;
export const LinkResolvedPage = () => {
    // TODO - There may be cases where resend link throws an error
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: { leftItemType: "Login", linkTo: routes.Login.toPath() } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader, { title: t("linkResolved_header") }, void 0), _jsx(RootImage, { children: _jsx(Icons.FancyCircleError, {}, void 0) }, void 0), _jsx(LayoutContentHeader, { description: t("linkResolved_content") }, void 0), _jsx(ButtonNavigate, { style: ButtonStyle.SolidPrimary, pathTo: routes.Login.toPath(), content: "Go to Login" }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
