import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from "@/src/features/common/FormInputs/Checkbox";
import { getDeliveryMethodInfo } from "@/src/modules/devliery-method/type/DeliveryMethodType";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: start;
		background: ${(p) => p.theme.cardBackground};
		border: 1px solid ${(p) => p.theme.divider};
		border-radius: 4px;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		gap: 12px;
		padding: 16px;
	}
`;
const RootCheckbox = styled.div `
	& {
	}
`;
const RootContent = styled.div `
	& {
		flex: 1;
	}
`;
export const DeliveryMethodSelect = (p) => {
    var _a;
    const workflow = p.workflow;
    const method = p.method;
    const isSelected = ((_a = p.methodSelected) === null || _a === void 0 ? void 0 : _a.guid) == method.guid;
    const typeInfo = getDeliveryMethodInfo(method.type);
    const ComponentSelect = typeInfo.ComponentSelect;
    return (_jsxs(Root, Object.assign({ onClick: () => p.methodSelectedSet(method) }, { children: [_jsx(RootCheckbox, { children: _jsx(Checkbox, { checked: isSelected }, void 0) }, void 0), _jsx(RootContent, { children: _jsx(ComponentSelect, { workflow: workflow, method: method }, void 0) }, void 0)] }), void 0));
};
