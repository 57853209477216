import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { DotStep } from "@/src/common/components/dot-stepper/DotStep";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		//gap: 4px;
	}
`;
export const DotStepper = (p) => {
    const stepList = Array.from({ length: p.steps }, (v, i) => i);
    return (_jsx(Root, { children: stepList.map((n) => {
            const isActive = n == p.step;
            return (_jsx(DotStep
            //
            , { isActive: isActive, onClick: () => p.onStep(n) }, "" + n));
        }) }, void 0));
};
