import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { useJunctionColorInfo } from "@/src/modules/junction/type/JunctionColor";
import { useJunctionLinkInfo } from "@/src/modules/junction/type/JunctionLink";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		border-radius: 4px;
	}

	&.highlight {
		background: ${(p) => p.$info.Background};
		border: 1px solid ${(p) => p.$info.Primary};
	}

	&.standard {
	}

	&.standard + &.standard {
		border-top: 1px solid ${(p) => p.theme.divider};
	}

	&.clickable {
		cursor: pointer;
	}

	&.clickable:hover {
		//	TODO: reidenzon - Something interesting!
	}
`;
const RootButton = styled.div `
	& {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		padding: 8px;
	}
`;
const RootLeft = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;
const RootIcon = styled.div `
	& {
		border-radius: 4px;
		font-size: 18px;
		padding: 4px;
	}

	${Root}.highlight && {
		color: ${(p) => p.$info.Primary};
	}

	${Root}.standard && {
		background: ${(p) => p.$info.Background};
	}
`;
const RootContent = styled.div `
	& {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 0.25rem;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Body};
		font-weight: bold;
	}
`;
const RootDescription = styled.div `
	& {
		${TypographyV3.Body};
		white-space: pre-wrap;
	}
`;
const RootRight = styled.div `
	& {
		align-items: center;
		display: flex;
		justify-content: center;
	}

	${Root}.standard && {
		color: ${(p) => p.theme.icon};
	}

	${Root}.highlight && {
		color: ${(p) => p.theme.icon};
	}
`;
export const JunctionNextStepsItem = (p) => {
    var _a;
    const bc = useContext(BrandContext);
    const item = p.item;
    const linkInfo = useJunctionLinkInfo(item.link);
    const colorInfo = useJunctionColorInfo(item.color);
    const isHighlight = item.highlighted;
    const isStandard = !isHighlight;
    const isClickable = Boolean(item.link);
    return (_jsxs(_Fragment, { children: [_jsx(Root
            //
            , Object.assign({ 
                //
                className: `JunctionNextStepsItem ${isHighlight && "highlight"} ${isStandard && "standard"} ${isClickable && "clickable"}`, "$info": colorInfo, onClick: linkInfo.onClick }, { children: _jsxs(RootButton, { children: [_jsx(RootLeft, { children: _jsx(RootIcon, Object.assign({ "$info": colorInfo }, { children: _jsx(Symbol, { icon: item.icon }, void 0) }), void 0) }, void 0), _jsxs(RootContent, { children: [_jsx(RootTitle, { children: (_a = item.link) === null || _a === void 0 ? void 0 : _a.title }, void 0), _jsx(RootDescription, { children: item.description }, void 0)] }, void 0), item.link && (_jsx(RootRight, Object.assign({ "$info": colorInfo }, { children: _jsx(Symbol, { icon: linkInfo.icon }, void 0) }), void 0))] }, void 0) }), void 0), linkInfo.outlet] }, void 0));
};
