import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
const LoadingSpinnerContainer = styled.div `
	display: flex;
	flex-direction: column;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex: 1;
`;
LoadingSpinnerContainer.displayName = "LoadingSpinnerContainer";
/**
 * NOTE: in order for LoaderSpinnerCentered to work as expected, it must
 * be the child of a component that has the css values:
 * 	...
 * 	display: flex;
 * 	flex-direction: column;
 * 	...
 *
 * This will ensure the spinner is centered.
 */
export const LoaderSpinnerCentered = () => {
    return (_jsx(LoadingSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0));
};
