import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { UserPasswordYup } from "@/src/common/utility/password/UserPassword";
import { FormId } from "@/src/enums/forms";
import { Form } from "@/src/features/common";
import { PasswordStrengthIndicator } from "@/src/features/common/PasswordStrengthIndicator";
import { t } from "@/src/features/Localization";
import FormikErrorFocus from "@/src/features/utils/Formik/FormikErrorFocus";
import { FormikSelect } from "@/src/features/utils/Formik/FormikSelect";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { useGroupViewListRegisterQuery } from "@/src/modules/group/redux/ApiCoreGroup";
import { FormikProvider, useFormik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	width: 500px;
	max-width: 100%;
`;
const RootFooter = styled.div `
	& {
		margin-top: 12px;
	}
`;
export const RegisterAccountForm = (p) => {
    var _a, _b, _c, _d, _e, _f;
    const apiList = useGroupViewListRegisterQuery({
        pharmacy: p.state.groupPharmacy,
    });
    const provinceList = ((_b = (_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.provinceList) !== null && _b !== void 0 ? _b : []);
    const provinceDefault = (_c = apiList.data) === null || _c === void 0 ? void 0 : _c.data.provinceDefault;
    const pharmacy = (_d = apiList.data) === null || _d === void 0 ? void 0 : _d.data.pharmacy;
    const isPharmacy = Boolean(pharmacy);
    const RegisterValidationSchema = Yup.object().shape({
        email: Yup.string().email(t("common_emailInput_error_invalidFormat")).required(t("common_emailInput_error_invalidFormat")),
        password: UserPasswordYup(),
        groupProvince: Yup.string()
            // .min(1, t("pages.registerAccount.formFields.group.invalidFormatError"))
            .min(1, t("common_provinceInput_error_empty"))
            .required(t("common_provinceInput_error_empty")),
    });
    const formik = useFormik({
        initialValues: Object.assign(Object.assign({}, p.state), { groupProvince: provinceDefault || p.state.groupProvince || "", groupPharmacy: (_e = pharmacy === null || pharmacy === void 0 ? void 0 : pharmacy.guid) !== null && _e !== void 0 ? _e : "", groupPharmacyName: (_f = pharmacy === null || pharmacy === void 0 ? void 0 : pharmacy.name) !== null && _f !== void 0 ? _f : "" }),
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: RegisterValidationSchema,
        onSubmit: (s) => {
            p.onSubmit(s);
        },
    });
    return (_jsx(Root, { children: _jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.RegisterAccount }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "email", "data-cy": "register-account-email", label: t("signUp_email_step1_inputLabel_email"), inputProps: {
                            autoComplete: "username",
                        } }, void 0), _jsx(FormikTextField, { fullWidth: true, formikId: "password", type: FormikTextFieldTypes.Password, "data-cy": "register-account-password", label: t("signUp_email_step1_inputLabel_password"), inputProps: {
                            autoComplete: "new-password",
                        } }, void 0), _jsx(PasswordStrengthIndicator, { currentPassword: formik.values.password }, void 0), _jsx(FormikSelect, { disabled: isPharmacy, isLoading: apiList.isLoading, formikId: "groupProvince", label: t("signUp_email_step1_inputLabel_province"), onChange: () => {
                            return null;
                        }, options: provinceList && (provinceList === null || provinceList === void 0 ? void 0 : provinceList.length) > 0
                            ? provinceList === null || provinceList === void 0 ? void 0 : provinceList.map((item) => ({
                                value: item.guid,
                                label: item.name,
                            }))
                            : [] }, void 0), isPharmacy && (_jsx(FormikTextField
                    //
                    , { 
                        //
                        disabled: true, fullWidth: true, formikId: "groupPharmacyName", label: "Pharmacy" }, void 0)), _jsx(RootFooter, { children: _jsx(ButtonStandard
                        //
                        , { 
                            //
                            style: ButtonStyle.SolidPrimary, content: "Next", isForward: true, isSubmit: true, isWide: true }, void 0) }, void 0), _jsx(FormikErrorFocus, {}, void 0)] }), void 0) }), void 0) }, void 0));
};
