import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Footer } from "@/src/features/common/Footer";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { Nav } from "@/src/features/Nav/Nav";
import { PatientSelect, PatientSelectFormId } from "@/src/features/PatientSelect/PatientSelect";
import { useCustomHistory } from "@/src/hooks/useCustomHistory";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
export var ProfilesPageUrlQueryKey;
(function (ProfilesPageUrlQueryKey) {
    ProfilesPageUrlQueryKey["AddProfile"] = "addProfile";
    ProfilesPageUrlQueryKey["AddProfileSuccessRedirect"] = "apsr";
})(ProfilesPageUrlQueryKey || (ProfilesPageUrlQueryKey = {}));
export const ProfilesPage = () => {
    const history = useHistory();
    const { query } = useQuery();
    const { locationStateFrom } = useCustomHistory();
    const segment = useSegmentAnalytics();
    const isAddForm = (query === null || query === void 0 ? void 0 : query.get(ProfilesPageUrlQueryKey.AddProfile)) || false;
    const addSuccessUrl = (query === null || query === void 0 ? void 0 : query.get(ProfilesPageUrlQueryKey.AddProfileSuccessRedirect)) || undefined;
    const onDependentProfileClick = (args) => {
        segment.segmentTrackEvent("select_patient_profile", {
            entrypoint: SegmentEntryPoint.PatientProfilesPage,
            patient_id: args.userGuid,
        });
        history.push(routes.Profile.toPath({ guid: args.userGuid }));
    };
    function handleGoBack() {
        if (isAddForm) {
            segment.segmentTrackEvent("select_back", {
                entrypoint: SegmentEntryPoint.AddPatientFlow,
            });
            history.push(locationStateFrom);
        }
        else {
            history.push(routes.Home.toPath());
        }
    }
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { 
                //
                dataLeft: { onClick: handleGoBack, leftItemType: "Back" }, dataRight: { rightItemType: "PhoneStartChat" } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, Object.assign({ isLargeVerticalPadding: true }, { children: _jsx(PatientSelect, { addSuccessUrl: addSuccessUrl, isAddPatient: true, onDependentProfileClick: onDependentProfileClick, description: "Add a patient you manage medications for and get free delivery anywhere in Canada.", title: t("patientProfiles_patientManagement_header") }, void 0) }), void 0) }, void 0), isAddForm && (_jsx(Footer, { children: _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, form: PatientSelectFormId, content: "Confirm", isSubmit: true, isWide: true }, void 0) }, void 0))] }, void 0));
};
