import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from "@/src/hooks/useAuth";
import { TopicItem } from "@/src/modules/workflow/layout/chat/TopicItem";
import { useWorkflowChatTopicQuery } from "@/src/modules/workflow/redux/ApiCoreWorkflow";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
export const TopicList = (p) => {
    var _a, _b;
    const auth = useAuth();
    const user = auth.user;
    const apiList = useWorkflowChatTopicQuery({ userFor: user.guid });
    const topicList = ((_b = (_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.topics) !== null && _b !== void 0 ? _b : []);
    return (_jsx(Root, { children: topicList.map((topic) => {
            return (_jsx(TopicItem
            //
            , { onAdd: p.onAdd, topic: topic }, topic));
        }) }, void 0));
};
