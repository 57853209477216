import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
const spinnerHeight = 40;
const LoadingSpinnerContainer = styled.div `
	width: 100%;
	margin-top: calc(50vh - ${spinnerHeight / 2}px);
	text-align: center;
`;
export const LoadingPageFiller = () => {
    return (_jsx(LoadingSpinnerContainer, { children: _jsx(LoaderSpinner, {}, void 0) }, void 0));
};
