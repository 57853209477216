import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.footer `
	& {
		${TypographyV3.Button};
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 12px;
	}
`;
export const ModalFooterColumn = (p) => {
    return _jsx(Root, { children: p.children }, void 0);
};
