import { jsx as _jsx } from "react/jsx-runtime";
import { ButtonStyle, getButtonStyleInfo } from "@/src/common/components/button/ButtonStyle";
import { t } from "@/src/features/Localization";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import styled from "styled-components";
const Root = styled.a `
	& {
		white-space: nowrap;
	}

	${(p) => p.$info.Css};
`;
export const ButtonTelephone = (p) => {
    const segment = useSegmentAnalytics();
    const styleInfo = getButtonStyleInfo(ButtonStyle.TextAnchor);
    const telephone = t("chat_topics_footer_phoneNumber");
    const handleClick = () => {
        segment.segmentTrackEvent("select_contact", {
            entrypoint: p.segmentEntrypoint,
        });
    };
    return (_jsx(Root, Object.assign({ href: `tel:${telephone}`, "$info": styleInfo, onClick: handleClick }, { children: telephone }), void 0));
};
