import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { Nav } from "@/src/features/Nav/Nav";
import { PatientSelect } from "@/src/features/PatientSelect/PatientSelect";
import { useCreateThenRedirectToNewWorkflow } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflow";
import { useAuth } from "@/src/hooks/useAuth";
import { WorkflowTypeRxFillV3 } from "@/src/modules/workflow/type/rx-fill-v3/Type";
import { WorkflowTypeRxRefillV3 } from "@/src/modules/workflow/type/rx-refill-v3/Type";
import { WorkflowTypeTransferV1 } from "@/src/modules/workflow/type/transfer-v1/Type";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
export const LayoutWorkflowAdd = () => {
    const auth = useAuth();
    const history = useHistory();
    const { type: workflowType } = useParams();
    const segment = useSegmentAnalytics();
    const { query } = useQuery();
    const userFor = query.get("userFor");
    const rxNumberList = query.get("rxNumberList"); // rxNumberList=RxNumber|RxNumber|RxNumber
    const workflowAddPush = useCreateThenRedirectToNewWorkflow({
        workflowType: workflowType,
        rxNumberList: rxNumberList,
    });
    const workflowAddReplace = useCreateThenRedirectToNewWorkflow({
        workflowType: workflowType,
        rxNumberList: rxNumberList,
        isReplaceBrowserHistory: true,
    });
    const isAddPatient = ![WorkflowTypeRxRefillV3].includes(workflowType);
    const isRefillCount = [WorkflowTypeRxRefillV3].includes(workflowType);
    function handleGoBack() {
        history.push(routes.Home.toPath());
    }
    const onDependentProfileClick = (args) => {
        const segmentEventName = getSegmentEventName(workflowType);
        if (segmentEventName) {
            segment.segmentTrackEvent(segmentEventName, {
                entrypoint: SegmentEntryPoint.PatientProfilesPage,
                patient_id: args.userGuid,
            });
        }
        workflowAddPush.createThenRedirectToNewWorkflow(args.userGuid);
    };
    useEffect(() => {
        if (!auth.isUserInRelationshipWithDependents) {
            workflowAddReplace.createThenRedirectToNewWorkflow();
        }
        else if (userFor) {
            workflowAddReplace.createThenRedirectToNewWorkflow(userFor);
        }
    }, [auth.isUserInRelationshipWithDependents, userFor]);
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: { onClick: handleGoBack, leftItemType: "Back" } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, Object.assign({ isLargeVerticalPadding: true }, { children: auth.isUserInRelationshipWithDependents && (_jsx(PatientSelect, { addSuccessUrl: routes.WorkflowAdd.toPath({ type: workflowType }), isAddPatient: isAddPatient, isRxCount: isRefillCount, onDependentProfileClick: onDependentProfileClick, segmentNewProfileEntryPoint: getSegmentNewProfileEntryPoint(workflowType), title: getTitle(workflowType) }, void 0)) }), void 0) }, void 0)] }, void 0));
};
/**
 * @deprecated TODO: reidenzon - Move to WorkflowTypeInfo instead!
 */
const getSegmentEventName = (workflowType) => {
    switch (workflowType) {
        case WorkflowTypeRxFillV3:
            return "select_new_rx";
        case WorkflowTypeRxRefillV3:
            return "select_refill";
        case WorkflowTypeTransferV1:
            return "select_transfer";
    }
    return undefined;
};
/**
 * @deprecated TODO: reidenzon - Move to WorkflowTypeInfo instead!
 */
const getSegmentNewProfileEntryPoint = (workflowType) => {
    switch (workflowType) {
        case WorkflowTypeRxFillV3:
            return SegmentEntryPoint.NewRx;
        case WorkflowTypeTransferV1:
            return SegmentEntryPoint.Transfer;
    }
    return undefined;
};
/**
 * @deprecated TODO: reidenzon - Move to WorkflowTypeInfo instead!
 */
const getTitle = (workflowType) => {
    switch (workflowType) {
        case WorkflowTypeRxFillV3:
            return t("workflowNew_fill_header");
        case WorkflowTypeRxRefillV3:
            return t("workflowNew_refill_header");
        case WorkflowTypeTransferV1:
            return t("workflowNew_transfer_header");
    }
    return "";
};
