import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ModalFooterRowCenter } from "@/src/common/components/modal/ModalFooterRowCenter";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { t } from "@/src/features/Localization";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
const BoxStyled = styled(Box) `
	position: absolute;
	width: 327px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: ${(p) => p.theme.cardBackground};
	border-radius: 7px;
`;
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
export default function MFBButtonCancelModal({ isOpen, handleClose, handleSubmit }) {
    return (_jsx(Modal, Object.assign({ open: isOpen, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, { children: _jsx(BoxStyled, { children: _jsxs(Root, { children: [_jsx(ModalHeader
                    //
                    , { 
                        //
                        title: t("common_mfbInput_dialog_remove_header"), description: t("common_mfbInput_dialog_remove_content") }, void 0), _jsxs(ModalFooterRowCenter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, onClick: handleClose, content: t("common_mfbInput_dialog_remove_button_no") }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, onClick: handleSubmit, content: t("common_mfbInput_dialog_remove_button_yes") }, void 0)] }, void 0)] }, void 0) }, void 0) }), void 0));
}
