import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { Modal } from "@/src/common/components/modal/Modal";
const Root = styled.div `
	& {
	}
`;
export const ModalList = (p) => {
    return (_jsx(Root, { children: p.modalList.map((m) => {
            return _jsx(Modal, { modal: m, onClose: () => p.onClose(m.guid) }, m.guid);
        }) }, void 0));
};
