import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldError } from "@/src/common/components/field/FieldError";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import { DeliveryMethodSelect } from "@/src/modules/devliery-method/components/select/DeliveryMethodSelect";
import { useEffect } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`;
export const DeliveryMethodSelectList = (p) => {
    var _a;
    const workflow = p.workflow;
    const methodList = (_a = p.methodList) !== null && _a !== void 0 ? _a : [];
    const isEmpty = methodList.length == 0;
    useEffect(() => {
        if (methodList.length >= 1) {
            p.methodSelectedSet(methodList[0]);
        }
    }, [methodList]);
    return (_jsxs(Root, { children: [methodList.map((m) => {
                return (_jsx(DeliveryMethodSelect
                //
                , { workflow: workflow, method: m, methodSelected: p.methodSelected, methodSelectedSet: p.methodSelectedSet }, m.guid));
            }), isEmpty && (_jsxs("div", { children: [_jsx(FieldError, { error: "We don't currently deliver to this address." }, void 0), _jsx(AssistanceLink, {}, void 0)] }, void 0))] }, void 0));
};
