import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
/**
 * PageContainer will automatically ensure the content is within
 * a wrapper with the default defined max width and centering this
 * wrapper on the page.
 */
export const PageContainerWithStyle = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	position: relative;
	background: ${(p) => { var _a; return (_a = p.$background) !== null && _a !== void 0 ? _a : p.theme.cardBackground; }};
`;
export const PageContainer = (p) => {
    return (_jsx(PageContainerWithStyle, Object.assign({ className: p.className, "$background": p.background }, { children: p.children }), void 0));
};
