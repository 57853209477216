import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import { useAuth } from "@/src/hooks/useAuth";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { GroupType } from "@/src/modules/group/type/GroupType";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	display: flex;
	height: 108px;
`;
const RootContent = styled.div `
	display: flex;
	width: 319px;
	background-color: ${(p) => p.theme.invert};
	border-bottom-right-radius: 54px;
`;
const RootLogo = styled.div `
	margin-left: 32px;
	margin-top: 35px;
	margin-bottom: 35px;
`;
const RootLabels = styled.div `
	margin-left: 17px;
	margin-top: 35px;
	margin-bottom: 35px;
`;
const RootName = styled.div `
	${TypographyV3.Header3};
`;
const RootProvince = styled.div `
	${TypographyV3.Body};
`;
const DrawerHeaderCancel = styled.button `
	margin: 28px 20px;
`;
export const NavDrawerHeader = ({ handleNavDrawerClose }) => {
    var _a, _b, _c;
    const bc = useContext(BrandContext);
    const { user } = useAuth();
    const firstName = (_a = user === null || user === void 0 ? void 0 : user.details) === null || _a === void 0 ? void 0 : _a.nameFirst;
    const groupRoles = user === null || user === void 0 ? void 0 : user.groupRoles;
    const userGroupProvinces = groupRoles === null || groupRoles === void 0 ? void 0 : groupRoles.reduce((acc, cv) => {
        const groupAncestors = cv.groupAncestors;
        const groupProvinces = groupAncestors.filter((groupAncestor) => groupAncestor.type === GroupType.Province);
        return [...acc, ...groupProvinces];
    }, []);
    const provinceName = (_c = (_b = userGroupProvinces === null || userGroupProvinces === void 0 ? void 0 : userGroupProvinces[0]) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.name;
    return (_jsxs(Root, { children: [_jsxs(RootContent, { children: [_jsx(RootLogo, { children: _jsx(Icons.MednowIcon, { size: 32 }, void 0) }, void 0), _jsxs(RootLabels, { children: [_jsx(RootName, { children: firstName }, void 0), _jsx(RootProvince, { children: provinceName }, void 0)] }, void 0)] }, void 0), _jsx("div", { children: _jsx(DrawerHeaderCancel, Object.assign({ onClick: handleNavDrawerClose }, { children: _jsx(Icons.CancelProfile, { size: 16, color: bc.Colors.invert }, void 0) }), void 0) }, void 0)] }, void 0));
};
