import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: block;
	}
	& svg {
		display: block;
	}
`;
export const WorkflowStepIcon = (p) => {
    const stateInfo = p.state;
    const Icon = stateInfo.Icon;
    return (_jsx(Root, Object.assign({ stateInfo: stateInfo }, { children: _jsx(Icon, { size: 24 }, void 0) }), void 0));
};
