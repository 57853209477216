import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StreamContext } from "@/src/features/Chat/StreamProvider";
import { LoadingPageFiller } from "@/src/features/common/LoadingPageFiller";
import { ChatterActive } from "@/src/modules/workflow/layout/chat/chatter/ChatterActive";
import { ChatterHistory } from "@/src/modules/workflow/layout/chat/chatter/ChatterHistory";
import { ChatterTopicList } from "@/src/modules/workflow/layout/chat/chatter/ChatterTopicList";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex: 1;
		flex-direction: column;
		gap: 4px;
	}
`;
export const Chatter = (p) => {
    const sc = useContext(StreamContext);
    return (_jsxs(Root, Object.assign({ className: "Chatter" }, { children: [_jsx(ChatterHistory, {}, void 0), sc.isLoading && _jsx(LoadingPageFiller, {}, void 0), !sc.isLoading && !sc.isActive && _jsx(ChatterTopicList, {}, void 0), !sc.isLoading && sc.isActive && _jsx(ChatterActive, {}, void 0)] }), void 0));
};
