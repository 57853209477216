import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdvisoryListItem } from "@/src/common/components/advistory/AdvisoryListItem";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.FieldTitle};
	}
`;
export const AdvisoryList = (p) => {
    return (_jsxs(Root, { children: [p.title && _jsx(RootTitle, { children: p.title }, void 0), p.items.map((item) => {
                return _jsx(AdvisoryListItem, { instruction: item }, item.title);
            })] }, void 0));
};
