import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { UberAvailabilityGeneral } from "@/src/modules/devliery-method/type/uber/components/UberAvailabilityGeneral";
import { SummaryItem } from "@/src/modules/workflow/layout/workflow/summary/SummaryItem";
import { SummaryItemSimple } from "@/src/modules/workflow/layout/workflow/summary/SummaryItemSimple";
import { getDeliveryScheduleString } from "@/src/modules/workflow/type/WorkflowDeliverySchedule";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const DeliveryMethodDetails = (p) => {
    const w = p.workflow;
    const wd = w.details;
    const m = p.method;
    const md = m.details;
    const isScheduled = Boolean(wd.deliverySchedule);
    return (_jsxs(_Fragment, { children: [!isScheduled && (_jsxs(_Fragment, { children: [_jsx(SummaryItem, Object.assign({ title: "Delivery" }, { children: "Uber (to be scheduled)" }), void 0), _jsx(SummaryItemSimple, { children: _jsx(UberAvailabilityGeneral, { workflow: w }, void 0) }, void 0)] }, void 0)), isScheduled && (_jsxs(_Fragment, { children: [_jsx(SummaryItem, Object.assign({ title: "Delivery" }, { children: "Uber (scheduled)" }), void 0), _jsx(SummaryItem, Object.assign({ title: "Schedule" }, { children: getDeliveryScheduleString(wd.deliverySchedule) }), void 0)] }, void 0))] }, void 0));
};
