var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { Field } from "@/src/common/components/field/Field";
import { FieldList } from "@/src/common/components/field/FieldList";
import { FormikInputPrice } from "@/src/common/components/formik/field/FormikInputPrice";
import { ModalFooterRowCenter } from "@/src/common/components/modal/ModalFooterRowCenter";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { ModalContext } from "@/src/common/components/modal/ModalProvider";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { FormikProvider, useFormik } from "formik";
import { useContext } from "react";
import styled from "styled-components";
import { number, object } from "yup";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
const RootRow = styled.div `
	& {
		align-items: start;
		display: flex;
		flex: 1;
		flex-direction: row;
		justify-content: space-between;
		gap: 12px;
	}
`;
export const WorkflowModalTip = (p) => {
    const state = p.state;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: state,
        validateOnBlur: true,
        validationSchema: object().shape({
            price: number()
                .min(0, "Tip can not be negative")
                .max(10, "Tip can not exceed $10.")
                .test("1", "Tip must be 0 or at least 50 cents.", (v) => {
                const is = v > 0 && v < 0.5;
                return !is;
            }),
        }),
        onSubmit: (s) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            (_a = p.onConfirm) === null || _a === void 0 ? void 0 : _a.call(p, {
                price: formik.values.price,
            });
        }),
    });
    const handlePrice = (price) => {
        formik.setFieldValue("price", price);
    };
    return (_jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsxs(Root, { children: [_jsx(ModalHeader
                //
                , { 
                    //
                    onClose: p.onClose, title: "Edit tip amount.", description: "100% of your tip goes to your courier." }, void 0), _jsxs(FieldList, { children: [_jsxs(RootRow, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, content: "$0", onClick: () => handlePrice(0) }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "$1", onClick: () => handlePrice(1) }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "$2", onClick: () => handlePrice(2) }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "$3", onClick: () => handlePrice(3) }, void 0)] }, void 0), _jsx(Field, Object.assign({ title: "Tip" }, { children: _jsx(FormikInputPrice, { isFocus: true, name: "price" }, void 0) }), void 0)] }, void 0), _jsxs(ModalFooterRowCenter, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidGhost, content: "Cancel", onClick: p.onClose }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, content: "Update", onClick: () => formik.handleSubmit() }, void 0)] }, void 0)] }, void 0) }), void 0));
};
export const useWorkflowModalTip = () => {
    const mc = useContext(ModalContext);
    return {
        doModal: (p) => {
            mc.AddModal({
                guid: generateGuid(),
                content: (m) => {
                    return (_jsx(WorkflowModalTip, { onClose: () => {
                            m.onClose();
                        }, onConfirm: (s) => {
                            var _a;
                            (_a = p.onConfirm) === null || _a === void 0 ? void 0 : _a.call(p, s);
                            m.onClose();
                        }, state: p.state }, void 0));
                },
            });
        },
    };
};
