import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SSOButtonApple } from "@/src/features/Login/SSOButtonApple";
import { SSOButtonGoogle } from "@/src/features/Login/SSOButtonGoogle";
import styled from "styled-components";
const Root = styled.div `
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
`;
export function SSOSignInWithButtons(p) {
    return (_jsxs(Root, { children: [_jsx(SSOButtonGoogle, { isLogin: p.isLogin, isRegister: p.isRegister }, void 0), _jsx(SSOButtonApple, { isLogin: p.isLogin, isRegister: p.isRegister }, void 0)] }, void 0));
}
