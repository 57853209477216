import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import { Spacer } from "@/src/common/components/layout/Spacer";
import { getNotificationCategoryInfo } from "@/src/modules/notification/type/NotificationCategory";
import { NotificationMethod } from "@/src/modules/notification/type/NotificationMethod";
import { NotificationToggle } from "@/src/modules/user/layout/notification-settings/NotificationToggle";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
`;
const RootToggle = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 4px;
	}
`;
export const Notification = (p) => {
    const info = getNotificationCategoryInfo(p.category);
    return (_jsxs(Root, { children: [_jsx(FieldHeader, { title: info.Title, description: info.Description }, void 0), _jsxs(RootToggle, { children: [_jsx(Spacer, {}, void 0), [
                        //
                        NotificationMethod.Email,
                        NotificationMethod.Sms,
                        NotificationMethod.Push,
                    ].map((m) => {
                        return _jsx(NotificationToggle, { category: p.category, method: m, notifications: p.notifications }, m);
                    })] }, void 0)] }, void 0));
};
