import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { t } from "@/src/features/Localization";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Hint};
		display: flex;
		gap: 4px;
		margin-left: 6px;
	}
`;
const RootLabel = styled.div `
	//font-weight: 600;
	text-align: center;
	letter-spacing: 0.01em;
`;
const RootStatus = styled.div `
	color: ${(p) => { var _a; return (_a = p.color) !== null && _a !== void 0 ? _a : p.theme.textHint; }};
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.01em;
`;
/*
There shall be a visual indicator for the “strength” of the password.

Strength of the password can be strong, medium or weak
Strong == has at least one lowercase letter (a-z), one uppercase letter (A-Z), one digit (0-9), one special character (!@#$%^&*()), and is at least 8 characters long. (5/5 reqs met)
Medium == If the password meets 4/5 of the above requirements
Weak == If the password entered does not meet the strong or medium-level requirements, then it is deemed weak.

*/
export const PasswordStrengthIndicator = ({ currentPassword }) => {
    const bc = useContext(BrandContext);
    if (!currentPassword) {
        return _jsx("div", {}, void 0);
    }
    let passwordStrength;
    let counter = 0;
    const lowerCaseCharacterRegex = /^(?=.*[a-z])/;
    const meetsLowerCaseCriteria = lowerCaseCharacterRegex.test(currentPassword);
    if (meetsLowerCaseCriteria) {
        counter += 1;
    }
    const upperCaseCharacterRegex = /^(?=.*[A-Z])/;
    const meetsUpperCaseCriteria = upperCaseCharacterRegex.test(currentPassword);
    if (meetsUpperCaseCriteria) {
        counter += 1;
    }
    const numberRegex = /^(?=.*[0-9])/;
    const meetsNumberCriteria = numberRegex.test(currentPassword);
    if (meetsNumberCriteria) {
        counter += 1;
    }
    const specialCharacterRegex = /^(?=.*[^A-Za-z0-9])/;
    const meetsSpecialCharacterCriteria = specialCharacterRegex.test(currentPassword);
    if (meetsSpecialCharacterCriteria) {
        counter += 1;
    }
    const currentPasswordLength = currentPassword.length;
    const meetsMinimumLengthCriteria = currentPasswordLength >= 8;
    if (meetsMinimumLengthCriteria) {
        counter += 1;
    }
    return (_jsxs(Root, { children: [_jsx(RootLabel, { children: t("common_passwordInput_strength_label") }, void 0), counter == 5 && _jsx(RootStatus, Object.assign({ color: bc.Colors.statusSuccess }, { children: t("common_passwordInput_strength_strong") }), void 0), counter == 4 && _jsx(RootStatus, Object.assign({ color: bc.Colors.statusWarning }, { children: t("common_passwordInput_strength_medium") }), void 0), counter <= 3 && _jsx(RootStatus, Object.assign({ color: bc.Colors.statusError }, { children: t("common_passwordInput_strength_weak") }), void 0)] }, void 0));
};
