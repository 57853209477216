import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		position: relative;
	}
`;
const RootImage = styled.img `
	& {
		border-radius: 4px;
		height: 96px;
		width: 96px;
	}
`;
export const SummaryImage = (p) => {
    return (_jsx(Root, { children: _jsx(RootImage, { src: p.file.url }, void 0) }, void 0));
};
