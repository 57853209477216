import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Elevations } from "@/src/enums/elevations";
import { JunctionNextStepsList } from "@/src/modules/junction/components/home/JunctionNextStepsList";
import { useJunctionNextStepsQuery } from "@/src/modules/junction/redux/ApiJunction";
import { useApiJunctionRequest } from "@/src/modules/junction/redux/ApiJunctionRequest";
import styled from "styled-components";
const Root = styled.div `
	& {
		// background: ${(p) => p.theme.cardBackground};
		//border-radius: 4px;
		// box-shadow: ${Elevations.FeatureCard};
		display: flex;
		flex-direction: column;
		gap: 8px;
		//padding: 12px;
	}
`;
const RootHeader = styled.div `
	& {
		${TypographyV3.Header6};
	}
`;
export const JunctionNextSteps = (p) => {
    var _a;
    const junctionRequest = useApiJunctionRequest();
    const apiNextSteps = useJunctionNextStepsQuery(junctionRequest({}));
    const itemList = (((_a = apiNextSteps.data) === null || _a === void 0 ? void 0 : _a.data.items) || []);
    const itemListHighlight = itemList.filter((it) => it.highlighted);
    const itemListStandard = itemList.filter((it) => !it.highlighted);
    if (!itemList.length) {
        return null;
    }
    return (_jsxs(Root, { children: [_jsx(RootHeader, { children: "Next Steps" }, void 0), Boolean(itemListHighlight.length) && _jsx(JunctionNextStepsList, { items: itemListHighlight, isHighlight: true }, void 0), Boolean(itemListStandard.length) && _jsx(JunctionNextStepsList, { items: itemListStandard }, void 0)] }, void 0));
};
