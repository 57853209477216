import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled, { css } from "styled-components";
const RootTitle = styled.div.attrs({ className: "details-content-card-title" }) `
	${TypographyV3.Header6};
`;
const RootBody = styled.div `
	${TypographyV3.Body};
	padding-right: 10px;
`;
const Root = styled.div `
	width: 316px;
	min-height: 40px;
	border: 1px solid ${(p) => p.theme.divider};
	padding: 12px;
	display: flex;
	align-items: center;
	border-bottom: 0px;

	&:first-child {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
	}

	&:last-child {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		border-bottom: ${(p) => (p.$isBorderless ? "none" : `1px solid ${p.theme.divider}`)};
	}

	&:only-child {
	}

	${({ $isRemoveBorderTop }) => $isRemoveBorderTop && "border-top: 0px;"}
	${({ $isBorderless }) => $isBorderless && "border: 0px;"}

	${(p) => p.$isClickable &&
    css `
			&:hover {
				cursor: pointer;
			}

			&:hover .details-content-card-title {
				color: ${p.theme.icon};
			}

			&:hover .content-card-chevron path {
				fill: ${p.theme.icon};
			}
		`}
`;
const RootContent = styled.div `
	flex: 1;
	gap: 4px;
	display: flex;
	flex-direction: column;
`;
const RootIcon = styled.div `
	& {
		color: ${(p) => p.theme.icon};
	}
`;
export const NoPrescriptionTableItem = (p) => {
    const { body, header, onClick, borderless = false } = p;
    const $isClickable = Boolean(onClick);
    return (_jsxs(Root, Object.assign({ "$isTop": true, "$isClickable": $isClickable, "$isBorderless": borderless, onClick: onClick }, { children: [_jsxs(RootContent, { children: [_jsx(RootTitle, { children: header }, void 0), _jsx(RootBody, { children: body }, void 0)] }, void 0), $isClickable && (_jsx(RootIcon, { children: _jsx(Symbol, { icon: "chevron_right" }, void 0) }, void 0))] }), void 0));
};
