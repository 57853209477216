import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { getWorkflowStatusInfo } from "@/src/modules/workflow/type/WorkflowStatus";
import { useWorkflowStatusColorInfo } from "@/src/modules/workflow/type/WorkflowStatusColor";
import { getWorkflowTypeInfo } from "@/src/modules/workflow/type/WorkflowType";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
import styled, { keyframes } from "styled-components";
const Root = styled.button `
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;
	padding: 8px 16px;
	align-items: center;
	border-radius: 4px;
	border: 1px solid ${(p) => { var _a; return (_a = p.$colorBorder) !== null && _a !== void 0 ? _a : p.theme.divider; }};
	gap: 12px;
	background: ${(p) => p.theme.cardBackground};

	&:hover {
		color: ${(p) => { var _a; return (_a = p.$colorBorder) !== null && _a !== void 0 ? _a : p.theme.divider; }};
		background: ${(p) => { var _a; return (_a = p.$colorBackground) !== null && _a !== void 0 ? _a : p.theme.cardBackground; }};
		border: 1px solid ${(p) => { var _a; return (_a = p.$colorBorder) !== null && _a !== void 0 ? _a : p.theme.divider; }};
		opacity: 0.9;
	}
`;
const ContentContainer = styled.div `
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;
const RootDot = styled.div `
	background: ${(p) => { var _a; return (_a = p.$workflowStatusIconColor) !== null && _a !== void 0 ? _a : p.theme.divider; }};
	width: 8px;
	height: 8px;
	border-radius: 50%;
`;
const RootLabel = styled.div `
	${TypographyV3.Body};
	font-weight: 600;
`;
const RootBadge = styled.div `
	background: ${(p) => { var _a; return (_a = p.$colorBackground) !== null && _a !== void 0 ? _a : p.theme.divider; }};
	padding: 2px 8px;
	height: 18px;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const RootBadgeText = styled.div `
	${TypographyV3.Badge};
	color: ${(p) => { var _a; return (_a = p.$colorText) !== null && _a !== void 0 ? _a : p.theme.divider; }};
`;
const animatedArrowKeyframeLeftPosition = "-4px";
const breatheAnimation = keyframes `
	0% {
		transform: translate(0, 0);
	}
	80% {
		transform: translate(0, 0);
	}
	85% {
		transform: translate(${animatedArrowKeyframeLeftPosition}, 0);
	}
	90% {
		transform: translate(0, 0);
	}
	95% {
		transform: translate(${animatedArrowKeyframeLeftPosition}, 0);
	}
	100% {
		transform: translate(0, 0);
	}
`;
const RootIcon = styled.div `
	animation-name: ${breatheAnimation};
	animation-duration: 4s; // 4s hold, 1s move -4px (i.e. to left), 1s move right to 0, 1s move -4px, 1s move back right;
	animation-iteration-count: infinite;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const DynamicActionBar = (p) => {
    var _a, _b;
    const history = useHistory();
    const segment = useSegmentAnalytics();
    const workflow = (_a = p.rxWorkflows) === null || _a === void 0 ? void 0 : _a[0];
    if (!workflow) {
        return _jsx(_Fragment, {}, void 0);
    }
    function handleWorkflowDetailsChange(workflowGuid) {
        segment.segmentTrackEvent("view_order_progress", {
            entrypoint: SegmentEntryPoint.HomePage,
            patient_id: workflow === null || workflow === void 0 ? void 0 : workflow.patient.guid,
        });
        history.push(routes.WorkflowView.toPath({ workflowGuid }));
    }
    const typeInfo = getWorkflowTypeInfo(workflow.type);
    const statusInfo = getWorkflowStatusInfo(workflow.status);
    const statusColorInfo = useWorkflowStatusColorInfo(statusInfo.Color);
    return (_jsxs(Root, Object.assign({ onClick: () => handleWorkflowDetailsChange(workflow.guid), "$colorBorder": statusColorInfo.Border, "$colorBackground": statusColorInfo.Background }, { children: [_jsxs(ContentContainer, { children: [_jsx(RootDot, { "$workflowStatusIconColor": statusColorInfo.Icon }, void 0), _jsx(RootLabel, { children: typeInfo.TextDab }, void 0), _jsx(RootBadge, Object.assign({ "$colorBackground": statusColorInfo.Background }, { children: _jsx(RootBadgeText, Object.assign({ "$colorText": statusColorInfo.Text }, { children: (_b = statusInfo.TextDab) === null || _b === void 0 ? void 0 : _b.call(statusInfo, workflow.details) }), void 0) }), void 0)] }, void 0), _jsx(RootIcon, { children: _jsx(Symbol, { icon: "arrow_forward", color: statusColorInfo.Icon }, void 0) }, void 0)] }), void 0));
};
