import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		${TypographyV3.Body};
		font-weight: 600;
	}
`;
export const InvoiceRowHeader = (p) => {
    return (_jsx(InvoiceRow
    //
    , { 
        //
        left: _jsx(RootContent, { children: "Item" }, void 0), right: _jsx(RootContent, { children: "Price" }, void 0) }, void 0));
};
