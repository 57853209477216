import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Elevations } from "@/src/enums/elevations";
import { Icons } from "@/src/features/common";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { RefillsRemainingType } from "@/src/modules/user/type/pharmacylink/RefillsRemainingType";
import { Warning } from "@mui/icons-material";
import { useContext } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		color: ${(p) => p.theme.badge};
		background: ${(p) => p.theme.badgeBackground};
		border-radius: 1em;
		min-width: 1em;
		padding: 2px 5px;
		position: relative;
	}

	&.progress {
		color: ${(p) => p.theme.disabled};
		cursor: pointer;
		background: ${(p) => p.theme.disabledBackground};
		opacity: 0.5;
	}

	&.progress:hover {
		opacity: 1;
	}
`;
const RootContent = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 4px;
		justify-content: center;
	}

	${Root}.progress & {
	}

	${Root}.progress:hover & {
	}
`;
const RootValue = styled.div `
	& {
		${TypographyV3.Badge};
	}
`;
const RootNote = styled.div `
	& {
		display: none;
		background: ${(p) => p.theme.cardBackground};
		border-radius: 8px;
		box-shadow: ${Elevations.Tight1};
		font-size: 12px;
		margin-right: 24px;
		padding: 12px;
		position: absolute;
		transform: translate(0, -50%);
		top: 50%;
		right: 100%;
		pointer-events: none;
		width: 300px;
		z-index: 1;
	}

	${Root}.doctor:hover &,
	${Root}.progress:hover & {
		display: block;
	}
`;
const RootNoteHeader = styled.div `
	& {
		${TypographyV3.Header6};
		margin-bottom: 4px;
	}
`;
const RootNoteContent = styled.div `
	& {
		${TypographyV3.Body};
	}
`;
export const RxCardCount = (p) => {
    const bc = useContext(BrandContext);
    const rx = p.rx;
    const isUnlimited = rx.refillsRemainingType == RefillsRemainingType.Unlimited;
    const isCount = !isUnlimited;
    const isProgress = !rx.isRefill;
    const isDoctor = !isProgress && rx.refillsRemaining == 0;
    const classList = [];
    if (isProgress)
        classList.push("progress");
    if (isDoctor)
        classList.push("doctor");
    return (_jsxs(Root, Object.assign({ className: classList.join(" ") }, { children: [_jsxs(RootContent, { children: [isProgress && _jsx(Icons.RefillNotAvailable, { color: bc.Colors.statusError, size: 14 }, void 0), isDoctor && _jsx(Warning, { sx: { color: bc.Colors.statusWarning, fontSize: 12 } }, void 0), isUnlimited && _jsx(RootValue, { children: "∞" }, void 0), isCount && _jsx(RootValue, { children: rx.refillsRemaining }, void 0)] }, void 0), isProgress && (_jsxs(RootNote, { children: [_jsx(RootNoteHeader, { children: "You have a refill in progress" }, void 0), _jsx(RootNoteContent, { children: "Your remaining refills will update once your order has been shipped." }, void 0)] }, void 0)), isDoctor && (_jsxs(RootNote, { children: [_jsx(RootNoteHeader, { children: "No refills available" }, void 0), _jsx(RootNoteContent, { children: "We will contact the doctor on your behalf." }, void 0)] }, void 0))] }), void 0));
};
