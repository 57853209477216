import { css } from "styled-components";
export var JunctionPopupSize;
(function (JunctionPopupSize) {
    JunctionPopupSize["Small"] = "small";
    JunctionPopupSize["Medium"] = "medium";
    JunctionPopupSize["Large"] = "large";
})(JunctionPopupSize || (JunctionPopupSize = {}));
const infoMap = new Map();
const infoList = [
    {
        Size: JunctionPopupSize.Small,
        Css: css `
			& {
				height: 40vh;
				min-height: 320px;
				max-height: 90vh;
			}
		`,
    },
    {
        Size: JunctionPopupSize.Medium,
        Css: css `
			& {
				height: 60vh;
				min-height: 480px;
				max-height: 90vh;
			}
		`,
    },
    {
        Size: JunctionPopupSize.Large,
        Css: css `
			& {
				height: 90vh;
				max-height: 90vh;
			}
		`,
    },
];
infoList.forEach((info) => infoMap.set(info.Size, info));
export const getJunctionPopupSizeInfo = (s) => {
    return infoMap.get(s !== null && s !== void 0 ? s : JunctionPopupSize.Medium);
};
