import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ButtonTelephone } from "@/src/common/components/button/ButtonTelephone";
import { GridRow } from "@/src/common/components/grid/GridRow";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { getDeepLinkFromURLSearchParams } from "@/src/features/DeepLink/DeepLinkHelpers";
import { t } from "@/src/features/Localization";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Hint};
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	& b {
		color: ${(p) => p.theme.buttonPrimary};
		font-weight: 500;
	}
`;
export const LoginAlternatives = (p) => {
    const { query } = useQuery();
    const deeplink = getDeepLinkFromURLSearchParams(query);
    const institutionCode = query.get("groupCode") || undefined;
    const pharmacyGuid = query.get("ph") || undefined;
    return (_jsxs(Root, { children: [p.isLogin && (_jsxs(GridRow, { children: [_jsx("span", { children: t("signUp_email_step1_footer_haveAccount") }, void 0), _jsx(ButtonNavigate, { style: ButtonStyle.TextAnchor, pathTo: routes.Login.toPath({
                            deeplink: deeplink,
                        }), content: t("signUp_email_step1_footer_logIn") }, void 0)] }, void 0)), p.isRegister && (_jsxs(GridRow, { children: [_jsx("span", { children: t("login_footer_noAccount") }, void 0), _jsx(ButtonNavigate
                    //
                    , { 
                        //
                        style: ButtonStyle.TextAnchor, pathTo: routes.RegisterAccount.toPath({
                            deeplink: deeplink,
                            groupCode: institutionCode,
                            pharmacyGuid: pharmacyGuid,
                        }), content: t("login_footer_signUp") }, void 0)] }, void 0)), p.isPhone && (_jsxs(GridRow, { children: [_jsx("span", { children: t("login_footer_contact") }, void 0), _jsx(ButtonTelephone, { segmentEntrypoint: p.entrypoint }, void 0)] }, void 0)), p.isFax && (_jsx(GridRow, { children: t("login_footer_fax", {
                    faxNumber: _jsx("b", { children: t("login_footer_fax_faxNumber") }, void 0),
                }) }, void 0))] }, void 0));
};
