import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { withCssBackgroundImagesWithDefaultPageFallback } from "@/src/features/common/HOCs/withCssBackgroundImages/withCssBackgroundImagesWithDefaultPageFallback";
import { PageFader } from "@/src/features/common/Page/PageFader";
import useResizeObserver from "@react-hook/resize-observer";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-grow: 1;
		position: relative;
	}
`;
const Container = styled.div `
	& {
		flex-grow: 1;
		position: relative;
		overflow: auto;
		height: ${({ $isFullHeight }) => ($isFullHeight ? "100%" : "")};
	}
`;
// This will add $backgroundImages prop to add backgrounds images
// to the container.
const ContainerWithBackgroundImages = withCssBackgroundImagesWithDefaultPageFallback(Container);
const Content = styled.div `
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: ${({ $isFullHeight }) => ($isFullHeight ? "100%" : "")};
	//padding-bottom: ${(p) => (p.$isFadeFooter ? "48px" : "")};
`;
export const PageOverflowContainer = (p) => {
    const refContent = useRef();
    const refWrapper = useRef();
    const [opacity, setOpacity] = useState(0);
    const contentHeight = useHeight(refContent);
    const handleScroll = () => {
        const wrapper = refWrapper.current;
        const threshold = 64;
        const distance = wrapper.scrollHeight - wrapper.offsetHeight;
        if (distance <= 0) {
            setOpacity(0);
            return;
        }
        const remainder = distance - wrapper.scrollTop;
        const opacity = Math.min(threshold, remainder) / threshold;
        setOpacity(opacity);
    };
    useEffect(() => {
        handleScroll();
    }, [contentHeight]);
    return (_jsxs(Root, { children: [_jsx(ContainerWithBackgroundImages, Object.assign({ id: p.id, className: `Wrapper PageOverflowContainer ${p.className || ""}`, "$backgroundImages": p.$backgroundImages, "$isFullHeight": p.isFullHeight, onScroll: handleScroll, ref: refWrapper }, { children: _jsx(Content
                //
                , Object.assign({ "$isFadeFooter": p.isFadeFooter, "$isFullHeight": p.isFullHeight, className: `Content PageOverflowContainerContent ${p.contentClassName || ""}`, ref: refContent }, { children: p.children }), void 0) }), void 0), p.isFadeFooter && _jsx(PageFader, { opacity: opacity }, void 0)] }, void 0));
};
// TODO: reidenzon - Move to utilities.
// https://www.npmjs.com/package/@react-hook/resize-observer
const useHeight = (target) => {
    const [height, setHeight] = useState();
    useLayoutEffect(() => {
        setHeight(target.current.offsetHeight);
    }, [target]);
    useResizeObserver(target, (entry) => {
        setHeight(target.current.offsetHeight);
    });
    return height;
};
