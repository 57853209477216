import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getPhoneNumberFormatted } from "@/src/common/utility/phone/PhoneNumber";
import { t } from "@/src/features/Localization";
import { NoPrescriptionTableItem } from "@/src/features/NoPrescription/NoPrescriptionTableItem";
import { useAuth } from "@/src/hooks/useAuth";
import { useVirtualCare } from "@/src/modules/service/layout/virtual-care/useVirtualCare";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { WorkflowTypeTransferV1 } from "@/src/modules/workflow/type/transfer-v1/Type";
import { usePostWorkflowAddMutation, useUserViewSingleCustomerRelationeeQuery } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { parse } from "query-string";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
export var TransferActionLogic;
(function (TransferActionLogic) {
    TransferActionLogic["Transfer"] = "transfer";
})(TransferActionLogic || (TransferActionLogic = {}));
const OrderDetailsContainer = styled.div `
	margin-left: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 327px;
`;
export const NoPrescriptionTable = (p) => {
    var _a;
    const patientGuid = p.patientGuid;
    const { user } = useAuth();
    const { search } = useLocation();
    const segment = useSegmentAnalytics();
    const history = useHistory();
    const virtualCare = useVirtualCare({});
    const [apiWorkflowAdd, { data: workFlowAddResponseData }] = usePostWorkflowAddMutation();
    const apiRelationee = useUserViewSingleCustomerRelationeeQuery({
        userForGuid: patientGuid,
    }, {
        skip: patientGuid == (user === null || user === void 0 ? void 0 : user.guid),
    });
    function handleTransferClick() {
        segment.segmentTrackEvent("select_transfer", {
            entrypoint: p.segmentEntrypoint,
            patient_id: patientGuid,
        });
        apiWorkflowAdd({
            type: WorkflowTypeTransferV1,
            userFor: patientGuid,
        });
    }
    const handleClinicalService = () => {
        history.push(routes.ServiceList.toPath({}));
    };
    useEffect(() => {
        var _a, _b;
        if (workFlowAddResponseData) {
            const query = parse(search);
            const workflowGuid = (_b = (_a = workFlowAddResponseData === null || workFlowAddResponseData === void 0 ? void 0 : workFlowAddResponseData.data) === null || _a === void 0 ? void 0 : _a.workflow) === null || _b === void 0 ? void 0 : _b.guid;
            if ([TransferActionLogic.Transfer].includes(query.actionLogic)) {
                history.replace(routes.WorkflowView.toPath({ workflowGuid }));
            }
            else {
                history.push(routes.WorkflowView.toPath({ workflowGuid }));
            }
        }
    }, [workFlowAddResponseData]);
    const patient = patientGuid == (user === null || user === void 0 ? void 0 : user.guid) ? user : (_a = apiRelationee.data) === null || _a === void 0 ? void 0 : _a.data.user;
    if (!patient) {
        return _jsx(_Fragment, {}, void 0);
    }
    const settingInfo = getSettingDetailsByType(SettingType.GroupPharmacyInfo, patient.settings);
    const settingVirtualCareDoctor = getSettingDetailsByType(SettingType.GroupVirtualCareDoctor, patient.settings);
    function handleVirtualCare() {
        virtualCare({});
    }
    function handleWeCanReachOutYourDoctor() {
        history.push(routes.Chat.toPath());
    }
    return (_jsxs(OrderDetailsContainer, { children: [_jsxs("div", { children: [_jsx(NoPrescriptionTableItem, { header: t("common_prescriptionAlternativesTable_transfer_header"), body: "We can reach out to your current pharmacy and transfer your prescription to Mednow.", onClick: handleTransferClick }, void 0), _jsx(NoPrescriptionTableItem, { header: "We can reach out to your doctor", body: "If you need a prescription for a medication you’re already taking, we can help. Start a chat now.", onClick: handleWeCanReachOutYourDoctor }, void 0), _jsx(NoPrescriptionTableItem, { header: "See a Mednow Clinician", body: "Speak to a Mednow Pharmacist to request a new prescription.", onClick: handleClinicalService }, void 0), false && settingVirtualCareDoctor.isEnabled && (_jsx(NoPrescriptionTableItem, { header: t("common_prescriptionAlternativesTable_seeDoctor_header"), body: "Speak to a Mednow virtual care clinician to request a new prescription.", onClick: handleVirtualCare }, void 0))] }, void 0), _jsx(NoPrescriptionTableItem, { header: t("common_prescriptionAlternativesTable_fax_header"), body: t("common_prescriptionAlternativesTable_fax_content", {
                    faxNumber: _jsx("b", { children: getPhoneNumberFormatted(settingInfo.fax) }, void 0),
                }), borderless: true }, void 0)] }, patientGuid));
};
