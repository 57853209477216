import { WorkflowStatusCategory } from "@/src/modules/workflow/type/WorkflowStatusCategory";
import { WorkflowStatusColor } from "@/src/modules/workflow/type/WorkflowStatusColor";
export const WorkflowStatusServiceMinorAilmentV1Draft = "service-minor-ailment-v1-draft";
export const WorkflowStatusServiceMinorAilmentV1Triage = "service-minor-ailment-v1-triage";
export const WorkflowStatusServiceMinorAilmentV1InProgress = "service-minor-ailment-v1-in-progress";
export const WorkflowStatusServiceMinorAilmentV1Complete = "service-minor-ailment-v1-complete";
export const WorkflowStatusServiceMinorAilmentV1Rejected = "service-minor-ailment-v1-rejected";
export const getStatusInfoList = () => {
    return [
        {
            Status: WorkflowStatusServiceMinorAilmentV1Draft,
            StatusCategory: WorkflowStatusCategory.Draft,
        },
        {
            Status: WorkflowStatusServiceMinorAilmentV1Triage,
            StatusCategory: WorkflowStatusCategory.Triage,
            Color: WorkflowStatusColor.Triage,
            TextCard: (d) => "Request sent",
            TextDab: (d) => "Processing",
        },
        {
            Status: WorkflowStatusServiceMinorAilmentV1InProgress,
            StatusCategory: WorkflowStatusCategory.Progress,
            Color: WorkflowStatusColor.InProgress,
            TextCard: (d) => "In progress",
            TextDab: (d) => "In Progress",
        },
        {
            Status: WorkflowStatusServiceMinorAilmentV1Complete,
            StatusCategory: WorkflowStatusCategory.Closed,
            TextCard: (d) => "Complete",
        },
        {
            Status: WorkflowStatusServiceMinorAilmentV1Rejected,
            StatusCategory: WorkflowStatusCategory.Closed,
            StatusIsAbandoned: true,
            TextCard: (d) => "Incomplete",
        },
    ];
};
