var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useAuth } from "@/src/hooks/useAuth";
import { routes } from "@/src/routes";
import { useHistory } from "react-router-dom";
export const useMedVisit = (p) => {
    const auth = useAuth();
    const user = auth.user;
    const history = useHistory();
    const isDependents = auth.isUserInRelationshipWithDependents;
    return (args) => __awaiter(void 0, void 0, void 0, function* () {
        if (args.isForce || !isDependents) {
            const p = new URLSearchParams();
            window.open("https://mednow.typeform.com/to/DJCGiIVw?" + p.toString(), "_blank");
        }
        else {
            history.push(routes.MedVisit.toPath({}));
        }
    });
};
