import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const SummaryRxUser = (p) => {
    const rx = p.rx;
    const parts = [
        //
        rx.namePrimary,
        rx.quantityDispense,
        (rx.form || "UNIT") + ",",
        rx.nameSecondary,
    ];
    return _jsx(Root, { children: parts.join(" ") }, void 0);
};
