import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Body};
		background: ${(p) => p.$background};
		display: flex;
		flex-direction: column;
		gap: 8px;
		padding: 16px 12px;
	}

	& + & {
		border-top: 1px solid ${(p) => p.theme.divider};
	}

	&:last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`;
const RootHeader = styled.div `
	& {
		display: flex;
		flex-direction: row;
		gap: 8px;
	}
`;
const RootLeft = styled.div `
	& {
		flex: 3;
	}
`;
const RootRight = styled.div `
	& {
		flex: 1;
	}
`;
const RootBody = styled.div `
	& {
	}
`;
export const InvoiceRow = (p) => {
    return (_jsxs(Root, Object.assign({ "$background": p.$background }, { children: [_jsxs(RootHeader, { children: [_jsx(RootLeft, { children: p.left }, void 0), _jsx(RootRight, { children: p.right }, void 0)] }, void 0), p.body && _jsx(RootBody, { children: p.body }, void 0)] }), void 0));
};
