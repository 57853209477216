import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
// TODO: reidenzon - Look into this...
// https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
const Root = styled.div `
	& {
		align-items: end;
		background-image: linear-gradient(rgba(0, 0, 0, 0), ${(p) => p.theme.menuBackground});
		display: flex;
		flex-direction: row;
		justify-content: center;
		height: 64px;
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: ${(p) => p.$opacity};
		z-index: 1;
	}
`;
const RootSymbol = styled.div `
	& {
		${TypographyV3.Button};
		align-items: center;
		color: ${(p) => p.theme.invert};
		display: flex;
		flex-direction: row;
		gap: 4px;
		padding: 12px;
		text-align: center;
		text-transform: uppercase;
		vertical-align: bottom;
	}
`;
export const PageFader = (p) => {
    return (_jsx(Root, Object.assign({ "$opacity": p.opacity }, { children: _jsxs(RootSymbol, { children: [_jsx(Symbol, { icon: "arrow_downward" }, void 0), _jsx(Symbol, { icon: "arrow_downward" }, void 0), _jsx(Symbol, { icon: "arrow_downward" }, void 0), _jsx("span", { children: "Scroll Down" }, void 0), _jsx(Symbol, { icon: "arrow_downward" }, void 0), _jsx(Symbol, { icon: "arrow_downward" }, void 0), _jsx(Symbol, { icon: "arrow_downward" }, void 0)] }, void 0) }), void 0));
};
