import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { ListCTAItemLabel } from "@/src/features/common/ListCTA/ListCTAItemLabel";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { useContext } from "react";
import styled from "styled-components";
/* ------------------------- Variables and Constants ------------------------ */
// This is the affect on button when it is hovered.
const containerHoverOpacity = 0.65;
export const DEFAULT_ICON_RIGHT_SIZE = 16;
const RootIconLeft = styled.span `
	margin-right: 12px;
	width: 24px;
	height: 24px;

	display: flex;
	align-items: center;
	justify-content: center;
`;
const RootTitle = styled.span `
	${TypographyV3.Body};
`;
const ListCTAItemRightContainer = styled.span `
	display: flex;
	flex-direction: row;
	margin-left: auto;
`;
const ListCTAItemRightIcon = styled.span `
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 12px;
`;
const ListCTAItemContentContainer = styled.span `
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
`;
const Root = styled.button `
	display: flex;
	align-items: center;
	height: 56px;
	width: 100%;
	padding: 12px 0;
	border-bottom: 1px solid ${(p) => p.theme.divider};

	&:hover ${ListCTAItemContentContainer} {
		opacity: ${containerHoverOpacity};
	}
`;
export const ListCTAItem = (p) => {
    const bc = useContext(BrandContext);
    const item = p.item;
    const rightIcon = item.rightIcon || _jsx(Symbol, { icon: "chevron_right", color: bc.Colors.icon }, void 0);
    if (!item.isShow) {
        return null;
    }
    // label
    const { text: labelText, style, isLoading: isLoadingLabel } = item.label || {};
    return (_jsx(Root, Object.assign({ type: "button", onClick: item.onClick }, { children: _jsxs(ListCTAItemContentContainer, { children: [_jsx(RootIconLeft, Object.assign({ className: "ListCTAItemLeftIcon" }, { children: item.leftIcon }), void 0), item.text && _jsx(RootTitle, { children: item.text }, void 0), _jsxs(ListCTAItemRightContainer, { children: [_jsx(ListCTAItemLabel, { text: labelText, style: style, isLoading: isLoadingLabel }, void 0), _jsx(ListCTAItemRightIcon, { children: rightIcon }, void 0)] }, void 0)] }, void 0) }), void 0));
};
