import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 32px 0;
	}
`;
export const ChatterHistoryEmpty = (p) => {
    return (_jsx(Root, { children: _jsx("span", { children: "No history." }, void 0) }, void 0));
};
