import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import { useContext } from "react";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		${TypographyV3.Body};
		text-transform: uppercase;
	}
`;
export const InvoiceRowTotal = (p) => {
    const bc = useContext(BrandContext);
    return (_jsx(InvoiceRow
    //
    , { "$background": bc.Colors.badgeBackground, left: _jsx(RootContent, { children: "Total" }, void 0), right: _jsx(RootContent, { children: p.total }, void 0) }, void 0));
};
