import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Body};

		align-items: center;
		background: ${(p) => p.theme.buttonSecondary};
		border-radius: 16px;
		//color: ${(p) => p.theme.invert};
		display: flex;
		font-size: 0.8em;
		padding: 0 0.5em;
	}
`;
export const NavDrawerBadge = (p) => {
    return _jsx(Root, { children: "New Feature!" }, void 0);
};
