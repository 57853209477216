import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { generateGuidShort } from "@/src/common/utility/guid/GUID";
import { Icons } from "@/src/features/common";
import { DEFAULT_ICON_RIGHT_SIZE, ListCTAItem } from "@/src/features/common/ListCTA/ListCTAItem";
import { ListCTAItemLabelStyle } from "@/src/features/common/ListCTA/ListCTAItemLabel";
import { t } from "@/src/features/Localization";
import { useCreateThenRedirectToNewWorkflowRx } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowRx";
import { useCreateThenRedirectToNewWorkflowRxRefill } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowRxRefill";
import { useCreateThenRedirectToNewWorkflowTransfer } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowTransfer";
import { useAuth } from "@/src/hooks/useAuth";
import { BrandContext } from "@/src/modules/brand/context/BrandProvider";
import { JunctionQuickLink } from "@/src/modules/junction/components/home/JunctionQuickLink";
import { useJunctionQuickLinksQuery } from "@/src/modules/junction/redux/ApiJunction";
import { useApiJunctionRequest } from "@/src/modules/junction/redux/ApiJunctionRequest";
import { useVirtualCare } from "@/src/modules/service/layout/virtual-care/useVirtualCare";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { Topic } from "@/src/modules/workflow/type/chat-v1/Topic";
import { WorkflowTypeRxFillV3 } from "@/src/modules/workflow/type/rx-fill-v3/Type";
import { WorkflowTypeRxRefillV3 } from "@/src/modules/workflow/type/rx-refill-v3/Type";
import { WorkflowTypeTransferV1 } from "@/src/modules/workflow/type/transfer-v1/Type";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useContext } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
	}
`;
function getCtaRefillLabelValues({ numMedications, isLoading }) {
    // If the number of medication is undefined
    // then do not return anything for the label.
    if (numMedications === undefined) {
        return undefined;
    }
    let ctaRefillLabelText;
    const ctaRefillLabelColorType = numMedications > 0 ? ListCTAItemLabelStyle.Success : ListCTAItemLabelStyle.Warning;
    if (!isLoading) {
        if (numMedications === 1) {
            ctaRefillLabelText = t("home_cta_refill_badge_single");
        }
        else if (numMedications > 1 || numMedications === 0) {
            ctaRefillLabelText = t("home_cta_refill_badge_plural", {
                numMedications,
            });
        }
    }
    return {
        text: ctaRefillLabelText,
        style: ctaRefillLabelColorType,
        isLoading,
    };
}
export const HomeSecondaryCTAs = (p) => {
    var _a;
    const bc = useContext(BrandContext);
    const auth = useAuth();
    const user = auth.user;
    const junctionRequest = useApiJunctionRequest();
    const apiList = useJunctionQuickLinksQuery(junctionRequest({}));
    const quickList = (((_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.items) || []);
    const history = useHistory();
    const segment = useSegmentAnalytics();
    const newWorkflowRxFill = useCreateThenRedirectToNewWorkflowRx();
    const newWorkflowRxRefill = useCreateThenRedirectToNewWorkflowRxRefill({});
    const newWorkflowTransfer = useCreateThenRedirectToNewWorkflowTransfer();
    const detailsMedVisit = getSettingDetailsByType(SettingType.GroupMedVisit, user === null || user === void 0 ? void 0 : user.settings);
    const detailsServiceClinical = getSettingDetailsByType(SettingType.GroupServiceClinical, user === null || user === void 0 ? void 0 : user.settings);
    const detailsVirtualCare = getSettingDetailsByType(SettingType.GroupVirtualCareDoctor, user === null || user === void 0 ? void 0 : user.settings);
    const virtualCare = useVirtualCare({});
    const itemList = [
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "refresh" }, void 0),
            text: t("home_cta_refill_label"),
            label: getCtaRefillLabelValues({
                numMedications: p.userRxRefillCount,
                isLoading: p.isFetchingUserRxCountRefillQuery,
            }),
            isShow: true,
            onClick: () => {
                segment.segmentTrackEvent("select_refill", {
                    entrypoint: SegmentEntryPoint.HomePageCtaLine,
                });
                if (auth.isUserInRelationshipWithDependents) {
                    history.push(routes.WorkflowAdd.toPath({ type: WorkflowTypeRxRefillV3 }));
                }
                else {
                    newWorkflowRxRefill.createThenRedirectToNewWorkflowRxRefill();
                }
            },
        },
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "photo_camera" }, void 0),
            text: t("home_cta_rx_label"),
            isShow: true,
            onClick: () => {
                segment.segmentTrackEvent("select_new_rx", {
                    entrypoint: SegmentEntryPoint.HomePageCtaLine,
                });
                if (auth.isUserInRelationshipWithDependents) {
                    history.push(routes.WorkflowAdd.toPath({ type: WorkflowTypeRxFillV3 }));
                }
                else {
                    newWorkflowRxFill.createThenRedirectToNewWorkflowRx();
                }
            },
        },
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "medication" }, void 0),
            text: t("home_cta_noRx_label"),
            isShow: true,
            onClick: () => {
                segment.segmentTrackEvent("select_prescription_alternatives", {
                    entrypoint: SegmentEntryPoint.HomePageCtaLine,
                });
                if (auth.isUserInRelationshipWithDependents) {
                    history.push(routes.PrescriptionAlternativesFor.toPath({}));
                }
                else {
                    history.push(routes.PrescriptionAlternatives.toPath({}));
                }
            },
        },
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "south_west" }, void 0),
            text: t("home_cta_transfer_label"),
            isShow: true,
            onClick: () => {
                segment.segmentTrackEvent("select_transfer", {
                    entrypoint: SegmentEntryPoint.HomePageCtaLine,
                });
                if (auth.isUserInRelationshipWithDependents) {
                    history.push(routes.WorkflowAdd.toPath({ type: WorkflowTypeTransferV1 }));
                }
                else {
                    newWorkflowTransfer.createThenRedirect();
                }
            },
        },
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "person" }, void 0),
            text: "Schedule a Clinical Service",
            isShow: detailsServiceClinical.isEnabled,
            onClick: () => history.push(routes.ServiceList.toPath()),
        },
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "person" }, void 0),
            text: t("home_cta_doctor_label"),
            rightIcon: _jsx(Icons.ExternalLink, { color: bc.Colors.icon, size: DEFAULT_ICON_RIGHT_SIZE }, void 0),
            isShow: !detailsServiceClinical.isEnabled && detailsVirtualCare.isEnabled && Boolean(detailsVirtualCare.url),
            onClick: () => virtualCare({}),
        },
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "person" }, void 0),
            text: "Get a Prescription",
            onClick: () => virtualCare({}),
            isShow: !detailsServiceClinical.isEnabled && detailsVirtualCare.isEnabled && !Boolean(detailsVirtualCare.url),
        },
        {
            idKey: generateGuidShort(),
            leftIcon: _jsx(Symbol, { color: bc.Colors.icon, icon: "stethoscope" }, void 0),
            text: "Request a home doctor visit",
            isShow: detailsMedVisit.isEnabled,
            onClick: () => {
                // history.push(routes.MedVisit.toPath({}));
                history.push(routes.Chat.toPath({ topic: Topic.MedVisit }));
            },
        },
    ];
    return (_jsxs(Root, { children: [itemList.map((item) => {
                return _jsx(ListCTAItem, { item: item }, item.idKey);
            }), quickList.map((item) => {
                return _jsx(JunctionQuickLink, { item: item }, item.link.title);
            })] }, void 0));
};
