import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.section `
	& {
		${TypographyV3.Body};

		border: 1px solid ${(p) => p.theme.divider};
		padding: 12px;
		white-space: pre-wrap;
	}

	&:first-child {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	& + & {
		border-top: none;
	}

	&:last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`;
export const SummaryItemSimple = (p) => {
    return _jsx(Root, Object.assign({ className: "SummaryItemSimple" }, { children: p.children }), void 0);
};
