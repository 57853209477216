import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldDescription } from "@/src/common/components/field/FieldDescription";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.header `
	& {
		display: flex;
		flex-direction: column;
		gap: 1em;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.FieldTitle};
	}
`;
export const FieldHeader = (p) => {
    const isHeader = Boolean(p.title) || Boolean(p.description);
    if (!isHeader) {
        return null;
    }
    return (_jsxs(Root, { children: [p.title && _jsx(RootTitle, { children: p.title }, void 0), _jsx(FieldDescription, { children: p.description }, void 0)] }, void 0));
};
