import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { SummaryItem } from "@/src/modules/workflow/layout/workflow/summary/SummaryItem";
import { SummaryNote } from "@/src/modules/workflow/layout/workflow/summary/SummaryNote";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const DeliveryMethodConfirm = (p) => {
    const w = p.workflow;
    const s = p.state;
    const m = s.deliveryMethod;
    const md = m === null || m === void 0 ? void 0 : m.details;
    if (!md) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [md.isAvailability && _jsx(SummaryItem, Object.assign({ title: "Signature Availability" }, { children: s.deliverySignatureNotes }), void 0), _jsx(SummaryItem, Object.assign({ title: "Shipping & Handling" }, { children: "FREE" }), void 0), _jsx(SummaryItem, Object.assign({ title: "Estimated Delivery" }, { children: `${md.eteMin}-${md.eteMax} Business Days` }), void 0), _jsx(SummaryNote, { children: "Estimated delivery time is calculated once your order has been shipped." }, void 0)] }, void 0));
};
