import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { getPriceInCentsAsString } from "@/src/common/utility/price/Price";
import { InvoiceRow } from "@/src/modules/workflow/layout/workflow/progress/invoice/InvoiceRow";
import styled from "styled-components";
const RootRx = styled.div `
	 {
		font-weight: 600;
	}
`;
const RootName1 = styled.div `
	& {
	}
`;
const RootName2 = styled.div `
	& {
		${TypographyV3.Hint};
	}
`;
const RootPrice = styled.div `
	& {
	}
`;
export const InvoiceRowItem = (p) => {
    const item = p.item;
    const price = getPriceInCentsAsString(item.price, true);
    return (_jsx(InvoiceRow
    //
    , { 
        //
        left: _jsxs(_Fragment, { children: [item.rxNumber && _jsx(RootRx, { children: `Rx: ${item.rxNumber}` }, void 0), _jsx(RootName1, { children: item.name }, void 0), _jsx(RootName2, { children: item.name2 }, void 0), item.receiptFile && (_jsx(ButtonStandard, { style: ButtonStyle.TextAnchor, content: "Receipt", onClick: () => window.open(item.receiptFile.url, "_blank") }, void 0))] }, void 0), right: _jsx(_Fragment, { children: _jsx(RootPrice, { children: price }, void 0) }, void 0) }, void 0));
};
