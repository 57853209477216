import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { useContext } from "react";
import { ModalContext } from "@/src/common/components/modal/ModalProvider";
import { generateGuid } from "@/src/common/utility/guid/GUID";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { ModalFooterRowEnd } from "@/src/common/components/modal/ModalFooterRowEnd";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
	}
`;
export const WorkflowModalPaid = (p) => {
    return (_jsxs(Root, { children: [_jsx(ModalHeader, { onClose: p.onClose, title: "Your payment has been received." }, void 0), _jsx(ModalFooterRowEnd, { children: _jsx(ButtonStandard, { content: "Ok!", onClick: p.onClose, style: ButtonStyle.SolidPrimary }, void 0) }, void 0)] }, void 0));
};
export const useWorkflowModalPaid = () => {
    const mc = useContext(ModalContext);
    return {
        doModal: (p) => {
            mc.AddModal({
                guid: generateGuid(),
                content: (m) => {
                    return (_jsx(WorkflowModalPaid, { onClose: () => {
                            var _a;
                            (_a = p.onClose) === null || _a === void 0 ? void 0 : _a.call(p);
                            m.onClose();
                        } }, void 0));
                },
            });
        },
    };
};
