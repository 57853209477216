import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getDeliveryMethodInfo } from "@/src/modules/devliery-method/type/DeliveryMethodType";
import { SummaryItem } from "@/src/modules/workflow/layout/workflow/summary/SummaryItem";
import { SummaryList } from "@/src/modules/workflow/layout/workflow/summary/SummaryList";
import { WorkflowFulfillment } from "@/src/modules/workflow/type/WorkflowFulfillment";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const WorkflowDelivery = (p) => {
    var _a;
    const workflow = p.workflow;
    const details = workflow.details;
    const isDelivery = details.fulfillment == WorkflowFulfillment.Delivery;
    if (!isDelivery) {
        return null;
    }
    const deliveryMethodInfo = getDeliveryMethodInfo((_a = details.deliveryMethod) === null || _a === void 0 ? void 0 : _a.type);
    const DeliveryMethodDetails = deliveryMethodInfo.ComponentDetails;
    const DeliveryMethodDetailsFooter = deliveryMethodInfo.ComponentDetailsFooter;
    return (_jsxs(SummaryList, Object.assign({ title: "Delivery details" }, { children: [_jsx(SummaryItem, Object.assign({ title: "Delivery Address" }, { children: details.deliveryAddress }), void 0), _jsx(SummaryItem, Object.assign({ title: "Delivery Notes" }, { children: details.deliveryNotes }), void 0), DeliveryMethodDetails && _jsx(DeliveryMethodDetails, { workflow: workflow, method: details.deliveryMethod }, void 0), DeliveryMethodDetailsFooter && _jsx(DeliveryMethodDetailsFooter, { workflow: workflow, method: details.deliveryMethod }, void 0)] }), void 0));
};
