var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ErrorMessage } from "@/src/features/common/ErrorMessage";
import { TextField } from "@/src/features/common/TextField";
import { t } from "@/src/features/Localization";
import { stripNonNumericCharactersFromString } from "@/src/utils/HelperUtils";
import { resolveValue } from "@/src/utils/PathValue";
import { useFormikContext } from "formik";
import * as React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
const RootInput = styled(TextField) `
	width: 100%;
`;
const NumberFormatPhone = React.forwardRef(function NumberFormatPhone(
// eslint-disable-next-line @typescript-eslint/no-explicit-any
_a, ref) {
    var 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { onChange } = _a, other = __rest(_a, 
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ["onChange"]);
    return (_jsx(NumberFormat, Object.assign({}, other, { getInputRef: ref, onValueChange: (values) => {
            onChange({
                target: {
                    value: values.value,
                },
            });
        }, format: "(###) ###-####", mask: "_", isNumericString: true }), void 0));
});
/**
 * NOTE: `FormikTextFieldMaskedPhoneNumber` component will work STRICTLY with numberic
 * values. e.g. "(416) 999-1234" will be automatically converted to "4169991234"
 *
 * This means that reformattting the values when sending to backend, or being used
 * elsewhere, will need to happen later in the data flow and not within formik's
 * value.
 */
export const FormikTextFieldMaskedPhoneNumber = ({ formikId, label }) => {
    const formik = useFormikContext();
    const errorMessage = resolveValue(formik.touched, formikId) && resolveValue(formik.errors, formikId);
    // This will remove any non-numeric characters,
    // Including, formatting:
    //  "--" => ""  (I.e. formatting blank "--" values as an empty string).
    // "(987) 654-3210" => "9876543210"
    const formattedValue = stripNonNumericCharactersFromString(resolveValue(formik.values, formikId));
    const handleChange = (event) => {
        formik.setFieldValue(formikId, event.target.value);
        // formik.handleBlur(event);
        // NOTE: in order to ensure that the validation occurs AFTER
        // the formik value has been updated, we do a setTimeout of 0
        // so that it occurs later in the stack, by pushing this to the task queue,
        // Which will then have this task executed after all events currently on the
        // stack have completed (i.e. after setting the value into formik).
        //
        // For more insights see details about the "event loop" "call stack" and "task queue":
        // https://www.youtube.com/watch?v=8aGhZQkoFbQ
        // See: https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
        setTimeout(() => { var _a; return formik.touched && ((_a = formik.validateForm) === null || _a === void 0 ? void 0 : _a.call(formik)); }, 0);
    };
    return (_jsxs(Root, { children: [_jsx(RootInput, { label: label, value: formattedValue, onBlur: formik.handleBlur, onChange: handleChange, name: "numberformat", id: "formatted-numberformat-input", InputProps: {
                    inputComponent: NumberFormatPhone,
                }, placeholder: t("signUp_email_step2_inputLabel_hint_phone"), variant: "outlined", error: resolveValue(formik.touched, formikId) && Boolean(resolveValue(formik.errors, formikId)) }, void 0), errorMessage && _jsx(ErrorMessage, { error: errorMessage }, void 0)] }, void 0));
};
