import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChatMessageInputExtraFooter } from "@/src/features/Chat/ChatMessageInputExtraFooter";
import { MessageSimple } from "@/src/features/Chat/MessageSimpleCustom";
import { chatClient, StreamContext } from "@/src/features/Chat/StreamProvider";
import { LayoutChatKey } from "@/src/modules/workflow/layout/LayoutChat";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Channel, Chat, MessageInput, MessageInputFlat, MessageList, Window } from "stream-chat-react";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex: 1;
		flex-direction: column;
		min-height: 0;
	}

	& .str-chat-channel {
		flex: 1;
		min-height: 0;
	}
`;
export const ChatterActive = (p) => {
    const history = useHistory();
    const { query } = useQuery();
    const historyGuid = query.get(LayoutChatKey.HistoryGuid);
    const sc = useContext(StreamContext);
    useEffect(() => {
        var _a;
        if (historyGuid && historyGuid == ((_a = sc.chatWorkflow) === null || _a === void 0 ? void 0 : _a.guid)) {
            history.replace(routes.Chat.toPath({}));
        }
    }, [historyGuid, sc.chatWorkflow]);
    return (_jsx(Root, Object.assign({ className: "ChatterActive" }, { children: _jsx(Chat, Object.assign({ client: chatClient, theme: "commerce light" }, { children: _jsx(Channel, Object.assign({ channel: sc.channel }, { children: _jsxs(Window, { children: [_jsx(MessageList, { disableDateSeparator: true, messageActions: [], Message: MessageSimple }, void 0), _jsx(ChatMessageInputExtraFooter, {}, void 0), _jsx(MessageInput, { grow: true, Input: MessageInputFlat }, void 0)] }, void 0) }), void 0) }), void 0) }), void 0));
};
