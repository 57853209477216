import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { FieldList } from "@/src/common/components/field/FieldList";
import { ModalHeader } from "@/src/common/components/modal/ModalHeader";
import { Form } from "@/src/features/common";
import { AssistanceLink } from "@/src/features/common/AssistanceLink";
import { TextField } from "@/src/features/common/TextField";
import { t } from "@/src/features/Localization";
import { useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	display: flex;
	justify-content: center;
	margin-top: 32px;
`;
export const MFBDrawerContent = (p) => {
    const [code, setCode] = useState("");
    return (_jsx(Root, { children: _jsxs(Form, Object.assign({ onSubmit: p.handleSubmit }, { children: [_jsxs(FieldList, { children: [_jsx(ModalHeader, { title: "Enter your group code" }, void 0), _jsx(TextField
                        //
                        , { 
                            //
                            autoFocus: true, fullWidth: true, isSubmitEnter: true, label: t("common_mfbInput_bottomSheet_inputLabel_groupCode"), value: code, onChange: (e) => setCode(e.target.value) }, void 0), _jsx(ButtonStandard
                        //
                        , { 
                            //
                            content: "Submit", iconRight: "business", isLoading: p.isLoading, isSubmit: true, isWide: true }, void 0)] }, void 0), _jsx(AssistanceLink, {}, void 0)] }), void 0) }, void 0));
};
