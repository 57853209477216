import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		flex: 1;
	}
`;
export const Spacer = (p) => {
    return _jsx(Root, {}, void 0);
};
