import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { LayoutEmpty } from "@/src/common/components/layout/LayoutEmpty";
import { Icons } from "@/src/features/common";
import { t } from "@/src/features/Localization";
import { useCreateThenRedirectToNewWorkflowRx } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowRx";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	& {
		margin-top: 32px;
	}
`;
const ImageContainer = styled.div `
	display: flex;
	justify-content: center;
	margin-top: 32px;
`;
export const MedicationHistoryError = (p) => {
    const history = useHistory();
    const segment = useSegmentAnalytics();
    const newWorkflowRxFill = useCreateThenRedirectToNewWorkflowRx();
    function handleNewPrescriptionWorkflow() {
        segment.segmentTrackEvent("select_new_rx", {
            entrypoint: SegmentEntryPoint.MedicationHistory,
        });
        newWorkflowRxFill.createThenRedirectToNewWorkflowRx(p.userForGuid);
    }
    function handleNoPrescriptionAlternative() {
        history.push(routes.PrescriptionAlternatives.toPath({
            patientGuid: p.userForGuid,
        }));
    }
    return (_jsx(Root, { children: _jsx(LayoutEmpty, { header: t("medicationHistory_unavailable_header"), image: _jsx(ImageContainer, { children: _jsx(Icons.PrescriptionNotSubmitted, { style: { height: "180px", width: "230px" } }, void 0) }, void 0), content: t("medicationHistory_unavailable_content"), footer: _jsxs(_Fragment, { children: [_jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, onClick: handleNewPrescriptionWorkflow, content: "Fill New Prescription" }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.TextSubtle, onClick: handleNoPrescriptionAlternative, content: "I don't have a prescription" }, void 0)] }, void 0) }, void 0) }, void 0));
};
