import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from "@/src/hooks/useAuth";
import { ReasonItem } from "@/src/modules/service/layout/virtual-care/ReasonItem";
import { getVirtualCareReasonInfo, VirtualCareReason } from "@/src/modules/service/type/VirtualCareReason";
import { routes } from "@/src/routes";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-top: 12px;
	}
`;
export const ReasonList = (p) => {
    const auth = useAuth();
    const user = auth.user;
    const history = useHistory();
    const handleClick = (reason) => {
        history.push(routes.VirtualCareReason.toPath({
            reason: reason,
        }));
    };
    return (_jsx(Root, { children: [
            VirtualCareReason.Acne,
            VirtualCareReason.Allergies,
            VirtualCareReason.ColdSores,
            VirtualCareReason.Dexcom,
            VirtualCareReason.ErectileDysfunction,
            VirtualCareReason.FamilyPlanning,
            VirtualCareReason.MalePatternBaldness,
            VirtualCareReason.MentalHealth,
            VirtualCareReason.Rosacea,
        ].map((reason) => {
            const reasonInfo = getVirtualCareReasonInfo(reason);
            return (_jsx(ReasonItem
            //
            , { isEnabled: true, title: reasonInfo.Title, onClick: () => handleClick(reason) }, reason));
        }) }, void 0));
};
