import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import MFBButtonCancelModal from "@/src/features/MFB/MFBButtonCancelModal";
import { useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	background: ${(p) => p.theme.badgeBackground};
	min-height: 79px;
	display: flex;
	flex-direction: column;
	margin-top: 16px;
	border-radius: 7px;
	padding: 20px 24px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	justify-content: center;
`;
const RootContent = styled.div `
	${TypographyV3.Body}
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 12px;
`;
const RootName = styled.div `
	& {
		flex: 2;
		font-weight: 600;
	}
`;
export const MFBGroupCard = ({ group, onRemove }) => {
    var _a;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const groupGuid = group === null || group === void 0 ? void 0 : group.guid;
    const groupName = (_a = group === null || group === void 0 ? void 0 : group.details) === null || _a === void 0 ? void 0 : _a.name;
    function handleModalOpen() {
        setIsModalOpen(true);
    }
    function handleModalClose() {
        setIsModalOpen(false);
    }
    function handleModalSubmit() {
        onRemove(groupGuid);
        handleModalClose();
    }
    return (_jsxs(Root, { children: [_jsxs(RootContent, { children: [_jsx(RootName, { children: groupName }, void 0), _jsx(ButtonStandard, { style: ButtonStyle.TextDanger, onClick: handleModalOpen, content: "Remove" }, void 0)] }, void 0), _jsx(MFBButtonCancelModal, { isOpen: isModalOpen, handleSubmit: handleModalSubmit, handleClose: handleModalClose }, void 0)] }, void 0));
};
