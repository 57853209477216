var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldHeader } from "@/src/common/components/field/FieldHeader";
import { Config } from "@/src/common/utility/config/Config";
import { ErrorMessage } from "@/src/features/common/ErrorMessage";
import { Autocomplete } from "@/src/features/common/FormInputs";
import { resolveValue } from "@/src/utils/PathValue";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
`;
/**
 * This is to be added in the beginning of "App.tsx" in order to initialize
 * the google api, otherwise an issue like this occurs:
 *
 * Error:
 *  	Cannot read properties of undefined (reading 'places')
 *
 * This happens because the "react-google-autocomplete" package has code which tries
 * to run `google.maps.places` before `window.google.maps` exists.
 *
 * Therefore, we try to ensure `window.google.maps` exists by pre-initializing it,
 * instead of just using "react-google-autocomplete"'s `usePlacesService` initializer.
 *
 * See:
 * https://github.com/ErrorPro/react-google-autocomplete/issues/176
 *
 */
export const useInitializeGooglePlacesAPI = () => {
    const apiKey = Config.GoogleApiKey || "MISSING";
    function addGoogleScriptToBody() {
        var _a;
        if (!((_a = window === null || window === void 0 ? void 0 : window.google) === null || _a === void 0 ? void 0 : _a.maps)) {
            const script = document.createElement("script");
            // We do not set the script to async because we want to ensure it is added.
            script.setAttribute("src", `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`);
            document.head.appendChild(script);
        }
    }
    useEffect(() => {
        addGoogleScriptToBody();
    }, []);
};
/**
 * @deprecated TODO: reidenzon - Use [FormikAddress] instead!
 */
export const FormikGooglePlacesAPIAutocomplete = (p) => {
    const formikId = p.formikId;
    const labelText = p.labelText;
    const placeholderText = p.placeholderText;
    const { setFieldValue, values, touched, errors } = useFormikContext();
    const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
        options: {
            componentRestrictions: { country: "ca" },
            types: ["address"],
        },
    });
    const value = values[formikId];
    const [selectedPlace, setSelectedPlace] = useState();
    const [options, setOptions] = useState([]);
    const errorMessage = resolveValue(touched, formikId) && resolveValue(errors, formikId);
    const handleListItemSelect = (selectedValue) => {
        setSelectedPlace(selectedValue);
        getPlacePredictions("");
    };
    const fetchPlacePredictions = (input) => __awaiter(void 0, void 0, void 0, function* () {
        getPlacePredictions({ input: input });
        yield setFieldValue(formikId, input);
    });
    const getPlaceDetails = (place) => __awaiter(void 0, void 0, void 0, function* () {
        placesService === null || placesService === void 0 ? void 0 : placesService.getDetails({ placeId: place.place_id }, (placeResult) => {
            setFieldValue(formikId, placeResult.formatted_address);
        });
    });
    useEffect(() => {
        if (selectedPlace) {
            getPlaceDetails(selectedPlace);
        }
    }, [selectedPlace]);
    useEffect(() => {
        if (placePredictions.length > 0) {
            const locations = placePredictions.map((place) => ({
                description: place.description,
                place_id: place.place_id,
            }));
            setOptions(locations);
        }
    }, [placePredictions]);
    return (_jsxs(Root, Object.assign({ "data-cy": "autocomplete-container" }, { children: [_jsx(FieldHeader, { title: p.title }, void 0), _jsx(Autocomplete, { value: value, options: !isPlacePredictionsLoading && options.length > 0 ? options : [], optionLabelKey: "description", optionValueKey: "place_id", onChange: (evt, autocompleteValue) => fetchPlacePredictions(autocompleteValue), placeholder: placeholderText, label: labelText, onItemSelection: (evt, selectedValue) => handleListItemSelect(selectedValue), defaultValue: value || "" }, void 0), errorMessage && _jsx(ErrorMessage, { error: errorMessage }, void 0)] }), void 0));
};
