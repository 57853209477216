/**
 * FormikAutoSubmit can be used as component within <Formik>
 * So that the form can autosubmit if isAutoSubmit === true.
 * E.g.:
 *
 * <Formik >
 *  ...
 * 		<FormikAutoSubmit
 *			isAutoSubmit={isAutoSubmit}
 *			isSubmitting={isSubmitting}
 *			hasSubmitted={hasSubmitted}
 * 		/>
 * <Formik >
 */
import React from "react";
import { useFormikContext } from "formik";
import { useAuth } from "@/src/hooks/useAuth";
export const FormikAutoSubmit = ({ isAutoSubmit, isSubmitting, hasSubmitted }) => {
    // Grab values and submitForm from context
    const { submitForm } = useFormikContext();
    const { isLoggedIn, logoutUser } = useAuth();
    React.useEffect(() => {
        var _a;
        // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
        if (isAutoSubmit && !isSubmitting && !hasSubmitted) {
            if (isLoggedIn) {
                (_a = logoutUser()) === null || _a === void 0 ? void 0 : _a.then(() => {
                    submitForm();
                });
            }
            else {
                submitForm();
            }
        }
    }, [isAutoSubmit, submitForm, isSubmitting, hasSubmitted, isLoggedIn]);
    return null;
};
