var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { ErrorMessage } from "@/src/features/common/ErrorMessage";
import { Checkbox } from "@/src/features/common/FormInputs/Checkbox";
import { resolveValue } from "@/src/utils/PathValue";
import { useFormikContext } from "formik";
import styled from "styled-components";
const Root = styled.div `
	align-items: start;
	display: flex;
	gap: 8px;
`;
const RootIcon = styled.div `
	& {
	}
`;
const RootLabel = styled.div `
	${TypographyV3.FieldInput};
	overflow-wrap: break-word;
	position: relative;
	padding: 0.2em 0;
	white-space: pre-wrap;
`;
export const FormikCheckbox = (p) => {
    const { setFieldValue, values, touched, errors } = useFormikContext();
    const handleCheckboxChange = (newValue) => __awaiter(void 0, void 0, void 0, function* () {
        yield setFieldValue(p.formikId, newValue);
    });
    const errorMessage = resolveValue(touched, p.formikId) && resolveValue(errors, p.formikId);
    if (p.label) {
        return (_jsxs(_Fragment, { children: [_jsxs(Root, { children: [_jsx(RootIcon, { children: _jsx(Checkbox, { checked: resolveValue(values, p.formikId), isDisabled: p.isDisabled, onChange: handleCheckboxChange }, void 0) }, void 0), _jsx(RootLabel, { children: p.label }, void 0)] }, void 0), errorMessage && _jsx(ErrorMessage, { error: errorMessage }, void 0)] }, void 0));
    }
    return (_jsxs(_Fragment, { children: [_jsx(Checkbox, { checked: resolveValue(values, p.formikId), isDisabled: p.isDisabled, onChange: handleCheckboxChange }, void 0), errorMessage && _jsx(ErrorMessage, { error: errorMessage }, void 0)] }, void 0));
};
