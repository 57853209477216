import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.Body};
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 12px;
		justify-content: space-between;
	}
`;
const RootTitle = styled.div `
	& {
	}
`;
const RootPrice = styled.div `
	& {
		color: ${(p) => p.theme.badge};
	}
`;
export const DeliveryMethodSelectHeader = (p) => {
    return (_jsxs(Root, { children: [_jsx(RootTitle, { children: p.title }, void 0), _jsx(RootPrice, { children: p.price }, void 0)] }, void 0));
};
