import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const DeliveryMethodTimeline = (p) => {
    const w = p.workflow;
    const wd = w.details;
    const m = p.method;
    const md = m.details;
    return (_jsx(_Fragment, {}, void 0));
};
