var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { JunctionBanner } from "@/src/modules/junction/components/banner/JunctionBanner";
import { useJunctionScreenBannerDismissMutation, useJunctionScreenBannersQuery } from "@/src/modules/junction/redux/ApiJunction";
import { useApiJunctionRequest } from "@/src/modules/junction/redux/ApiJunctionRequest";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 4px;
	}
`;
export const JunctionBanners = (p) => {
    var _a;
    const junctionRequest = useApiJunctionRequest();
    const apiList = useJunctionScreenBannersQuery(junctionRequest({
        screen: p.screen,
    }));
    const itemList = (((_a = apiList.data) === null || _a === void 0 ? void 0 : _a.data.items) || []);
    const [apiDismiss, apiDismissState] = useJunctionScreenBannerDismissMutation();
    const handleDismiss = (item) => __awaiter(void 0, void 0, void 0, function* () {
        const response = (yield apiDismiss({
            bannerId: item.id,
        }).unwrap());
        apiList.refetch();
    });
    return (_jsx(Root, { children: itemList.map((item) => {
            return (_jsx(JunctionBanner
            //
            , { item: item, isDismissing: apiDismissState.isLoading, onDismiss: handleDismiss }, item.id));
        }) }, void 0));
};
