import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { Nav } from "@/src/features/Nav/Nav";
import { useCreateThenRedirectToNewWorkflowRx } from "@/src/features/Workflows/hooks/useCreateThenRedirectToNewWorkflowRx";
import { useAuth } from "@/src/hooks/useAuth";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { useDexcom } from "@/src/modules/service/layout/service-list/useDexcom";
import { WorkflowTypeRxFillV3 } from "@/src/modules/workflow/type/rx-fill-v3/Type";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	//width: 315px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 24px;
`;
const RootFooter = styled.footer `
	& {
		align-items: start;
		display: flex;
		flex-direction: column;
		justify-content: start;
		gap: 12px;
	}
`;
export const LayoutDexcom = () => {
    const auth = useAuth();
    const user = auth.user;
    const history = useHistory();
    const { query } = useQuery();
    const dexcom = useDexcom({});
    const workflowRxFill = useCreateThenRedirectToNewWorkflowRx();
    const handleRx = () => {
        if (auth.isUserInRelationshipWithDependents) {
            history.push(routes.DexcomFor.toPath({}));
        }
        else {
            dexcom.rx({ isForce: true });
        }
    };
    const handleBuy = () => {
        dexcom.buy({});
    };
    const handleWorkflow = () => {
        if (auth.isUserInRelationshipWithDependents) {
            history.push(routes.WorkflowAdd.toPath({ type: WorkflowTypeRxFillV3 }));
        }
        else {
            workflowRxFill.createThenRedirectToNewWorkflowRx();
        }
    };
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { 
                //
                dataLeft: { leftItemType: "Back", linkTo: routes.ServiceList.toPath() }, dataRight: { rightItemType: "PhoneStartChat" } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader
                            //
                            // isMargin={false}
                            , { 
                                //
                                // isMargin={false}
                                title: "Get the Dexcom CGM today!", description: _jsx(_Fragment, { children: _jsx("div", { children: "Delivered free, right to your door. (Canada-wide)" }, void 0) }, void 0) }, void 0), _jsx(RootFooter, { children: _jsx(ButtonStandard, { content: "I need a prescription", onClick: handleRx, style: ButtonStyle.SolidPrimary }, void 0) }, void 0), _jsxs(RootFooter, { children: [_jsx(ButtonStandard, { content: "I have a prescription for Dexcom CGM", onClick: handleWorkflow, style: ButtonStyle.TextAnchor }, void 0), _jsx(ButtonStandard, { content: "Buy a Device", onClick: handleBuy, style: ButtonStyle.TextAnchor }, void 0)] }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
