var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FieldStyle } from "@/src/common/components/field/FieldStyle";
import { FormikField } from "@/src/common/components/formik/FormikField";
import { Symbol } from "@/src/common/components/symbol/Symbol";
import { getPriceInDollarsAsString, getPriceInDollarsFromString } from "@/src/common/utility/price/Price";
import { useDocumentKey } from "@/src/modules/layout/context/useDocumentKey";
import { useFormikContext } from "formik";
import { useEffect, useRef } from "react";
import styled from "styled-components";
const RootContent = styled.div `
	& {
		align-items: center;
		display: flex;
		flex: 1;
		flex-direction: row;
		gap: 4px;
	}
`;
const RootInput = styled.input `
	& {
		${FieldStyle.Rest};
		flex: 1;
		min-width: 0;
		text-align: right;
	}

	&:focus,
	&:hover {
		${FieldStyle.Active};
	}
`;
const formatCommas = (v) => {
    return v.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const FormikInputPrice = (p) => {
    const formik = useFormikContext();
    const help = formik.getFieldHelpers(p.name);
    const meta = formik.getFieldMeta(p.name);
    const refInput = useRef(null);
    // TODO: reidenzon - Finesse me!
    // https://codepen.io/akalkhair/pen/dyPaozZ
    const handleInput = (isBlur) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const input = refInput.current;
        if (!input) {
            return;
        }
        const v = input.value;
        let vNew = v;
        const isDecimal = v.indexOf(".") >= 0;
        if (isDecimal) {
            const d = v.indexOf(".");
            const vLeft = formatCommas(v.substring(0, d));
            const vRight = (formatCommas(v.substring(d)) + (isBlur ? "00" : "")).substring(0, 2);
            vNew = "$" + vLeft + "." + vRight;
        }
        else {
            vNew = "$" + formatCommas(v) + (isBlur ? ".00" : "");
        }
        const cNew = vNew.length - v.length + ((_a = input.selectionStart) !== null && _a !== void 0 ? _a : 0);
        input.value = vNew;
        input.setSelectionRange(cNew, cNew);
        yield help.setValue(getPriceInDollarsFromString(vNew));
    });
    useDocumentKey({
        onEscape: (e) => { var _a; return (_a = refInput.current) === null || _a === void 0 ? void 0 : _a.blur(); },
    });
    useEffect(() => {
        refInput.current.value = getPriceInDollarsAsString(meta.value);
        handleInput(true);
    }, [meta.value]);
    return (_jsx(FormikField, Object.assign({ label: p.label, description: p.description, name: p.name }, { children: _jsxs(RootContent, { children: [_jsx(RootInput
                //
                , { 
                    //
                    autoComplete: "off", autoFocus: p.isFocus, disabled: p.isDisabled, name: p.name, onBlur: (e) => __awaiter(void 0, void 0, void 0, function* () {
                        yield handleInput(true);
                        // formik.handleBlur(e);
                    }), onChange: (e) => __awaiter(void 0, void 0, void 0, function* () {
                        // TODO: reidenzon - Do something?!
                        // await handleInput(false);
                        // formik.handleChange
                    }), type: "text", ref: refInput }, void 0), _jsx(Symbol, { icon: p.icon }, void 0)] }, void 0) }), void 0));
};
