import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { Nav } from "@/src/features/Nav/Nav";
import { useAuth } from "@/src/hooks/useAuth";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import { getVirtualCareReasonInfo } from "@/src/modules/service/type/VirtualCareReason";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	//width: 315px;
	display: flex;
	flex-direction: column;
	gap: 12px;
	margin-top: 24px;
`;
export var LayoutVirtualCareReasonKey;
(function (LayoutVirtualCareReasonKey) {
    LayoutVirtualCareReasonKey["Reason"] = "reason";
})(LayoutVirtualCareReasonKey || (LayoutVirtualCareReasonKey = {}));
export const LayoutVirtualCareReason = () => {
    const auth = useAuth();
    const user = auth.user;
    const history = useHistory();
    const { query } = useQuery();
    const reason = query === null || query === void 0 ? void 0 : query.get(LayoutVirtualCareReasonKey.Reason);
    const reasonInfo = getVirtualCareReasonInfo(reason);
    const handleBookNow = () => {
        window.open(reasonInfo.Url, "_blank");
    };
    return (_jsxs(PageContainer, { children: [_jsx(Nav
            //
            , { 
                //
                dataLeft: { leftItemType: "Back", linkTo: routes.ServiceList.toPath() }, dataRight: { rightItemType: "PhoneStartChat" } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader
                            //
                            , { 
                                //
                                title: reasonInfo.Title, description: _jsxs(_Fragment, { children: [_jsx("div", { children: reasonInfo.Description }, void 0), reasonInfo.Warning && _jsx("div", { children: reasonInfo.Warning }, void 0)] }, void 0) }, void 0), _jsx("div", { children: _jsx(ButtonStandard
                                //
                                , { 
                                    //
                                    content: "Proceed", onClick: handleBookNow, style: ButtonStyle.SolidPrimary }, void 0) }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
