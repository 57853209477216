import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { Nav } from "@/src/features/Nav/Nav";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import SVGIcon from "@/src/public/app/images/svg/registration-success-email.svg";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 32px;
		text-align: center;
	}
`;
const RootImage = styled.div `
	aspect-ratio: 158/131;
	display: flex;
	justify-content: center;
	height: 160px;
	margin: 32px auto;
`;
export const RegisterCompletePage = () => {
    const { query } = useQuery();
    const email = (query === null || query === void 0 ? void 0 : query.get("email")) || "";
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: { leftItemType: "Login", linkTo: routes.Login.toPath() } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader, { title: "Check your email", isMargin: false }, void 0), _jsx(RootImage, { children: _jsx(SVGIcon, {}, void 0) }, void 0), _jsx(LayoutContentHeader, { description: `We've sent an activation link to ${email}.` }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
