import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TimelineStepContent } from "@/src/modules/workflow/layout/workflow/progress/timeline/TimelineStepContent";
import { TimelineStepIcon } from "@/src/modules/workflow/layout/workflow/progress/timeline/TimelineStepIcon";
import { TimelineStepLine } from "@/src/modules/workflow/layout/workflow/progress/timeline/TimelineStepLine";
import { useTimelineStepStateInfo } from "@/src/modules/workflow/layout/workflow/progress/timeline/TimelineStepState";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: row;
		gap: 16px;
	}
`;
const RootIcon = styled.div `
	& {
		display: flex;
		flex-direction: column;
	}
`;
export const TimelineStep = (p) => {
    const stateInfo = useTimelineStepStateInfo(p.state);
    return (_jsxs(Root, { children: [_jsxs(RootIcon, { children: [_jsx(TimelineStepIcon, { state: stateInfo }, void 0), !p.isLast && _jsx(TimelineStepLine, { state: stateInfo }, void 0)] }, void 0), _jsx(TimelineStepContent, { state: stateInfo, step: p.step }, void 0)] }, void 0));
};
