import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const RootHeader = styled.div `
	${TypographyV3.Header1};
	text-align: center;
`;
const RootImage = styled.div `
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 32px;
`;
const RootContent = styled.div `
	${TypographyV3.Body};
	margin-top: 28px;
	text-align: center;
`;
const RootFooter = styled.div `
	${TypographyV3.Button};
	margin: 28px auto 0;

	& > * {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	& > * + * {
		margin-top: 15px;
	}
`;
export const LayoutEmpty = (p) => {
    return (_jsxs(_Fragment, { children: [_jsx(RootHeader, { children: p.header }, void 0), _jsx(RootImage, { children: p.image }, void 0), p.content && _jsx(RootContent, { children: p.content }, void 0), _jsx(RootFooter, { children: p.footer }, void 0)] }, void 0));
};
