import { jsx as _jsx } from "react/jsx-runtime";
import { DeliveryMethodSelectHeader } from "@/src/modules/devliery-method/components/select/DeliveryMethodSelectHeader";
import styled from "styled-components";
const Root = styled.div `
	& {
	}
`;
export const DeliveryMethodSelect = (p) => {
    const w = p.workflow;
    const m = p.method;
    const d = m.details;
    return (_jsx(Root, { children: _jsx(DeliveryMethodSelectHeader
        //
        , { 
            //
            title: `${d.eteMin}-${d.eteMax} Business Days`, price: "FREE" }, void 0) }, void 0));
};
