import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { WorkflowStepIcon } from "@/src/modules/workflow/layout/workflow/stepper/WorkflowStepIcon";
import { getStepperStepStateInfo } from "@/src/modules/workflow/layout/workflow/stepper/WorkflowStepState";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 8px;
		min-width: 50px;
	}
`;
const RootIcon = styled.div `
	& {
		align-items: center;
		display: flex;
		justify-content: center;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Header6};
		font-size: 0.8rem;
		align-items: center;
		display: flex;
		justify-content: center;
	}
`;
export const WorkflowStep = (p) => {
    const stateInfo = getStepperStepStateInfo(p.state);
    return (_jsxs(Root, { children: [_jsx(RootIcon, { children: _jsx(WorkflowStepIcon, { state: stateInfo }, void 0) }, void 0), _jsx(RootTitle, { children: p.step.Title }, void 0)] }, void 0));
};
