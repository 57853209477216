import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.section `
	& {
		${TypographyV3.Body};
		margin: 12px;
	}
`;
export const SummaryNote = (p) => {
    return _jsx(Root, { children: p.children }, void 0);
};
