import { jsx as _jsx } from "react/jsx-runtime";
import { ButtonNavigate } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { FormikCheckbox } from "@/src/features/common/FormInputs";
import { t } from "@/src/features/Localization";
import { RegisterType } from "@/src/features/RegisterAccount/types";
import styled from "styled-components";
const Root = styled.div `
	margin-top: 20px;
`;
export const RegisterOrActivateTermsOfServiceCheckbox = ({ registerType, formikId }) => {
    let privacyPolicyCopy;
    let termsOfUseCopy;
    if (registerType === RegisterType.SSO) {
        privacyPolicyCopy = t("ssoRegistration_agreementOne_privacyPolicy");
        termsOfUseCopy = t("ssoRegistration_agreementOne_termsOfService");
    }
    else {
        privacyPolicyCopy = t("signUp_email_step2_agreementOne_privacyPolicy");
        termsOfUseCopy = t("signUp_email_step2_agreementOne_termsOfService");
    }
    const privacyPolicy = (_jsx(ButtonNavigate
    //
    , { 
        //
        style: ButtonStyle.TextAnchor, pathTo: "https://mednow.ca/pages/privacy-policy", content: privacyPolicyCopy, isNewTab: true }, void 0));
    const termsOfService = (_jsx(ButtonNavigate
    //
    , { 
        //
        style: ButtonStyle.TextAnchor, pathTo: "https://mednow.ca/pages/terms-of-use", content: termsOfUseCopy, isNewTab: true }, void 0));
    const legalText = registerType === RegisterType.SSO
        ? t("ssoRegistration_agreementOne", {
            privacyPolicy,
            termsOfService,
        })
        : t("signUp_email_step2_agreementOne", {
            privacyPolicy,
            termsOfService,
        });
    return (_jsx(Root, Object.assign({ "data-cy": "privacy-policy-checkbox" }, { children: _jsx(FormikCheckbox, { formikId: formikId, label: legalText, isSecondary: true }, void 0) }), void 0));
};
