import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Icons } from "@/src/features/common";
import { getUserGroupAboveRank0 } from "@/src/features/User/userHelpers";
import styled from "styled-components";
const DEFAULT_IMAGE_HEIGHT_PX = "28px";
const Root = styled.div `
	& {
		margin-top: auto;
	}
`;
const RootContent = styled.div `
	margin: 32px 8px 16px 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;

	& img {
		height: ${DEFAULT_IMAGE_HEIGHT_PX};
	}
`;
const MednowLogo = styled(Icons.MednowLogoWithName) `
	height: ${DEFAULT_IMAGE_HEIGHT_PX};
`;
const DividerLine = styled.div `
	height: 40px;
	width: 0;
	border-left: 1px solid ${(p) => p.theme.divider};
`;
const InstitutionLogoImg = styled.img `
	height: 24px;
`;
export const ActivationLogosFooter = (p) => {
    var _a;
    const group = getUserGroupAboveRank0(p.user);
    const imageUrl = (_a = group === null || group === void 0 ? void 0 : group.details.images.logoAppActivate) === null || _a === void 0 ? void 0 : _a.url;
    return (_jsx(Root, { children: _jsxs(RootContent, { children: [_jsx(MednowLogo, { width: 177, height: 28 }, void 0), imageUrl && (_jsxs(_Fragment, { children: [_jsx(DividerLine, {}, void 0), _jsx(InstitutionLogoImg, { src: imageUrl }, void 0)] }, void 0))] }, void 0) }, void 0));
};
