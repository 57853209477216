import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 4px;
	}
`;
export const GridRow = (p) => {
    if (!p.children) {
        return null;
    }
    return _jsx(Root, { children: p.children }, void 0);
};
