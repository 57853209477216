import { jsx as _jsx } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import styled from "styled-components";
const Root = styled.div `
	& {
		margin-top: 32px;
	}
`;
/** @deprecated TODO: reidenzon - Use {@link ButtonStandard} instead!  */
export const ButtonRegister = (p) => {
    return (_jsx(Root, { children: _jsx(ButtonStandard
        //
        , { 
            //
            style: ButtonStyle.SolidPrimary, 
            // onClick={formik.handleSubmit}
            content: p.text, isForward: true, isLoading: p.isLoading, isSubmit: true, isWide: true }, void 0) }, void 0));
};
