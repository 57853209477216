import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PageContainer } from "@/src/features/common/Page/PageContainer";
import { PageOverflowContainer } from "@/src/features/common/Page/PageOverflowContainer";
import { PageWrapper } from "@/src/features/common/Page/PageWrapper";
import { t } from "@/src/features/Localization";
import { Nav } from "@/src/features/Nav/Nav";
import { LayoutContentHeader } from "@/src/modules/layout/components/LayoutContentHeader";
import SVGEmailSentSuccess from "@/src/public/app/images/svg/email-sent-success.svg";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import styled from "styled-components";
const Root = styled.div `
	& {
		margin-top: 64px;
		text-align: center;
	}
`;
const RootImage = styled.div `
	aspect-ratio: 289/206;
	display: flex;
	justify-content: center;
	height: 160px;
	margin: 32px auto;
`;
export const ForgotPasswordSuccessPage = () => {
    const { query } = useQuery();
    const email = (query === null || query === void 0 ? void 0 : query.get("email")) || "";
    return (_jsxs(PageContainer, { children: [_jsx(Nav, { dataLeft: {
                    leftItemType: "Login",
                    linkTo: routes.Login.toPath(),
                } }, void 0), _jsx(PageOverflowContainer, { children: _jsx(PageWrapper, { children: _jsxs(Root, { children: [_jsx(LayoutContentHeader, { title: t("resetPasswordSent_header") }, void 0), _jsx(RootImage, { children: _jsx(SVGEmailSentSuccess, {}, void 0) }, void 0), _jsx(LayoutContentHeader, { description: _jsx("div", { children: t("resetPasswordSent_content", {
                                        email: _jsx("b", { children: email }, void 0),
                                    }) }, void 0) }, void 0)] }, void 0) }, void 0) }, void 0)] }, void 0));
};
