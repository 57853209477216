import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { ButtonStyle } from "@/src/common/components/button/ButtonStyle";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { LoaderSpinner } from "@/src/features/common/LoaderSpinner";
import { t } from "@/src/features/Localization";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { useLazyGetUserReportViewMedicalHistoryQuery } from "@/src/redux/apiServices/suiteApi";
import { useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useEffect, useState } from "react";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 2rem;
		margin-top: 2rem;
	}
`;
const Title = styled.div `
	${TypographyV3.Header1};
	font-size: 23px;
	line-height: 30px;
	text-align: Center;
`;
const RootSuccess = styled.div `
	${TypographyV3.Body};
	display: flex;
	justify-content: center;
`;
export const MedicationHistoryDownload = (p) => {
    const [reportUrl, setReportUrl] = useState("");
    const { snackbar } = useCustomSnackbar();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const userFor = p.userForGuid;
    const [getUserReportViewMedicalHistory, { isLoading: getUserReportIsLoading, isSuccess: getUserReportIsSuccess, isError: getUserReportIsError, data: getUserReportRequestData },] = useLazyGetUserReportViewMedicalHistoryQuery();
    function handleGenerateReport() {
        if (userFor) {
            getUserReportViewMedicalHistory({ guid: userFor });
        }
        segmentTrackEvent("generate_med_report");
    }
    function handleViewReport() {
        segmentTrackEvent("view_med_report");
        window.open(reportUrl);
    }
    useEffect(() => {
        var _a, _b;
        if (getUserReportIsSuccess) {
            const reportUrlData = (_b = (_a = getUserReportRequestData === null || getUserReportRequestData === void 0 ? void 0 : getUserReportRequestData.data) === null || _a === void 0 ? void 0 : _a.report) === null || _b === void 0 ? void 0 : _b.url;
            setReportUrl(reportUrlData);
        }
    }, [getUserReportIsSuccess, getUserReportRequestData]);
    useEffect(() => {
        if (getUserReportIsError) {
            const errorMessage = "Report unavailable";
            snackbar.error(errorMessage);
        }
    }, [getUserReportIsError]);
    return (_jsxs(Root, { children: [_jsx(Title, { children: `Generate a medication history report for ${p.userForName}` }, void 0), !reportUrl && !getUserReportIsLoading && _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, onClick: handleGenerateReport, content: "Generate Report" }, void 0), !reportUrl && getUserReportIsLoading && _jsx(LoaderSpinner, {}, void 0), reportUrl && _jsx(ButtonStandard, { style: ButtonStyle.SolidPrimary, onClick: handleViewReport, content: "View Report", iconRight: "open_in_new" }, void 0), reportUrl && _jsx(RootSuccess, { children: t("medicationHistory_available_content") }, void 0)] }, void 0));
};
